import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GraphUp } from 'react-bootstrap-icons';
import companyLogoIconPath from '../../../../assets/code-cube-logo.svg';
import companyLogoNamePath from '../../../../assets/code-cube-name.svg';
import "./styles.scss";

import { AuthContext } from '../../../../services/auth.service';

import MenuCategory from "./MenuCategory";
import ProfileArea from "./ProfileArea";
import Dropdown from '../../../elements/Dropdown';

const Sidebar = () => {
    const { companyInfo, companyList } = useContext(AuthContext);
    const { adminUser } = useContext(AuthContext);
    const [tagMonitorActive, setTagMonitorActive] = useState(false);
    const [dataLayerGuardActive, setDataLayerGuardActive] = useState(false);
    const [sitespeedMonitorActive, setSitespeedMonitorActive] = useState(false);

    useEffect(() => {
        setTagMonitorActive(companyInfo?.tag_monitor);
        setDataLayerGuardActive(companyInfo?.datalayer_guard);
        setSitespeedMonitorActive(companyInfo?.sitespeed_monitor);
    }, [companyInfo]);


    const dashboardLinks = [
        { name: "DataLayer Guard", to: "/datalayer_guard_dashboard", active: dataLayerGuardActive },
        { name: "Tag Monitor", to: "/tag_monitor_dashboard", active: tagMonitorActive },
    ];

    const configLinks = [
        { name: "DataLayer Guard", to: "/datalayer_guard_config", active: dataLayerGuardActive },
        { name: "Sitespeed Monitor", to: "/sitespeed_monitor_config", active: sitespeedMonitorActive },
        { name: "Tag Monitor", to: "/tag_monitor_config", active: tagMonitorActive },
    ];

    const accountSettingsLinks = [
        { name: "Account settings", to: "/account_settings", active: true },
        { name: "Billing and payments", to: "/", active: false },
        { name: "Team members", to: "/", active: false },
    ];

    if (adminUser) {
        accountSettingsLinks.push(
            { name: "Create Datasets", to: "/create_dataset", active: true }
        )
    }


    const supportLinks = [
        { name: "Documentation", to: "https://docs.code-cube.io/", active: true },
        { name: "Requests", to: "/", active: false },
    ];

    const [hovered, handleHovering] = useState(false);
    const [sidebarClass, handleSidebarClass] = useState();
    const hoverSidebar = () => handleHovering(true);
    const hoverOutSidebar = () => handleHovering(false);

    useEffect(() => {
        handleSidebarClass(`sidebar-menu ${hovered ? "hovered" : ""}`);
    }, [hovered]);

    return (
        <div className={sidebarClass} onMouseEnter={hoverSidebar} onMouseLeave={hoverOutSidebar}>
            <div className="sidebar-container">
                <div className="sidebar-container-top mt-5">
                    <div className="sidebar-logo-area">
                        <Link className="sidebar-logo" to="/" onClick={() => hoverOutSidebar()}>
                            <div className="sidebar-logo-container">
                                <img className="sidebar-logo-icon" src={companyLogoIconPath} alt="Code Cube Logo" height="26" />
                                <img className="sidebar-logo-name title-sliding" src={companyLogoNamePath} alt="Code Cube" height="16" />
                            </div>
                        </Link>
                    </div>
                    <div className="sidebar-elems-area links-area">
                        <MenuCategory
                            categoryIcon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="white" className="bi bi-graph-up" viewBox="0 0 20 20">
                                <path d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"/>
                                </svg>
                            }
                            categoryName={<span style={{ fontSize: "0.9rem"}} >Dashboards</span>}
                            links={dashboardLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                        <MenuCategory
                            categoryIcon={
                                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6L13 6M13 6C13 7.65686 14.3431 9 16 9C17.6569 9 19 7.65685 19 6C19 4.34315 17.6569 3 16 3C14.3431 3 13 4.34315 13 6ZM7 14L19 14M7 14C7 15.6569 5.65685 17 4 17C2.34315 17 1 15.6569 1 14C1 12.3431 2.34315 11 4 11C5.65685 11 7 12.3431 7 14Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                            categoryName={<span style={{ fontSize: "0.9rem" }}>Configurations</span>}
                            links={configLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                        <MenuCategory
                            categoryIcon={
                                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 19C18 17.6044 18 16.9067 17.8278 16.3389C17.44 15.0605 16.4395 14.06 15.1611 13.6722C14.5933 13.5 13.8956 13.5 12.5 13.5H7.5C6.10444 13.5 5.40665 13.5 4.83886 13.6722C3.56045 14.06 2.56004 15.0605 2.17224 16.3389C2 16.9067 2 17.6044 2 19M14.5 5.5C14.5 7.98528 12.4853 10 10 10C7.51472 10 5.5 7.98528 5.5 5.5C5.5 3.01472 7.51472 1 10 1C12.4853 1 14.5 3.01472 14.5 5.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                            categoryName={<span style={{ fontSize: "0.9rem" }}>Account settings</span>}
                            links={accountSettingsLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                        <MenuCategory
                            categoryIcon={
                                <svg width="18" height="18" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.108 8.00224C11.2842 7.50136 11.632 7.079 12.0898 6.80998C12.5475 6.54095 13.0857 6.4426 13.609 6.53237C14.1324 6.62213 14.607 6.89421 14.949 7.30041C15.2909 7.70661 15.4781 8.22072 15.4773 8.75168C15.4773 10.2506 13.2289 11 13.2289 11M13.2579 14H13.2679M13.108 19C17.8024 19 21.608 15.1944 21.608 10.5C21.608 5.80558 17.8024 2 13.108 2C8.4136 2 4.60802 5.80558 4.60802 10.5C4.60802 11.45 4.76387 12.3636 5.05139 13.2166C5.15959 13.5376 5.21369 13.6981 5.22345 13.8214C5.23309 13.9432 5.2258 14.0286 5.19568 14.1469C5.16517 14.2668 5.09782 14.3915 4.96312 14.6408L3.32746 17.6684C3.09415 18.1002 2.97749 18.3161 3.0036 18.4828C3.02634 18.6279 3.11177 18.7557 3.2372 18.8322C3.3812 18.9201 3.62532 18.8948 4.11356 18.8444L9.23459 18.315C9.38967 18.299 9.46721 18.291 9.53788 18.2937C9.6074 18.2963 9.65647 18.3029 9.72426 18.3185C9.79318 18.3344 9.87984 18.3678 10.0532 18.4345C11.0012 18.7998 12.0312 19 13.108 19Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                            categoryName={<span style={{ fontSize: "0.9rem" }}>Support</span>}
                            links={supportLinks}
                            hovered={hovered}
                            hoverOutSidebar={hoverOutSidebar}
                        />
                    </div>
                </div>
                <div className="sidebar-container-bottom">
                    <div className="sidebar-elems-area">
                        {companyList?.length > 1 ?
                            <Dropdown
                                list={companyList}
                                hovered={hovered}
                                hoverOutSidebar={hoverOutSidebar}
                            />
                            :
                            <></>
                        }

                        <ProfileArea
                            hovered={hovered}
                            size={18}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
