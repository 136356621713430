import FetchService from './fetch.service';
import localStorageService from './localStorage.service';

const fetchService = new FetchService();

class ProductSettingsService {
    async getProductSettings() {
        const curSettings = localStorageService.getProductSettings();
        if (curSettings) return JSON.parse(curSettings);

        const response = await fetchService(`/product_settings/`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        const resJson = await response.json();
        localStorageService.setProductSettings(JSON.stringify(resJson?.product_settings));

        return resJson?.product_settings;
    }
}

export default new ProductSettingsService();
