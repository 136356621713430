import React, { useEffect, useState, useContext } from 'react';
import localStorageService from '../../services/localStorage.service';
import GTMServiceAPI from '../../services/gtm.API.service';
import AuthorizedGTM from './googleCallBack';
import { Link } from 'react-router-dom';
import { FiletypeDoc} from 'react-bootstrap-icons';


import "./style.scss";

const GTM = () => {
    const [authUrl , setAuthUrl] = useState("");
    const UserEmail = localStorageService.getUserData()?.email;    
    const [authorizedGTM, setAuthorizedGTM] = useState(false);



    useEffect(() => {
        (async () => {

            // Send a request to check if the user credentials already exist
            const resonse = await GTMServiceAPI.GetAuthorizationLink(UserEmail);
            if(resonse?.credentials_exist){
                setAuthorizedGTM(true)

            } else{
                setAuthUrl(resonse.auth_url);
            }

        })();
    })

    return(

        <div className="modal-elems-rows" style={{ padding: "1rem 3rem " }}> 

            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Set tag names as monitoring key</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to="https://docs.code-cube.io/tag-monitor/tag-bulk-edit/">

                        <div className="file-icon" style={{ color: '$cc-blue' }}>
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p>
                    To enable monitoring, additional configuration in your client-side Tag Manager container is                   
                    required. 
                    <br></br>
                    Please follow the steps in the documentation to complete the implementation.
                </p>

            </div>

            <AuthorizedGTM authorizedGTM={authorizedGTM} authUrl={authUrl}/>

        </div>
    )
}

export default GTM;
