import React from "react";


export const PasswordSecurityCheck = (props) => {

    const password = props.password;
    const isPasswordSecure = props.isPasswordSecure;

    return (
        <div>
            {password.length > 0 && (
                isPasswordSecure ? (
                    <div className='pb-2' style={{ color: 'green' }}><small style={{ letterSpacing: "0", fontSize : ".7rem" }}>Password is secure!</small></div>
                ) : (
                    <div className='pb-2' style={{ color: 'red' }}>
                        <small style={{ letterSpacing: "0", fontSize : ".7rem" }}>
                            8+ characters, mix of uppercase, lowercase, numbers, and special characters!
                        </small>
                    </div>
                )
                )
            }
        </div>
    )
}


export const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

    return (
        password.length >= minLength &&
        hasUppercase &&
        hasLowercase &&
        hasNumber &&
        hasSpecialChar
    );
};