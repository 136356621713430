import React, { useState, useEffect, useContext, useRef } from 'react';
import { CaretDownFill, Clipboard, Calendar3, CaretUpFill, ArrowCounterclockwise, ChevronDown } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { format, set } from 'date-fns';
import dayjs from 'dayjs';
import localStorageService from '../../services/localStorage.service';
import DashboardService from '../../services/dashboard.service';
import { AuthContext } from '../../services/auth.service';

import PageHeader from '../../components/elements/PageHeader';
import LoadingScreen from '../../components/elements/LoadingScreen';
import Table from '../../components/elements/Table';
import Tabs from '../../components/elements/Tabs';

import { Layout, Row, Col, Flex, Space, DatePicker, Select } from 'antd';

import "./styles.scss";
const { Content } = Layout;
const { RangePicker } = DatePicker;


export const getDateRangeCode = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(endDate - startDate);

    // Number of milliseconds in a day
    const oneDayMs = 1000 * 60 * 60 * 24;

    // Number of milliseconds in an hour
    const oneHourMs = 1000 * 60 * 60;

    // Get the current date and time
    const now = new Date();

    // Calculate the difference between now and the end date
    const differenceFromNowMs = Math.abs(now - endDate);

    let result;

    switch (differenceMs) {
        case oneDayMs:
            if (differenceFromNowMs <= oneDayMs) {
                result = "last_24_hours";
            } else if (differenceMs === oneDayMs) {
                result = "one_day";
            }
            break;
        case oneHourMs:
            result = "last_hour";
            break;
        case 0:
            result = "one_day";
            break;
        default:
            result = null;
    }

    return result;
}


export const DataLayerGuardDash = () => {

    const navigate = useNavigate();

    const { companyId, companyInfo } = useContext(AuthContext);
    const [companyID, setCompanyID] = useState(companyId);
    const [datasetFound, setDatasetFound] = useState(true);
    const [filterButtons, setfilterButtons] = useState(true);
    const [inititalLoad, setInititalLoad] = useState(true);
    const [clientTimeStamp, setClientTimeStamp] = useState("Europe/Amsterdam");

    const [loadingActive, setLoadingActive] = useState(true);
    const [activeTab, setActiveTab] = useState("errors");

    // Set the loading status for the loading spinner
    const [updateLoadFlag, setUpdateLoadFlag] = useState(false);

    const [errorsList, setErrorsList] = useState([]);
    const [initialErrorsList, setInitialErrorsList] = useState([]);
    const [errorToReview, setErrorToReview] = useState({});
    const [errorExpanded, setErrorExpanded] = useState(false);
    const [issueContent, setIssueContent] = useState(<></>);
    const [eventNameFilter, setEventNameFilter] = useState("");

    const [snapshotsList, setSnapshotsList] = useState([]);
    const [initialSnapshotsList, setInitialSnapshotsList] = useState([]);
    const [snapshotToReview, setSnapshotToReview] = useState({});
    const [snapshotExpanded, setSnapshotExpanded] = useState(false);

    //Dashboard Data
    const [domainsList, setDomainsList] = useState([]);

    // Filters
    const [filtersDisabled, setFiltersDisabled] = useState(true);

    // List of event names and hosts
    const [eventNameList, setEventNameList] = useState([]);

    // Filters Data
    const [chosenEventName, setChosenEventName] = useState([]);
    const [chosenDomain, setChosenDomain] = useState([]);

    // current values of the filters
    const [currentChosenEventName, setCurrentChosenEventName] = useState([]);
    const [currentChosenDomain, setCurrentChosenDomain] = useState([]);

    // DatePicker
    const [startDate, setStartDate] = useState(dayjs().add(-7, 'd').set('hour', 0).set('minute', 0).set('second', 0));
    const [endDate, setEndDate] = useState(dayjs().set('hour', 23).set('minute', 59).set('second', 59));

    const timestampFormat = "YYYY-MM-DD HH:mm:ss";
    const timestampFormatInput = "YYYY-MM-DD HH:mm";

    const [differenceOneDayOrOneHour, setDifferenceOneDayOrOneHour] = useState(null);

    const rangePresets = [
        {
            label: 'Last 1 Hour',
            value: [dayjs().subtract(1, 'hour'), dayjs()],
        },
        {
            label: 'Last 24 Hours',
            value: [dayjs().subtract(24, 'hour'), dayjs()],
        },
        {
            label: 'Last 7 Days',
            value: [
                dayjs().add(-7, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
        {
            label: 'Last 14 Days',
            value: [
                dayjs().add(-14, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
        {
            label: 'Last 30 Days',
            value: [
                dayjs().add(-30, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
        {
            label: 'Last 90 Days',
            value: [
                dayjs().add(-90, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
    ];

    const timeAgo = (timestamp) => {
        const currentDate = new Date();
        const pastDate = new Date(timestamp);

        const amsterdamTime = currentDate.toLocaleString('en-US', { timeZone: clientTimeStamp });
        const amsterdamDate = new Date(amsterdamTime);


        const timeDifference = amsterdamDate - pastDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day(s) and ${hours % 24} hour(s) ago`;
        } else if (hours > 0) {
            return `${hours} hour(s) and ${minutes % 60} minute(s) ago`;
        } else if (minutes > 0) {
            return `${minutes} minute(s) ago`;
        } else {
            return `${seconds} second(s) ago`;
        }
    }

    const generateTimestamps = (startDate, endDate) => {

        const timestampsList = [];
        const now = new Date();

        const amsterdamTime = now.toLocaleString('en-US', { timeZone: clientTimeStamp });
        const amsterdamHour = new Date(amsterdamTime).getHours();
        const amsterdamDate = new Date(amsterdamTime);

        const lastHourStart = new Date(amsterdamDate.setHours(amsterdamDate.getHours() - 1));
        lastHourStart.setMinutes(amsterdamDate.getMinutes());

        const timeSpan = companyID !== companyId ? null : differenceOneDayOrOneHour;

        const startDateNewDate = new Date(startDate);
        const endDateNewDate = new Date(endDate);

        const formatDate = (date) => {
            // Extract year, month, and day
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
            let day = date.getDate().toString().padStart(2, '0');

            // Format the date as YYYY-MM-DD
            let formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        }

        switch (timeSpan) {
            case "one_day":
            case "last_24_hours":
                for (let i = amsterdamHour; i >= 0; i--) {
                    const hour = `${i.toString()}:00`;
                    const date = formatDate(new Date(endDateNewDate));
                    const fullDateString = `${date} ${hour}`
                    timestampsList.push(fullDateString);
                }

                for (let i = 23; i >= amsterdamHour; i--) {
                    const hour = `${i.toString()}:00`;
                    const date = formatDate(new Date(startDateNewDate));
                    const fullDateString = `${date} ${hour}`
                    timestampsList.push(fullDateString);
                }
                break;

            case "last_hour":
                for (let i = 0; i < 60; i++) {
                    const timeEntry = new Date(lastHourStart.getTime() + i * 60000);
                    const hours = ('0' + timeEntry.getHours()).slice(-2); // Extract hours and pad with leading zero if necessary
                    const minutes = ('0' + timeEntry.getMinutes()).slice(-2); // Extract minutes and pad with leading zero if necessary
                    const formattedTime = `${hours}:${minutes}`;
                    timestampsList.push(formattedTime);
                }
                break;

            case false:
                while (startDateNewDate <= endDateNewDate) {
                    timestampsList.push(formatDate(new Date(startDateNewDate))); // Format as "YYYY-MM-DD"
                    startDateNewDate.setDate(new Date(startDateNewDate.getDate() + 1)); // Increment the date by 1 day
                }
                break;

            default:
        }

        return timestampsList;
    }

    const addMissingTimestamps = (list, aggrParam) => {

        const timestampsList = generateTimestamps(startDate, endDate);

        // Group the results by the combination of tag_id and container_id
        const groupedData = {};
        const errordata = aggrParam === "total_fails_percent" ? true : false;

        list?.forEach((item) => {
            const key = `${item.tag_id}_${item.container_id}`;

            if (!groupedData[key]) {
                groupedData[key] = [];

            }
            groupedData[key].push(item);
        });


        // Add missing timestamps with 0 total_calls for other tags
        for (const key in groupedData) {
            let currentTagData = groupedData[key];

            if (currentTagData) {
                const missingTimestamps = timestampsList.filter((timestamp) => !currentTagData.some((item) => item.timestamp === timestamp));

                if (currentTagData.length > 0) {
                    missingTimestamps.forEach((timestamp) => {
                        currentTagData.push({
                            container_id: currentTagData[0].container_id,
                            tag_id: currentTagData[0].tag_id,
                            tag_name: currentTagData[0].tag_name,
                            timestamp: timestamp,
                            total_calls: 0,
                            data_type: errordata ? currentTagData[0].data_type : null,
                            total_fails: errordata ? 0 : null,
                            total_fails_percent: errordata ? 0 : null,
                        });
                    });
                }
            }
        }

        // extract all objects inside the nested arrays and create a new list
        let arrayOfArrays = Object.values(groupedData);
        const results = arrayOfArrays.reduce((accumulator, currentArray) => {
            return [...accumulator, ...currentArray];
        }, []);


        return results;
    }

    const getTimestamps = (dataArr, aggrParam, setLabelFunc) => {
        let sortedData;

        const addedMissingTimestampsArrData = addMissingTimestamps(dataArr, aggrParam);
        const timeSpan = companyID !== companyId ? null : differenceOneDayOrOneHour;

        switch (timeSpan) {
            case "one_day":
            case "last_24_hours":
                // sort the timestams when they are in the format of "YYYY-MM-DD HH:MM"
                function parseTimestamp(timestamp) {
                    const [date, time] = timestamp.split(" ");
                    const [year, month, day] = date.split("-").map(Number);
                    const [hours, minutes] = time.split(":").map(Number);
                    return new Date(year, month - 1, day, hours, minutes);
                }

                // Sort the array of objects by timestamp property in descending order
                sortedData = addedMissingTimestampsArrData.sort(
                    (a, b) => parseTimestamp(a.timestamp) - parseTimestamp(b.timestamp)
                );
                break;

            case "last_hour":
                // sort the timestams when they are in the format of "HH:MM"
                function timeToMinutes(time) {
                    const [hours, minutes] = time.split(":").map(Number);
                    return hours * 60 + minutes;
                }

                // Sort the array of objects by timestamp property in descending order
                sortedData = addedMissingTimestampsArrData.sort(
                    (a, b) => timeToMinutes(a.timestamp) - timeToMinutes(b.timestamp)
                );
                break;

            default:
                // sort the timestams when they are in the format of regular dates
                sortedData = addedMissingTimestampsArrData.sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                );
        }

        setLabelFunc(retrieveTimestampLabels(sortedData, "timestamp"));
        return sortedData;
    }

    const retrieveTimestampLabels = (arr, labelField) => {
        const sortedTimestamps = [...new Set(arr.map(item => item[labelField]))].sort((a, b) => {
            const timeA = new Date("1970/01/01 " + a);
            const timeB = new Date("1970/01/01 " + b);
            return timeA - timeB;
        });

        return sortedTimestamps;
    };

    const onRangeChange = (dates = [], dateStrings = []) => {
        const dateStart = dates?.length ? dates[0] : "";
        const dateEnd = dates?.length ? dates[1] : "";
        const dateStartStr = dateStrings?.length ? dateStrings[0] : "";
        const dateEndStr = dateStrings?.length ? dateStrings[1] : "";

        const oneDaySpan = getDateRangeCode(dateStartStr, dateEndStr);
        setDifferenceOneDayOrOneHour(oneDaySpan);

        // If new date has been selected update the charts
        if (dateStart !== startDate || dateEnd !== endDate) {
            if (dateStart && dateEnd && companyID === companyId) setUpdateLoadFlag(!updateLoadFlag);
        }

        setStartDate(dateStart);
        setEndDate(dateEnd);
    };

    const handleFilterChange = (e, updateFunction) => updateFunction(e);

    const handleClear = (updateFunction) => {
        updateFunction([]);
        handleLoseFocus();
    }

    const handleLoseFocus = () => {
        const domainListChanged = !arraysEqual(currentChosenDomain, chosenDomain);
        const eventNameListChanged = !arraysEqual(currentChosenEventName, chosenEventName);

        if (domainListChanged || eventNameListChanged) {
            if (chosenDomain.length > 0 || chosenEventName.length > 0) {
                setLoadingActive(true);
                setUpdateLoadFlag(!updateLoadFlag);
            } else {
                refreshFilters();
            }
        }
    };


    // Tabs
    const [tabsVisibility, setTabsVisibility] = useState({
        "errors": true,
        "overview": false,
    });

    const handleTabsVisibilityChange = (val) => {

        switch (val) {
            case 'errors':
                setTabsVisibility({ "errors": true, "overview": false });

                break;
            case 'overview':
                setTabsVisibility({ "errors": false, "overview": true });
                break;
            default:
        }

        setActiveTab(val);
    }

    const setDefaultDateRange = () => {
        setStartDate(dayjs().add(-7, 'd').set('hour', 0).set('minute', 0).set('second', 0));
        setEndDate(dayjs().set('hour', 23).set('minute', 59).set('second', 59));
    }



    const refreshFilters = () => {

        setfilterButtons(true);
        setChosenDomain([]);
        setChosenEventName([]);
        setErrorsList(initialErrorsList);
        setDefaultDateRange();
        setSnapshotsList(initialSnapshotsList);
        setDifferenceOneDayOrOneHour(null);
        setCurrentChosenDomain([]);
        setCurrentChosenEventName([]);
    }

    const renderIssueContent = (row) => {
        const templateString = JSON.stringify(JSON.parse(row?.template || "{}"), null, 4);

        switch (row?.error_type) {

            case "missing_object":
                return (
                    <div key="missing_object" className="error-details-desc">
                        <strong>Example of the expected object</strong>
                        <div className="dl-template-grn">
                            <pre>{templateString}</pre>
                        </div>
                    </div>
                );

            case "incorrect_parameters":
                const description = row?.description;
                const error = row?.error;
                const parameterNames = row?.parameter_name || "";
                const expectedType = row?.expected_type;
                const expectedValue = row?.expected_value;
                const actualType = row?.actual_type;
                const actualValue = row?.actual_value;

                const nameString = (
                    <div>
                        {parameterNames}
                    </div>
                );

                const incorrectTypeErrDesc = (
                    <>
                        <div className="expected-type">
                            <p><strong>Expected type:</strong> {expectedType}</p>
                        </div>
                        <div className="actual-type">
                            <p><strong>Found type:</strong> {actualType}</p>
                        </div>
                        <div className="actual-value">
                            <p><strong>Found value:</strong> {actualValue}</p>
                        </div>
                    </>
                );

                const incorrectValueErrDesc = (
                    <>
                        <div className="expected-value">
                            <p><strong>Expected value:</strong> {expectedValue}</p>
                        </div>
                        <div className="actual-value">
                            <p><strong>Found Value:</strong> {actualValue}</p>
                        </div>
                    </>
                );

                let errorContent = null;

                switch (error) {
                    case "incorrect_type":
                        errorContent = incorrectTypeErrDesc;
                        break;
                    case "incorrect_value":
                        errorContent = incorrectValueErrDesc;
                        break;
                    default:
                }

                return (
                    <div key="incorrect_parameters" className="error-details-desc">
                        <h6><strong>Issue</strong></h6>
                        <div className="param-error">
                            <strong>Error:</strong> {description}
                        </div>
                        {nameString &&
                            <div className="parameters"><strong>Parameter:</strong>
                                {nameString}
                            </div>
                        }
                        {errorContent &&
                            <div>
                                {errorContent}
                            </div>
                        }
                    </div>
                );

            default:
                return (<></>);
        }

    }

    const dataLayerContentPreview = (datalayerString) => {
        let formattedString = "";

        switch (datalayerString) {
            case "":
            case "{}":
            case "[]":
            case "N/A":
                formattedString = "The object is missing";
                break;
            default:
                formattedString = JSON.stringify(JSON.parse(datalayerString), null, 2);
        }

        return formattedString;
    }


    const handleEventNameField = (e) => setEventNameFilter(e.target.value);

    const handleAreaUpdate = (row, type) => {
        if (type === 'snapshot') {
            if (snapshotToReview === row) setSnapshotExpanded(!snapshotExpanded);
            else setSnapshotExpanded(true);
            setSnapshotToReview(row);
        } else if (type === 'error') {
            if (errorToReview === row) {
                setErrorExpanded(!errorExpanded);
            } else {
                setErrorExpanded(true);
            }
            setErrorToReview(row);
            const issueContentData = renderIssueContent(row);
            setIssueContent(issueContentData);
        }
    };

    const CaretComponent = ({ row, isExpanded, type }) => {
        const handleClick = (event) => {
            event.stopPropagation();
            handleAreaUpdate(row, type);
        };

        return (
            <div onClick={handleClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, margin: 0 }}>
                {isExpanded ? <CaretUpFill size={14} /> : <CaretDownFill size={14} />}
            </div>
        );
    };

    const UrlComponent = ({ row }) => row?.url;

    function extractHostname(url) {
        const regex = /^(?:https?:\/\/)?(?:[^@\/\/]+@)?(?:www\.)?([^:\/\/]+)/;
        const match = url?.match(regex);
        return match ? match[1] : null;
    }

    const errorsListColumns = [
        {
            name: 'Hostname',
            sortable: true,
            width: '17%',
            selector: row => extractHostname(row?.url),
        },
        {
            name: 'Event Name',
            sortable: true,
            width: '17%',
            selector: row => row?.event_name,
        },
        {
            name: 'Error',
            sortable: true,
            width: '26%',
            selector: row => row?.description || row.error_description,
        },
        {
            name: 'Last time occurred',
            sortable: true,
            width: '17%',
            selector: row => row?.timestamp || "-",
        },
        {
            name: 'Parameter',
            sortable: true,
            width: '21%',
            selector: row => row?.parameter_name || "-",
        },
        {
            name: '',
            sortable: false,
            compact: true,
            width: '1%',
            format: () => <CaretDownFill size={14} />,
            selector: (row, index) => index,
            cell: row => (
                <CaretComponent
                    row={row}
                    isExpanded={errorToReview === row && errorExpanded}
                    type="error"
                />
            ),
        },
    ];

    const snapshotsListColumns = [
        {
            name: 'Hostname',
            sortable: true,
            width: '50%',
            format: (row) => <div style={{ textWrap: "balance" }}>{row?.url}</div>,
            selector: row => row?.url,
            cell: row => <UrlComponent row={row} />,
        },
        {
            name: 'Event Name',
            sortable: true,
            width: '41%',
            selector: row => row?.page_type,
        },
        {
            name: '',
            sortable: false,
            compact: true,
            width: '1%',
            format: () => <CaretDownFill size={14} />,
            selector: (row, index) => index,
            cell: row => (
                <CaretComponent
                    row={row}
                    isExpanded={snapshotToReview === row && snapshotExpanded}
                    type="snapshot"
                />
            ),
        },
    ];

    const lastHourTimestamp = () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() - 1);
        return currentDate.getTime();
    }


    useEffect(() => {
        // toggle the apply button based on the selected filters
        // If any filter is added enable the buttons 

        const filterButtonsDisabled = !(chosenDomain || chosenEventName);
        setfilterButtons(filterButtonsDisabled);

    }, [chosenDomain, chosenEventName]);


    const getFilterObject = () => {
        let setDifferenceOneDayOrOneHourParam,
            chosenEventNameParam,
            chosenDomainParam;
    
        // If the company is changed reset all the filters
        if (companyID !== companyId) {
            setDifferenceOneDayOrOneHour(null);
            setChosenEventName([]);
            setChosenDomain([]);
    
            setCurrentChosenDomain([]);
            setCurrentChosenEventName([]);
    
            setClientTimeStamp("Europe/Amsterdam")
            setStartDate(dayjs().add(-7, 'd'));
            setEndDate(dayjs());
            setDifferenceOneDayOrOneHourParam = null;
            chosenEventNameParam = "";
            chosenDomainParam = "";
        } else {
            setDifferenceOneDayOrOneHourParam = differenceOneDayOrOneHour;
            chosenEventNameParam = chosenEventName;
            chosenDomainParam = chosenDomain;
            setCurrentChosenDomain(chosenDomain);
            setCurrentChosenEventName(chosenEventName);
        }
    
        const reqObj = {
            companyID: companyId,
            startDate: startDate.format(timestampFormat),
            endDate: endDate.format(timestampFormat),
            chosenEventName: chosenEventNameParam,
            chosenDomain: chosenDomainParam,
            differenceOneDayOrOneHour: setDifferenceOneDayOrOneHourParam,
        };
    
        return reqObj;
    };

    useEffect(() => {
        (async () => {
            setLoadingActive(true); // Start the loading spinner when the request starts
            setErrorExpanded(false);
            setSnapshotExpanded(false);
    
            const session = await DashboardService.checkSession();
            const sessionData = await session?.json();
            const sessionExists = sessionData?.session_exists;
    
            if (sessionExists) {
                let requestData;
                if (startDate && endDate) {
                    // If the profile has changed, reset all filters
                    if (companyID !== companyId) {
                        setInititalLoad(true);
                        setChosenDomain([]);
                        setChosenEventName([]);
                        setErrorsList([]);
                        setDomainsList([]);
                        setEventNameList([]);
                        setSnapshotsList([]);
                        setEventNameFilter("");
                        setStartDate(dayjs().add(-7, 'd'));
                        setEndDate(dayjs());
                        
                        requestData = {
                            domain: "",
                            event_name: "",
                            differenceOneDayOrOneHour: null,
                        };
                    } else {
                        // If the profile has not changed, prepare the request using the current filters
                        const filterObject = getFilterObject();
                        requestData = {
                            domain: filterObject.chosenDomain,
                            event_name: filterObject.chosenEventName,
                            differenceOneDayOrOneHour: filterObject.differenceOneDayOrOneHour,
                            start_date: filterObject.startDate,
                            end_date: filterObject.endDate,
                        };
                    }
    
                    const response = await DashboardService.getDLGDashboardData(requestData);
                    const respData = await response.json();
                    const datasetExists = respData?.dataset_exists;
    
                    if (datasetExists) {
                        // If the dataset exists, update the data
                        setDatasetFound(true);
                        setDomainsList(respData?.context?.company_domains.map(elem => ({ domain: elem?.domain })));
                        setEventNameList(respData?.context?.event_names.map(elem => ({ event_name: elem?.event_name })));
                        setErrorsList(() => respData?.context?.errors);
                        setSnapshotsList(() => respData?.context?.templates);
    
                        // Set the BQ_client_timestamp
                        const BQClientTimestamp = respData?.context?.BQ_client_timestamp;
                        setClientTimeStamp(BQClientTimestamp);
    
                        if (inititalLoad || companyID !== companyId) {
                            setInitialErrorsList(() => respData?.context?.errors);
                            setInitialSnapshotsList(() => respData?.context?.templates);
                        }
    
                        setInititalLoad(false);
                        setFiltersDisabled(false); // Enable filters when data is received
                    } else {
                        setDatasetFound(false);
                    }
    
                    setCompanyID(companyId); // Update the current company ID
                } else {
                    alert("Please select a valid start and end date!");
                }
    
                setLoadingActive(false); // Stop the loading spinner when the request is complete
            } else {
                localStorageService.clearLocalStorage();
                navigate('/login');
            }
        })();
    }, [companyId, updateLoadFlag]);

    const arraysEqual = (arr1 = [], arr2 = []) => {
        if (arr1.length !== arr2.length) return false;

        for (let i = 0; i < arr1.length; i++) { 
            if (arr1[i] !== arr2[i]) return false;
        }

        return true;
    };



    const handleUpdateClick = () => setUpdateLoadFlag(!updateLoadFlag);

    const handleCopyClick = (value) => {
        const textarea = document.createElement('textarea');
        textarea.value = JSON.stringify(value || {});
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    };

    const updateDateRange = (range) => {
        let newStartDate;
        let newEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

        switch (range) {
            case 'last_hour':
                newStartDate = dayjs().subtract(1, 'hour');
                newEndDate = dayjs();
                break;
            case 'last_24_hours':
                newStartDate = dayjs().subtract(24, 'hour');
                newEndDate = dayjs();
                break;
            case 'last_7_days':
                newStartDate = dayjs().subtract(7, 'day').startOf('day');
                break;
            case 'last_14_days':
                newStartDate = dayjs().subtract(14, 'day').startOf('day');
                break;
            default:
                newStartDate = dayjs().subtract(7, 'day').startOf('day');
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setUpdateLoadFlag(!updateLoadFlag);
    };

    return (
        <Content>
            <LoadingScreen isActive={loadingActive} />
            {datasetFound ? (
                <Space direction="vertical" className="container-content" size="large">
                    <div>
                        <Space direction="vertical" size="large">

                            <Tabs
                                items={[
                                    {
                                        name: "errors",
                                        title: "encountered errors",
                                        onClickFunction: () => handleTabsVisibilityChange("errors"),
                                    },
                                    {
                                        name: "overview",
                                        title: "dataLayer overview",
                                        onClickFunction: () => handleTabsVisibilityChange("overview"),
                                    },
                                ]}
                                activeTab={activeTab}
                            />

                            <div>
                                {tabsVisibility["errors"] ? (
                                    <h5>Encountered errors in your dataLayer</h5>
                                ) : tabsVisibility["overview"] ? (
                                    <h5>Overview of the latest DataLayer snapshots</h5>
                                ) : ""}
                            </div>


                            {/* filters */}

                            <Row className="filters-row">
                                <Flex style={{ width: '100%' }} gap="small" justify={'flex-start'} align={'flex-start'}>
                                    <Space style={{ minWidth: '20rem' }} direction="vertical">
                                        <RangePicker
                                            presets={[
                                                ...rangePresets,
                                            ]}
                                            showTime={false}
                                            format={timestampFormatInput}
                                            onChange={onRangeChange}
                                            variant="filled"
                                            placeholder="time & date"
                                            defaultValue={[startDate, endDate]}
                                            value={[startDate, endDate]}
                                        />
                                    </Space>
                                    <Space style={{ width: '100%' }} direction="vertical">
                                        <Select
                                            popupMatchSelectWidth={false}
                                            mode="multiple"
                                            style={{ width: '100%', minWidth: '10rem' }}
                                            placeholder="domain"
                                            onChange={(e) => handleFilterChange(e, setChosenDomain)}
                                            options={domainsList.map((elem) => ({
                                                label: elem.domain,
                                                value: elem.domain,
                                            }))}
                                            showSearch={true}
                                            maxTagCount={0}
                                            value={chosenDomain}
                                            disabled={filtersDisabled}
                                            onBlur={handleLoseFocus}
                                            onClear={() => handleClear(setChosenDomain)}
                                            variant="filled"
                                            suffixIcon={<ChevronDown />}
                                        />
                                    </Space>

                                    <Space style={{ width: '100%' }} direction="vertical">
                                        <Select
                                            popupMatchSelectWidth={false}
                                            mode="multiple"
                                            style={{ width: '100%', minWidth: '10rem' }}
                                            placeholder="event name"
                                            onChange={(e) => handleFilterChange(e, setChosenEventName)}
                                            options={eventNameList.map((elem) => ({
                                                label: elem.event_name,
                                                value: elem.event_name,
                                            }))}
                                            showSearch={true}
                                            maxTagCount={0}
                                            value={chosenEventName}
                                            disabled={filtersDisabled}
                                            onBlur={handleLoseFocus}
                                            onClear={() => handleClear(setChosenEventName)}
                                            variant="filled"
                                            suffixIcon={<ChevronDown />}
                                        />
                                    </Space>
                                    <Col align="center" className="filters-refresh">
                                        <ArrowCounterclockwise
                                            size={22}
                                            onClick={() => refreshFilters()}
                                        />
                                    </Col>
                                </Flex>
                            </Row>
                        </Space>
                        <Space className={`charts-area ${tabsVisibility["errors"] ? "visible" : ""}`}>
                            <Table
                                columns={errorsListColumns}
                                tableData={errorsList}
                                rowClickFunction={(row) => handleAreaUpdate(row, 'error')}
                                filterParam="event_name"
                                includeIndexColumn={false} // Disable index column for this table
                                showSubHeader={false}
                            />

                            <div className={`mb-3 ${errorExpanded ? "slide-down" : "slide-up"}`}>
                                <div className="row" style={{ display: "flex" }}>
                                    <div className="elems-list col-6 equal-height-div">
                                        <h5><strong>Errors in detail</strong></h5>
                                        <div className="error-details-desc">
                                            <div><strong>Event:</strong> {errorToReview?.event_name}</div>
                                            <div><strong>Type of Error:</strong> {errorToReview?.error_description}</div>
                                            <div><strong>Last time it was encountered:</strong> {errorToReview?.timestamp}</div>
                                            <div><strong>Number of times the error encountered:</strong> {errorToReview?.occurrence_count}</div>
                                            <div id="urls">
                                                <strong>URL where the error is found:</strong><br />
                                                {errorToReview?.url}
                                            </div>
                                        </div>

                                        <div className="pt-5">{issueContent}</div>
                                    </div>

                                    <div className="text-area col-6 equal-height-div">
                                        <div className="text-area-block" style={{ marginBottom: 0 }}>
                                            <div className="text-area-title">
                                                <h5><strong>Snapshot of your Datalayer</strong></h5>
                                            </div>
                                            <div className="text-area-clipboard">
                                                <div className="text-area-copy-button" onClick={() => handleCopyClick(JSON.parse(errorToReview?.datalayer_string) || {})}>
                                                    <Clipboard size={18} />
                                                </div>
                                                <textarea className="datalayer-string-snp" disabled={true}
                                                    value={dataLayerContentPreview(errorToReview?.datalayer_string || "")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Space>
                        <div className={`charts-area ${tabsVisibility["overview"] ? "visible" : ""}`}>
                            <div>
                                <Table
                                    columns={snapshotsListColumns}
                                    tableData={snapshotsList}
                                    rowClickFunction={(row) => handleAreaUpdate(row, 'snapshot')}
                                    filterParam="event_name"
                                    includeIndexColumn={false}
                                />

                                <div className={`mb-3 text-area ${snapshotExpanded ? "slide-down" : "slide-up"}`}>
                                    <div className="text-area-block">
                                        <div className="text-area-title">
                                            <strong>URL:</strong> {snapshotToReview?.hostname || ""}
                                        </div>
                                        <div className="text-area-clipboard">
                                            <div className="text-area-copy-button" onClick={() => handleCopyClick(snapshotToReview?.event)}>
                                                <Clipboard size={18} />
                                            </div>
                                            <textarea style={{ minHeight: '25rem' }} className="datalayer-string-snp" disabled={true}
                                                value={JSON.stringify(snapshotToReview?.event || {}, null, 4)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Space>
            ) : (
                <div className="col-12 col-sm-10 col-md-8 text-start w-100">
                    <h1>Dataset not found!</h1>
                </div>
            )}
        </Content>
    );
}
