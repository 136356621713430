import { React, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc, DashCircle } from 'react-bootstrap-icons';

export const ProductSettingsModal = ({ context }) => {
    const {
        productDescLink,
        timezones,
        curTimezone,
        checkFreq,
        curCheckFreq,
        setCurTimezone,
        setCurCheckFreq,
        setConfigParams,
    } = useContext(context);

    const [choosenTimezone, setChoosenTimezone] = useState(curTimezone);
    const [choosenCheckFreq, setChoosenCheckFreq] = useState(curCheckFreq);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    useEffect(() => {
        setChoosenTimezone(curTimezone);
        setChoosenCheckFreq(curCheckFreq);
    }, [curTimezone, curCheckFreq]);

    const handleTimezoneChange = (newTimezone) => {
        setCurTimezone(newTimezone);
        setChoosenTimezone(newTimezone);
    }
    const handleCheckFreqChange = (newCheckFreq) => {
        setCurCheckFreq(newCheckFreq);
        setChoosenCheckFreq(newCheckFreq);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.timezone = choosenTimezone;
        newObject.times_per_day = choosenCheckFreq;
        setConfigParamsToSave(newObject);
    }, [choosenTimezone, choosenCheckFreq]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <h2>General Settings</h2>
            <div className="container-rows modal-panel-button-row">
                <div>
                    <Link className="panel-button-container" target="_blank" to={productDescLink}>
                        <div className="panel-button-icon">
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Product description</div>
                            <div className="panel-button-desc">Guide to set up your Sitespeed Monitor</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <h3 className="modal-category">1. Timezone</h3>
                <p>Which timezone is applicable for your business?</p>
                <div>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle btn-light text-start"
                            type="button" id="timezone_select" data-bs-toggle="dropdown" aria-expanded="false">
                            { choosenTimezone ? timezones[choosenTimezone] : "Choose timezone" }
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="timezone_select">
                            { Object.keys(timezones).map(key => 
                                <li className="pointer-on-hover" key={key} onClick={() => handleTimezoneChange(key)}>
                                    <div className="dropdown-item">{timezones[key]}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <h3 className="modal-category">2. How often to run?</h3>
                <p>How often should the Sitespeed Monitor run?</p>
                <div>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle btn-light text-start"
                            type="button" id="dlg_check_freq" data-bs-toggle="dropdown" aria-expanded="false">
                            { choosenCheckFreq ? checkFreq[choosenCheckFreq] : "Choose frequency" }
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dlg_check_freq">
                            { Object.keys(checkFreq).map(key => 
                                <li className="pointer-on-hover" key={key} onClick={() => handleCheckFreqChange(key)}>
                                    <div className="dropdown-item">{checkFreq[key]}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const MonitoringModal = ({ context }) => {
    const {
        productDescLink,
        urlsList,
        setUrlsList,
        setConfigParams,
    } = useContext(context);

    const [curUrlsList, setCurUrlsList] = useState(urlsList || []);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    useEffect(() => {
        setCurUrlsList(urlsList || []);
    }, [urlsList]);

    const handleUrlDelete = (idx) => {
        const newUrlsList = curUrlsList.filter((elem, index) => index !== idx);
        setCurUrlsList(newUrlsList);
        setUrlsList(newUrlsList);
    }

    const handleUrlAdd = () => {
        setCurUrlsList([ ...curUrlsList, {}]);
        setUrlsList([ ...curUrlsList, {}]);
    }

    const handleInputValueChange = (idx, param, event) => {
        const newUrlsList = [...curUrlsList];  // Create a new copy of the array
        const elem = { ...newUrlsList[idx] };  // Create a new copy of the object at the specified index
        elem[param] = event.target.value;
        newUrlsList[idx] = elem;
    
        setCurUrlsList(newUrlsList);
        setUrlsList(newUrlsList);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        const curUrlsListStr = escape(JSON.stringify({ "events": curUrlsList }));
        newObject.urls = curUrlsListStr;
        setConfigParamsToSave(newObject);
    }, [curUrlsList]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <h2>General Settings</h2>
            <div className="container-rows modal-panel-button-row">
                <div>
                    <Link className="panel-button-container" target="_blank" to={productDescLink}>
                        <div className="panel-button-icon">
                            <FiletypeDoc size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Product description</div>
                            <div className="panel-button-desc">Guide to set up your Sitespeed Monitor</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <h3 className="modal-category">Events overview</h3>
                <p>We need to have the following information per event, to be able to monitor them correctly:</p>
                <ul>
                    <li><p>Page name</p></li>
                    <li><p>Page URL (example of a page or a list of test pages)</p></li>
                </ul>
            </div>
            <div className="elems-list">
                <div className="inputs-row-4-col row-labels">
                    <div>Page name</div>
                    <div>Page URL</div>
                </div>
                <div className="list-col-2-add">
                    {
                        curUrlsList?.map((elem, idx) => {
                            return(
                                <div key={idx} className="inputs-row-4-col">
                                    <input type="text" placeholder="Page name" value={elem?.event_name || ""} onChange={(e) => handleInputValueChange(idx, "event_name", e)} />
                                    <input type="text" placeholder="URL" value={elem?.urls || ""} onChange={(e) => handleInputValueChange(idx, "urls", e)} />
                                    <DashCircle size={18} onClick={() => handleUrlDelete(idx)}></DashCircle>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div><div className="btn cc-btn-peach" onClick={handleUrlAdd}>+ add event</div></div>
        </div>
    );
}
