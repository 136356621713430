import React, { useState, useEffect } from 'react';
import { Button } from "antd";

export const ActionButton = (props) => {
    const {
        text = "Save",
        type = "",
        variant = "solid",
        color = "",
        size = "middle",
        disabled = false,
        onClickFunction = () => {},
        loading = false,
        icon = null,
        width = null,
        height = null,
        danger = false,
    } = props;

    const style = {};
    if (width) style.width = width;
    if (height) style.height = height;

    const [localLoading, setLocalLoading] = useState(loading);
    useEffect(() => setLocalLoading(loading), [loading]);

    return (
        <Button
            type={type}
            size={size}
            disabled={disabled}
            onClick={onClickFunction}
            loading={localLoading}
            icon={icon}
            style={style}
            danger={danger}
            onPointerDown={(e) => e.stopPropagation()}
            variant={variant}
            color={color}
        >
            {text}
        </Button>
    );
};
