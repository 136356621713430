import React, { useState, useEffect, createContext, useContext } from 'react';
import { Binoculars, CardChecklist, Envelope, Slack, MicrosoftTeams, Tools, PlayCircle } from 'react-bootstrap-icons';
import { RightCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
// 
import ConfigService from '../../services/config.service';
import ProductSettingsService from '../../services/product.service';
import { AuthContext } from '../../services/auth.service';
import LoadingScreen from '../../components/elements/LoadingScreen';

import "./styles.scss";

import { defaultEventTemplates } from './defaultEventTemplates';
import { transformDataObject } from '../../components/elements/JsonObjTable';

import PageHeader from '../../components/elements/PageHeader';
import { SideMenu } from '../../components/layouts/MainLayout/SideMenu';
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";
import { EmailsNotifModal } from '../../components/layouts/MainLayout/SideMenu/EmailNotifModal';
import { SlackNotifModal } from '../../components/layouts/MainLayout/SideMenu/SlackNotifModal';
import { TeamsNotifModal } from '../../components/layouts/MainLayout/SideMenu/TeamsNotifModal';

import {
    ProductSettinsModal,
    DataLayerEventsModal,
    MonitoringModal,
} from "./configModals";

import ScenariosMenu from './ScenariosMenu/index';

import { useMessage } from "../../components/elements/MessageCenter";

const _ = require('lodash');

const generateUniqueId = () => uuidv4();

export const simplifyDLGEventJson = (template) => {

    const modifyRows = (rows) => {
        const newObj = {};

        if (Array.isArray(rows) && rows.length > 0) {

            rows.forEach((row) => {
                const key = row.key;
                const value = row.value;
                const valueType = row.type;

                if (valueType === "array") {

                    const newNestedObj = modifyRows(value);
                    const itemsList = [];

                    if (typeof newNestedObj === "object" && Object.keys(newNestedObj).length === 0) {
                        newObj[key] = itemsList;
                    } else {
                        itemsList.push(newNestedObj);
                        newObj[key] = itemsList;
                    }


                } else if (valueType === "object") {
                    const newNestedObj = modifyRows(value);
                    newObj[key] = newNestedObj;

                } else {
                    if (!row.exatclyMatch) {
                        newObj[key] = valueType;
                    } else {
                        newObj[key] = value;
                    }

                }
            });
        }

        return newObj;
    };

    const newList = modifyRows(template);
    return newList;
};


const escapeEvents = async (events = {}, mainEvents = {}) => {
    events?.events?.forEach((row) => {
        const eventTemplate = row?.main_templates;
        const simplifiedTemplate = simplifyDLGEventJson(eventTemplate);
        row.templates = simplifiedTemplate;
        delete row.main_templates;
    });
    const updEvents = escape(JSON.stringify(events, 2));

    mainEvents?.events?.forEach((row) => delete row.templates);
    const updMainEvents = escape(JSON.stringify(mainEvents, 2));

    return {
        events: updEvents,
        mainEvents: updMainEvents,
    }
}


// Save function
export const SaveConfigData = async (params) => {
    const {
        companyId,
        currentConfig,
        setCurrentConfig,
        configParams,
        modalSaveFunction,
        handlePanelClick,
        setLoadingActive,
        currentPage,
        setCurrentPage,
        setModalContentCodeControl,
        setSideMenuState,
        success,
        error,
    } = params;

    setLoadingActive(true);

    // Deep clone configParams.templates into events and mainEvents
    const events = JSON.parse(JSON.stringify(configParams?.templates || {}));
    const mainEvents = JSON.parse(JSON.stringify(configParams?.templates || {}));
    const transEvents = JSON.parse(JSON.stringify(configParams?.transaction_templates || {}));
    const mainTransEvents = JSON.parse(JSON.stringify(configParams?.transaction_templates || {}));

    const escapedEvents = await escapeEvents(events, mainEvents);
    const escapedTransactionEvents = await escapeEvents(transEvents, mainTransEvents);

    if (configParams?.templates) {
        configParams.templates = escapedEvents?.events;
        configParams.main_templates = escapedEvents?.mainEvents;
    }

    if (configParams?.transaction_templates) {
        configParams.transaction_templates = escapedTransactionEvents?.events;
        configParams.main_transaction_templates = escapedTransactionEvents?.mainEvents;
    }

    if (configParams?.scenarios) {
        configParams.scenarios = escape(JSON.stringify(configParams.scenarios, 2));
    }

    const configObj = { ...currentConfig };

    Object.keys(configParams).forEach(key => configObj[key] = configParams[key]);

    const resp = await modalSaveFunction({ configObj, companyId });
    const respData = await resp?.json() || resp;

    switch (respData?.result) {
        case true:
            success("Configuration data successfully saved!");
            setCurrentConfig(configObj);
            break;
        default:
            error();
    }

    switch (currentPage) {
        case "dl_events":
        case "monitoring":
            setModalContentCodeControl(Date.now());
            setSideMenuState(true);
            handlePanelClick(currentPage);
            break;
        default:
            setModalContentCodeControl(null);
            handlePanelClick("empty");
            setCurrentPage("empty");
            setSideMenuState(false);
    }

    setLoadingActive(false);
}

export const ProdSettingsContext = createContext();
export const DataLayerEventsContext = createContext();
export const MonitoringContext = createContext();
export const EmailsNotifContext = createContext();
export const SlackNotifContext = createContext();
export const TeamsNotifContext = createContext();

export const ScenariosContext = createContext();

const productDescLink = `https://docs.code-cube.io/datalayer-guard/intro/`;
const notifImplementationLink = `https://docs.code-cube.io/notifications/`;
const scrapedEventsDescLink = `https://docs.code-cube.io/datalayer-guard/scraped-events/`;
const tagMangerEventsDescLink = `https://docs.code-cube.io/datalayer-guard/events-tag-manager/`;


export const DataLayerGuardConfig = () => {
    const { companyId, companyInfo, adminUser } = useContext(AuthContext);
    const [currCompanyInfo, setCurrCompanyInfo] = useState(companyInfo);

    // Using popup messages
    const { success, warning, error } = useMessage();

    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);
    const [modalWidth, setModalWidth] = useState("narrow");
    const [currentPage, setCurrentPage] = useState("empty");
    const [modalContentCodeControl, setModalContentCodeControl] = useState(Date.now());

    // Drawer Menu
    const [scenariosMenuState, setScenariosMenuState] = useState(false);

    const [loadingActive, setLoadingActive] = useState(true);

    const [currentConfig, setCurrentConfig] = useState({});
    const [configParams, setConfigParams] = useState({});

    const handlePanelClick = (page) => {
        switch (page) {
            case "dl_events":
            case "monitoring":
                setModalWidth("wide");
                setSideMenuState(true);
                setCurrentModal(modalFunc[page]);
                break;
            case "scenarios":
                setScenariosMenuState(true);
                setSideMenuState(false);
                break;

            default:
                setModalWidth("narrow");
                setSideMenuState(true);
                setCurrentModal(modalFunc[page]);
        }

        setCurrentPage(page);
    };

    const [emailNotifAvailable, setEmailNotifAvailable] = useState(false);
    const [slackNotifAvailable, setSlackNotifAvailable] = useState(false);
    const [teamsNotifAvailable, setTeamsNotifAvailable] = useState(false);

    const [timezones, setTimezones] = useState([]);
    const [timezoneName, setTimezoneName] = useState("");
    const [curTimezone, setCurTimezone] = useState(null);
    const [dlgCheckFreq, setDlgCheckFreq] = useState([]);
    const [curCheckFreq, setCurCheckFreq] = useState(null);
    const [companyDomains, setCompanyDomains] = useState([]);

    const [dlEvents, setDlEvents] = useState([]);
    const [newTemplateVersionAvailable, setnewTemplateVersionAvailable] = useState(false);

    const [transactionEvents, setTransactionEvents] = useState([]);
    const [newTransTempVersionAvailable, setNewTransTempVersionAvailable] = useState(false);
    const [databaseName, setDatabaseName] = useState("");

    // Reloading the page content only if the new company profile is different
    useEffect(() => _.isEqual(companyInfo, currCompanyInfo) ? undefined : setCurrCompanyInfo(companyInfo), [companyInfo]);


    // Add ecommerce event button functionality
    const handleTempsPushDefEcomObj = (eventName, idx, eventObj) =>
        pushDefaultEcommerceObject(eventName, idx, eventObj, dlEvents, setDlEvents);

    const handleTransTempsPushDefEcomObj = (eventName, idx, eventObj) =>
        pushDefaultEcommerceObject(eventName, idx, eventObj, transactionEvents, setTransactionEvents);

    const handleTempsCleanObj = (idx, eventObj) =>
        cleanJsonObject(idx, eventObj, dlEvents, setDlEvents);

    const handleTransTempsCleanObj = (idx, eventObj) =>
        cleanJsonObject(idx, eventObj, transactionEvents, setTransactionEvents);


    const pushDefaultEcommerceObject = (eventName = "page_load", idx, eventObj, events, setEvents) => {
        const newTemplates = [defaultEventTemplates[eventName]];

        eventObj.templates = JSON.stringify(newTemplates);
        eventObj.main_templates = transformDataObject(newTemplates);
        const newEventsList = [];

        events?.forEach(elem => {
            if (elem?.uniqueId !== idx) newEventsList.push(elem);
            else newEventsList.push(eventObj);
        });

        setEvents(newEventsList);

        return newTemplates;
    }

    // Clean JSON object
    const cleanJsonObject = (idx, eventObj, events = [], setEvents) => {
        eventObj.templates = "";
        eventObj.main_templates = "";
        const newEventsList = [];

        events?.forEach(elem => {
            if (elem?.uniqueId !== idx) newEventsList.push(elem);
            else newEventsList.push(eventObj);
        });

        setEvents(newEventsList);

        return [];
    }

    // Add event handle
    const handleTempEventAdd = () => handleEventObjectAdd(dlEvents, setDlEvents);
    const handleTransTempEventAdd = () => handleEventObjectAdd(transactionEvents, setTransactionEvents);

    const handleEventObjectAdd = (events = [], setEvents) => {
        const newEventUniqueId = generateUniqueId();

        const newEvent = {
            uniqueId: newEventUniqueId
        };
        const newEventsList = [...events, newEvent];

        setEvents(newEventsList);

        return {
            newEvent,
            newEventsList,
            newEventUniqueId,
        };
    }

    // Remove event handle
    const handleTempEventRemove = (idx) => handleEventObjectRemove(dlEvents, setDlEvents, idx);
    const handleTransTempEventRemove = (idx) => handleEventObjectRemove(transactionEvents, setTransactionEvents, idx);

    const handleEventObjectRemove = (events, setEvents, idx) => {
        const newEventsList = [...events]?.filter((elem) => elem?.uniqueId !== idx ? elem : undefined);
        setEvents(newEventsList);

        return newEventsList;
    }


    useEffect(() => {
        (async () => {
            setLoadingActive(true);

            setSideMenuState(false);

            const resp = await ConfigService.getDataLayerGuardConfigData(companyId);
            const respData = await resp.json();

            setCurrentConfig(respData?.config_data?.current_config);

            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);
            setTimezoneName(respData?.config_data?.current_config.timezone_name || "");
            setCurTimezone(respData?.config_data?.current_config?.timezone || "");
            setDlgCheckFreq(respData?.config_data?.dlg_check_freq || "");
            setCurCheckFreq(respData?.config_data?.current_config?.check_frequency || "");

            // DataLayer Events
            const tempsStr = respData?.config_data?.current_config?.main_templates || "{}";
            const tempEvents = JSON.parse(unescape(tempsStr))?.events;
            setDlEvents(tempEvents);

            setnewTemplateVersionAvailable(() => !!respData?.config_data?.current_config?.main_templates);
            setNewTransTempVersionAvailable(() => !!respData?.config_data?.current_config?.main_transaction_templates);

            // Monitoring via Tag Manager
            const transactionEventsStr = respData?.config_data?.current_config?.main_transaction_templates || "{}";
            const transEvents = JSON.parse(unescape(transactionEventsStr))?.events;
            setTransactionEvents(transEvents);
            setDatabaseName(respData?.config_data?.current_config?.database_name || "");
            setCompanyDomains(respData?.config_data?.current_config?.company_domains || []);

            const subLevel = companyInfo?.datalayer_guard_subscription_model?.toLowerCase();
            const settings = await ProductSettingsService.getProductSettings();
            const subSettings = settings?.datalayer_guard_settings[subLevel];
            setEmailNotifAvailable(subSettings?.email_notif);
            setSlackNotifAvailable(subSettings?.slack_notif);
            setTeamsNotifAvailable(subSettings?.teams_notif);

            setLoadingActive(false);
        })();
    }, [currCompanyInfo]);

    const modalFunc = {
        "empty": <></>,
        "general":
            <ProdSettingsContext.Provider
                value={{
                    productDescLink,
                    timezones,
                    timezoneName,
                    curTimezone,
                    dlgCheckFreq,
                    curCheckFreq,
                    setCurTimezone,
                    setCurCheckFreq,
                    setConfigParams,
                    companyDomains,
                    setCompanyDomains
                }}>
                <ProductSettinsModal context={ProdSettingsContext} />
            </ProdSettingsContext.Provider>,
        "dl_events":
            <DataLayerEventsContext.Provider
                value={{
                    companyId,
                    scrapedEventsDescLink,
                    dlEvents,
                    setDlEvents,
                    setConfigParams,
                    companyDomains,
                    newTemplateVersionAvailable,
                    pushDefaultEcommerceObject: handleTempsPushDefEcomObj,
                    cleanJsonObject: handleTempsCleanObj,
                    handleEventAdd: handleTempEventAdd,
                    handleEventRemove: handleTempEventRemove,
                    error,
                }}>
                <DataLayerEventsModal context={DataLayerEventsContext} />
            </DataLayerEventsContext.Provider>,
        "monitoring":
            <MonitoringContext.Provider
                value={{
                    databaseName,
                    companyId,
                    tagMangerEventsDescLink,
                    databaseName,
                    transactionEvents,
                    setTransactionEvents,
                    setConfigParams,
                    companyDomains,
                    newTransTempVersionAvailable,
                    pushDefaultEcommerceObject: handleTransTempsPushDefEcomObj,
                    cleanJsonObject: handleTransTempsCleanObj,
                    handleEventAdd: handleTransTempEventAdd,
                    handleEventRemove: handleTransTempEventRemove,
                    error,
                }}>
                <MonitoringModal context={MonitoringContext} />
            </MonitoringContext.Provider>,

        "email_notif":
            <EmailsNotifContext.Provider value={{
                notifImplementationLink,
                enableEmailNotif: currentConfig?.email_updates || false,
                curEmailsList: currentConfig?.emails?.split(";") || [],
                setConfigParams,
            }}>
                <EmailsNotifModal context={EmailsNotifContext} />
            </EmailsNotifContext.Provider>,
        "slack_notif":
            <SlackNotifContext.Provider value={{
                notifImplementationLink,
                enableSlackNotif: currentConfig?.slack_updates || false,
                slackChannel: currentConfig?.slack_channel || "",
                slackWorkspaceId: currentConfig?.slack_workspace_id || "",
                slackWebhook: currentConfig?.slack_webhook || "",
                setConfigParams,
            }}>
                <SlackNotifModal context={SlackNotifContext} />
            </SlackNotifContext.Provider>,
        "teams_notif":
            <TeamsNotifContext.Provider value={{
                notifImplementationLink,
                enableTeamsNotif: currentConfig?.teams_updates || false,
                teamsChannel: currentConfig?.teams_channel || "",
                teamsWebhook: currentConfig?.teams_webhook || "",
                setConfigParams,
            }}>
                <TeamsNotifModal context={TeamsNotifContext} />
            </TeamsNotifContext.Provider>,
    }

    return (
        <>
            <SideMenu
                key={modalContentCodeControl}
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                popoverMessage={<>No changes to save.</>}
                saveFunction={() => SaveConfigData({
                    companyId,
                    currentConfig,
                    setCurrentConfig,
                    configParams,
                    modalSaveFunction: ConfigService.saveDataLayerGuardConfigData,
                    handlePanelClick,
                    setLoadingActive,
                    currentPage,
                    setCurrentPage,
                    setModalContentCodeControl,
                    setSideMenuState,
                    success,
                    error,
                    warning,
                })}
                modalWidth={modalWidth}
            />

            <ScenariosContext.Provider
                value={{
                    companyId,
                    configParams,
                    setCurrentConfig,
                    setConfigParams,
                    scrapedEventsDescLink,
                    scenariosList: JSON.parse(unescape(currentConfig?.scenarios || "") || "[]"),

                    drawerGlobalState: scenariosMenuState,
                    setDrawerGlobalState: setScenariosMenuState,

                    defaultEventTemplates,
                    simplifyDLGEventJson,
                    transformDataObject,

                    error,

                    saveFunction: () => SaveConfigData({
                        companyId,
                        currentConfig,
                        setCurrentConfig,
                        configParams,
                        modalSaveFunction: ConfigService.saveDataLayerGuardConfigData,
                        handlePanelClick,
                        setLoadingActive,
                        currentPage,
                        setCurrentPage,
                        setModalContentCodeControl,
                        setSideMenuState,
                        success,
                        error,
                        warning,
                    })
                }}>
                <ScenariosMenu context={ScenariosContext} />
            </ScenariosContext.Provider>

            <div className="row">
                <LoadingScreen isActive={loadingActive} />
                <div className="col-12 col-sm-10 col-md-8 text-start">

                    <div>
                        <div className="row">
                            <PageHeader categoryName="configuration" pageName="DataLayer Guard" />
                            <div className="container-rows mt-4">
                                <div>
                                    <InteractivePanel
                                        icon={<Tools style={{color: "#ff661f"}} size={21} />}
                                        headerText="General settings"
                                        descriptionText="Basic settings for the configuration of the DataLayer Guard"
                                        available={true}
                                        clickFunction={() => handlePanelClick("general")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={
                                            <svg width="25" height="26" viewBox="0 0 25 26" fill="rgb(0, 225, 199)" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.0769 0H1.92308C1.41324 0.000645645 0.924468 0.206501 0.563959 0.572418C0.20345 0.938335 0.000636104 1.43444 0 1.95192V23.4231C0.000636104 23.9406 0.20345 24.4367 0.563959 24.8026C0.924468 25.1685 1.41324 25.3744 1.92308 25.375H23.0769C23.5868 25.3744 24.0755 25.1685 24.436 24.8026C24.7965 24.4367 24.9994 23.9406 25 23.4231V1.95192C24.9994 1.43444 24.7965 0.938335 24.436 0.572418C24.0755 0.206501 23.5868 0.000645645 23.0769 0ZM23.0769 1.95192V5.85577H1.92308V1.95192H23.0769ZM1.92308 23.4231V7.80769H23.0769V23.4231H1.92308Z" fill="#00E1C7"/>
                                            </svg>
                                            }
                                        headerText="Monitoring via scraped events"
                                        descriptionText="Add, edit or remove dataLayer events for monitoring"
                                        available={true}
                                        clickFunction={() => handlePanelClick("dl_events")}
                                    />
                                </div>
                                {adminUser && (
                                    <div>
                                        <InteractivePanel
                                            icon={<PlayCircle size={21} />}
                                            headerText="Monitoring via page scenarios"
                                            descriptionText="Collecting your dataLayer events using interaction scenarios"
                                            available={true}
                                            clickFunction={() => handlePanelClick("scenarios")}
                                        />
                                    </div>
                                )}
                                
                                <div>
                                    <InteractivePanel
                                        icon={
                                            <svg width="21" height="21" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.6745 23.9763L10.335 19.7448L19.6547 10.2278L24.1477 14.6312L14.6745 23.9763Z" fill="rgb(101, 53, 245)"/>
                                            <path d="M14.6927 5.26754L10.3688 0.852783L0.914075 10.2178C-0.304692 11.4366 -0.304692 13.4124 0.914075 14.6326L10.2267 24.0061L14.6302 19.8868L7.53491 12.4252L14.6927 5.26754Z" fill="#3100C4"/>
                                            <path d="M24.0844 10.2901L14.7092 0.915125C13.4891 -0.305042 11.5103 -0.305042 10.2901 0.915125C9.06995 2.13529 9.06995 4.11398 10.2901 5.33415L19.6653 14.7091C20.8855 15.9293 22.8642 15.9293 24.0844 14.7091C25.3046 13.4889 25.3046 11.5103 24.0844 10.2901Z" fill="#6535F5"/>
                                            <path d="M12.428 24.9995C14.1147 24.9995 15.4821 23.6322 15.4821 21.9456C15.4821 20.2589 14.1147 18.8916 12.428 18.8916C10.7414 18.8916 9.37402 20.2589 9.37402 21.9456C9.37402 23.6322 10.7414 24.9995 12.428 24.9995Z" fill="#3100C4"/>
                                            </svg>
                                            }
                                        headerText="Monitoring via Tag Manager"
                                        descriptionText="Set-up monitoring via Tag Manager to for example monitor purchases"
                                        available={true}
                                        clickFunction={() => handlePanelClick("monitoring")}
                                    />
                                </div>
                            </div>
                            <h3 style={{ fontSize: '1em' }} className="page-category">NOTIFICATION SETTINGS</h3>
                            <div className="container-rows">
                                <div>
                                    <InteractivePanel
                                        icon={<Envelope style={{color: "#ff661f"}} size={21} />}
                                        headerText="Email notifications"
                                        descriptionText="Configure settings to receive notifications per mail"
                                        available={emailNotifAvailable}
                                        clickFunction={() => handlePanelClick("email_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Slack size={21} />}
                                        headerText="Slack notifications"
                                        descriptionText="Configure settings to receive notifications via Slack"
                                        available={slackNotifAvailable}
                                        clickFunction={() => handlePanelClick("slack_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<MicrosoftTeams size={21} />}
                                        headerText="Teams notifications"
                                        descriptionText="Configure settings to receive notifications via Teams"
                                        available={teamsNotifAvailable}
                                        clickFunction={() => handlePanelClick("teams_notif")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
