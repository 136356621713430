import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "./styles.scss";

const MenuCategory = (props) => {
    const {
        categoryIcon,
        categoryName,
        links,
        hovered,
        hoverOutSidebar,
    } = props;

    const menuElems = links.map(elem =>
        <Link key={elem.name} to={elem.to} onClick={() => hoverOutSidebar()}
            className={`sidebar-elems-link ${elem?.active ? "" : "disabled"}`}>
            <span className="title-sliding">{elem.name}</span>
        </Link>
    );

    let [expandedCategory, setExpandedCategory] = useState(false);

    const handleClick = () => setExpandedCategory(!expandedCategory);

    useEffect(() => {
        if (categoryName === "Dashboards") setExpandedCategory(true);
    }, []);

    return (
        <div className="sidebar-menu-category">
            <div className="sidebar-category-container" onClick={handleClick}>
                {categoryIcon}
                <div className="sidebar-category-name title-sliding" >{categoryName}</div>
            </div>
            <div  className={`sidebar-elems-container ${expandedCategory ? (hovered ? 'expanded' : '') : ''}`}>
                <div className={`sidebar-elems-grid`}>
                    {menuElems}
                </div>
            </div>
        </div>
    );
}

export default MenuCategory;