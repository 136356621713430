// index.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CaretDownFill, CaretUpFill, ArrowCounterclockwise, ChevronDown } from "react-bootstrap-icons";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale,
    BarController, BarElement, ArcElement, PointElement,
    LineController, LineElement, Title, Tooltip, Legend,
} from "chart.js";
import {
    errorsChartOptions,
    customMarginPlugin,
} from './chartOptions';
import consentMonitoring from './consentMonitoring';
import cloudRun from './cloudRun';

// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import DashboardService from '../../services/dashboard.service';
import { AuthContext } from '../../services/auth.service';

import PageHeader from '../../components/elements/PageHeader';
import LoadingScreen from '../../components/elements/LoadingScreen';
import Table from '../../components/elements/Table';
import Tabs from '../../components/elements/Tabs';
import BarChart from '../../components/elements/BarChart';

import localStorageService from '../../services/localStorage.service';
import "./styles.scss";
import { Select, Space, Input, Row, Col, Flex, Layout, DatePicker, Segmented, Tooltip as AntdTooltip } from 'antd';
import { StopOutlined } from '@ant-design/icons';

import LineChart from './LineChart';

const { Content } = Layout;
const { RangePicker } = DatePicker;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarController,
    BarElement,
    ArcElement,
    PointElement,
    LineController,
    LineElement,
    Title,
    Tooltip,
    Legend,
);


export const getDateRangeCode = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(endDate - startDate);

    // Number of milliseconds in a day
    const oneDayMs = 1000 * 60 * 60 * 24;

    // Number of milliseconds in an hour
    const oneHourMs = 1000 * 60 * 60;

    // Get the current date and time
    const now = new Date();

    // Calculate the difference between now and the end date
    const differenceFromNowMs = Math.abs(now - endDate);

    let result;

    switch (differenceMs) {
        case oneDayMs:
            if (differenceFromNowMs <= oneDayMs) {
                result = "last_24_hours";
            } else if (differenceMs === oneDayMs) {
                result = "one_day";
            }
            break;
        case oneHourMs:
            result = "last_hour";
            break;
        case 0:
            if (startDate.toDateString() === now.toDateString()) {
                result = "today";
            } else {
                result = "one_day";
            }
            break;
        default:
            if (startDate.toDateString() === now.toDateString() && endDate.toDateString() === now.toDateString()) {
                result = "today";
            } else {
                result = null;
            }
    }

    return result;
}

export const TagMonitorDash = () => {

    const navigate = useNavigate();

    // Define the compant from the context
    const { companyId, companyInfo } = useContext(AuthContext);
    const [companyID, setCompanyID] = useState(companyId);
    const [clientTimeStamp, setClientTimeStamp] = useState("Europe/Amsterdam");
    const [datasetExists, setDatasetExists] = useState(true);

    const tagMonitorServer = companyInfo?.tag_monitor_server;

    // Set the loading status for the loading spinner
    const [updateLoadFlag, setUpdateLoadFlag] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);

    // Filters data
    const [filtersType, setFiltersType] = useState("client");
    const [filtersClientSide, setFiltersClientSide] = useState([]);
    const [filtersServerSide, setFiltersServerSide] = useState([]);

    const [activeTab, setActiveTab] = useState("error_monitoring");
    const [filtersData, setFiltersData] = useState([]);

    const [tagsList, setTagsList] = useState([]);
    const [tagsDropdownList, setTagsDropdownList] = useState([]);
    const [cidList, setCidList] = useState([]);
    const [eventNameList, setEventNameList] = useState([]);
    const [hostsList, setHostsList] = useState([]);

    const [selectedTags, setSelectedTags] = useState([]);
    const [chosenContainerId, setChosenContainerId] = useState([]);
    const [chosenEventName, setChosenEventName] = useState([]);
    const [chosenDomain, setChosenDomain] = useState([]);
    const [searchUrlValue, setSearchUrlValue] = useState("");

    const [currentSelectedTags, setCurrentSelectedTags] = useState([]);
    const [currentChosenContainerId, setCurrentChosenContainerId] = useState([]);
    const [currentChosenEventName, setCurrentChosenEventName] = useState([]);
    const [currentChosenDomain, setCurrentChosenDomain] = useState([]);
    const [currentSearchUrlValue, setCurrentSearchUrlValue] = useState("");


    const [filtersDisabled, setFiltersDisabled] = useState(true);
    const [tabType, setTabType] = useState("errors_client");

    const [excludedTagsClient, setExcludedTagsClient] = useState([]);
    const [excludedTagsServer, setExcludedTagsServer] = useState([]);


    // Check and mute tags excluded from monitoring
    const isTagExcluded = (tag) => {
        const { tag_name, tag_id } = tag;
      
        return (
          excludedTagsClient.includes(tag_name) ||
          excludedTagsClient.includes(tag_id) ||
          excludedTagsServer.includes(tag_name) ||
          excludedTagsServer.includes(tag_id)
        );
    };

    useEffect(() => {
        if (tagsList.length > 0) {
          const updatedTags = tagsList.map(tag => ({
            ...tag,
            excluded: isTagExcluded(tag)
          }));
          setTagsList(updatedTags);
      
          const updatedTagOptions = updatedTags.map((tag, index) => ({
            label: (
              <span className={tag.excluded ? 'excluded-tag' : ''}>
                {tag.tag_name || tag.tag_id}
                {tag.excluded && (
                  <>
                    <StopOutlined className="excluded-icon font-bold" />
                    <span className="excluded-text">excluded</span>
                  </>
                )}
              </span>
            ),
            value: tag.tag_name || tag.tag_id,
            className: tag.excluded ? 'excluded-tag' : '',
            key: `${tag.tag_name || tag.tag_id}-${index}`
          }));
          setTagOptions(updatedTagOptions);
        }
      }, [excludedTagsClient, excludedTagsServer]);

    const tabsInfo = {
        "errors_client": {
            title: "Client-side error monitoring",
            description: "This dashboard highlights errors in your client-side Tag Management container, letting you address issues before they affect data and marketing performance. Track errors by tag, event, domain, or container in Google Tag Manager to maintain data integrity and enhance campaign outcomes.",
        },
        "errors_server": {
            title: "Server-side error monitoring",
            description: "Monitor your server-side tags with real-time data, regardless of your container's hosting location. This dashboard shows errors per tag, event, domain, or container in Google Tag Manager, helping you quickly address issues to ensure data quality and effective marketing performance.",
        },
        "tag_volume_client": {
            title: "Tag volume (client-side)",
            description: "This dashboard monitors tag inactivity, alerting you to sudden drops in data collection. By using static rules and real-time insights, it helps you quickly identify and address issues, ensuring continuous data flow and preventing disruptions to your analytics and marketing activities.",
        },
        "tag_volume_server": {
            title: "Tag volume (server-side)",
            description: "This dashboard monitors tag inactivity, alerting you to sudden drops in data collection. By using static rules and real-time insights, it helps you quickly identify and address issues, ensuring continuous data flow and preventing disruptions to your analytics and marketing activities.",
        },
        "site_performance": {
            title: "Impact on site performance",
            description: "This dashboard tracks how tags impact site performance, offering insights on loading times and user experience to help you optimize tag implementation without compromising data collection or marketing.",
        },
        "consent_monitoring": {
            title: "Consent monitoring",
            description: "Track and manage consent settings with our dashboard to ensure accurate data and strong campaign results. It monitors consent statuses for all tags and triggers in Google Tag Manager, offering real-time alerts and insights to help you maintain data integrity and optimize marketing efforts.",
        },
        "cloud_run": {
            title: "Cloud Run",
            description: "With Cloud Run Monitoring your server-side Cloud Run instances will be monitored in real-time. An essential part to monitor as your whole marketing- and analytics set-up is dependent on the health of this server.",
        },
    }

    const handleSearchUrlValue = (e) => setSearchUrlValue(e.target.value);
    const [chartTitle, setChartTitle] = useState(tabsInfo["errors_client"]?.title);
    const [chartDesc, setChartDesc] = useState(tabsInfo["errors_client"]?.description);

    // DatePicker
    const [startDate, setStartDate] = useState(dayjs().add(-7, 'd').set('hour', 0).set('minute', 0).set('second', 0));
    const [endDate, setEndDate] = useState(dayjs().set('hour', 23).set('minute', 59).set('second', 59));

    const timestampFormat = "YYYY-MM-DD HH:mm:ss";
    const timestampFormatInput = "YYYY-MM-DD HH:mm";

    const [differenceOneDayOrOneHour, setDifferenceOneDayOrOneHour] = useState(null);

    const rangePresets = [
        {
            label: 'Last 1 Hour',
            value: [dayjs().subtract(1, 'hour'), dayjs()],
        },
        {
            label: 'Last 24 Hours',
            value: [dayjs().subtract(24, 'hour'), dayjs()],
        },
        {
            label: 'Last 7 Days',
            value: [
                dayjs().add(-7, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
        {
            label: 'Last 14 Days',
            value: [
                dayjs().add(-14, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
        {
            label: 'Last 30 Days',
            value: [
                dayjs().add(-30, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
        {
            label: 'Last 90 Days',
            value: [
                dayjs().add(-90, 'd').set('hour', 0).set('minute', 0).set('second', 0),
                dayjs().set('hour', 23).set('minute', 59).set('second', 59)
            ],
        },
    ];
    const onRangeChange = (dates = [], dateStrings = []) => {
        const dateStart = dates?.length ? dates[0] : "";
        const dateEnd = dates?.length ? dates[1] : "";
        const dateStartStr = dateStrings?.length ? dateStrings[0] : "";
        const dateEndStr = dateStrings?.length ? dateStrings[1] : "";

        const oneDaySpan = getDateRangeCode(dateStartStr, dateEndStr);
        setDifferenceOneDayOrOneHour(oneDaySpan);

        // If new date has been selected update the charts
        if (dateStart !== startDate || dateEnd !== endDate) {
            if (dateStart && dateEnd && companyID === companyId) setUpdateLoadFlag(!updateLoadFlag);
        }

        setStartDate(dateStart);
        setEndDate(dateEnd);
    };

    const setDefaultDateRange = () => {
        setStartDate(dayjs().add(-7, 'd').set('hour', 0).set('minute', 0).set('second', 0));
        setEndDate(dayjs().set('hour', 23).set('minute', 59).set('second', 59));
    }

    const [refresh, setRefresh] = useState(false);

    // Reset all values for the filter
    const refreshFilters = () => {
        setChosenContainerId([]);
        setChosenEventName([]);
        setChosenDomain([]);
        setSearchUrlValue("");
        setSelectedTags([]);
        setDefaultDateRange();
        setDifferenceOneDayOrOneHour(null);
        setRefresh(true);
        updateFiltersData(initialFilters);
        setCurrentChosenContainerId([]);
        setCurrentChosenDomain([]);
        setCurrentChosenEventName([]);
        setCurrentSearchUrlValue("");
        setCurrentSelectedTags([]);
        setSnapshotsList(initialSnapshotsList);
    }

    useEffect(() => {
        if (refresh) {
            setDifferenceOneDayOrOneHour(null);
            updateCharts(initialData, "all");
        }
    }, [refresh])


    // Handle the filters type and tab type on tabs click
    const handleDashTypeChangeValue = (val = filtersType, clickedTabType = "", activeTabStr) => {
        setFiltersType(val);
    
        switch (val) {
            case "client":
                setFiltersData(filtersClientSide);
                break;
            case "server":
                setFiltersData(filtersServerSide);
                break;
            default:
                setFiltersData([]);
        }
    
        setChartTitle(tabsInfo[clickedTabType]?.title);
        setChartDesc(tabsInfo[clickedTabType]?.description);
    
        let clickedFilterType = clickedTabType.split("_")[clickedTabType.split("_").length - 1];
        clickedFilterType = clickedTabType.includes("performance") || clickedTabType.includes("consent") ? "client" : clickedFilterType;
    
        if (tabType !== clickedTabType) {
            setTabType(clickedTabType);
            updateFilters(val);
            setActiveTab(activeTabStr);
            handleTabsVisibilityChange(clickedTabType);
            setTagsDropdownList([]);
    
            // when switching between the tabs, if the selected tab does not match
            // the current filters show the current filters selected
            if (clickedFilterType === filtersDataType) {
                setSelectedTags(currentSelectedTags);
                setChosenContainerId(currentChosenContainerId);
                setChosenEventName(currentChosenEventName);
                setChosenDomain(currentChosenDomain);
                setSearchUrlValue(currentSearchUrlValue);
            } 
            else {
                setSelectedTags([]);
                setChosenContainerId([]);
                setChosenEventName([]);
                setChosenDomain([]);
                setSearchUrlValue("");
            }
        }
    };

    const generateDropdownContent = (tagsData) => {
        const tags = [];

        tagsData?.forEach(elem => {
            if (elem.tag_name && elem.tag_name !== "undefined") {
                tags.push({
                    tag_id: elem.tag_id,
                    tag_name: elem.tag_name,
                    selected: false,
                });
            }

        });

        const dropdownList = [...new Set(tags?.sort((a, b) => {

            if (a.tag_name && b.tag_name) {
                const nameA = a.tag_name.toUpperCase();
                const nameB = b.tag_name.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }
        }))];

        return dropdownList;
    }


    const updateFilters = () => {
        const cid = [...new Set(filtersData.map((item) => item.container_id))];
        const hosts = [...new Set(filtersData.map((item) => item.hostname))];
        const eventNames = [... new Set(filtersData.map((item) => item.event_name))];

        setCidList(cid);
        setHostsList(hosts);
        setEventNameList(eventNames);

        const tagsDropdownData = generateDropdownContent(tagsList);
        setTagsDropdownList(tagsDropdownData);
    }

    useEffect(() => updateFilters(), [filtersData]);

    // Tabs
    const [tabsVisibility, setTabsVisibility] = useState({
        "errors_client": true,
        "errors_server": false,
        "tag_volume_client": false,
        "tag_volume_server": false,
        "site_performance": false,
        "consent_monitoring": false,
        "cloud_run": false,
    });


    const handleTabsVisibilityChange = (val) => {
        const updatedTabs = tabsVisibility;
        Object.keys(updatedTabs).forEach(key => updatedTabs[key] = (key === val ? true : false));
        setTabsVisibility(updatedTabs);
    };


    // Charts   
    const [clientErrorsLabels, setClientErrorsLabels] = useState([]);
    const [clientErrorsData, setClientErrorsData] = useState([]);
    const [clientErrorsOptions, setClientErrorsOptions] = useState([]);

    const [clientErrorsTable, setClientErrorsTable] = useState([]);
    const [serverErrorsTable, setServerErrorsTable] = useState([]);

    const [serverErrorsLabels, setServerErrorsLabels] = useState([]);
    const [serverErrorsData, setServerErrorsData] = useState([]);
    const [serverErrorsOptions, setServerErrorsOptions] = useState([]);

    const [tagVolClientData, setTagVolClientData] = useState([]);
    const [tagVolServerData, setTagVolServerData] = useState([]);

    const [tagVolTotalClientLabels, setTagVolTotalClientLabels] = useState([]);
    const [tagVolTotalClientData, setTagVolTotalClientData] = useState([]);
    const [tagVolTotalClientOptions, setTagVolTotalClientOptions] = useState([]);

    const [tagVolTotalServerLabels, setTagVolTotalServerLabels] = useState([]);
    const [tagVolTotalServerData, setTagVolTotalServerData] = useState([]);
    const [tagVolTotalServerOptions, setTagVolTotalServerOptions] = useState([]);

    const [sitePerfPerTagLabels, setSitePerfPerTagLabels] = useState([]);
    const [sitePerfPerTagData, setSitePerfPerTagData] = useState([]);
    const [sitePerfPerTagOptions, setSitePerfPerTagOptions] = useState([]);

    const [sitePerfPerTriggerLabels, setSitePerfPerTriggerLabels] = useState([]);
    const [sitePerfPerTriggerData, setSitePerfPerTriggerData] = useState([]);
    const [sitePerfPerTriggerOptions, setSitePerfPerTriggerOptions] = useState([]);

    const [consentMonitoringData, setConsentMonitoringData] = useState([]);
    const [cloudRunData, setCloudRunData] = useState([]);

    // snapshot
    const [snapshotToReview, setSnapshotToReview] = useState({});
    const [initialSnapshotsList, setInitialSnapshotsList] = useState([]);
    const [snapshotExpanded, setSnapshotExpanded] = useState(false);
    const [snapshotsList, setSnapshotsList] = useState([]);
    const [aggregatedConsentMonitoringData, setAggregatedConsentMonitoringData] = useState([]);

    const [initialData, setInitialData] = useState({});
    const [freshData, setFreshData] = useState({});
    const [initialFilters, setInitialFilters] = useState({});

    // Function to aggregate the consent monitoring data by consent_name
    const aggregateConsentMonitoringData = (data) => {
        if (!Array.isArray(data)) {
            return [];
        }

        const aggregatedData = data.reduce((acc, item) => {
            const { consent_name, granted, denied, total_values } = item;
            if (!acc[consent_name]) {
                acc[consent_name] = {
                    consent_name,
                    granted: 0,
                    denied: 0,
                    total_values: 0,
                };
            }
            acc[consent_name].granted += granted;
            acc[consent_name].denied += denied;
            acc[consent_name].total_values += total_values;
            return acc;
        }, {});

        return Object.values(aggregatedData).map(item => ({
            ...item,
            approval_percent: ((item.granted / item.total_values) * 100).toFixed(2),
        }));
    };


    const handleSnapshotAreaUpdate = (row) => {
        if (snapshotToReview === row) {
            setSnapshotExpanded(!snapshotExpanded);
        } else {
            setSnapshotExpanded(true);
        }
        setSnapshotToReview(row);
    };


    const updateCharts = (context, chartType) => {
        const {
            error_query_client,
            error_query_server,
            tag_vol_query_client,
            tag_vol_query_server,
            tag_vol_total_calls_client,
            tag_vol_total_calls_server,
            site_performance_per_tag,
            site_performance_per_trigger,
            consent_monitoring,
            cloud_run,
        } = context;

        let perTagData, perTriggerData;

        switch (chartType) {
            case "errors":
                setClientErrorsData(renderLineChart({
                    dataArr: error_query_client,
                    aggrParam: "total_fails_percent",
                    setLabelFunc: setClientErrorsLabels,
                    chartOptionFunc: setClientErrorsOptions,
                    chartOptionType: "error-monitoring",
                    chartElementsNum: clientErrorsEntriesNum,
                }));

                setServerErrorsData(renderLineChart({
                    dataArr: error_query_server,
                    aggrParam: "total_fails_percent",
                    setLabelFunc: setServerErrorsLabels,
                    chartOptionFunc: setServerErrorsOptions,
                    chartOptionType: "error-monitoring",
                    chartElementsNum: serverErrorsEntriesNum,
                }));

                // Rendering tables
                setClientErrorsTable(aggregateErrorsByTagName(error_query_client));
                setServerErrorsTable(aggregateErrorsByTagName(error_query_server));
                break;

            case "tag-vol":
                setTagVolTotalClientData(renderLineChart({
                    dataArr: tag_vol_total_calls_client,
                    aggrParam: "total_calls",
                    setLabelFunc: setTagVolTotalClientLabels,
                    chartOptionFunc: setTagVolTotalClientOptions,
                    chartOptionType: "tag-volume",
                    chartElementsNum: clientTagVolEntriesNum,
                }));

                setTagVolTotalServerData(renderLineChart({
                    dataArr: tag_vol_total_calls_server,
                    aggrParam: "total_calls",
                    setLabelFunc: setTagVolTotalServerLabels,
                    chartOptionFunc: setTagVolTotalServerOptions,
                    chartOptionType: "tag-volume",
                    chartElementsNum: serverTagVolEntriesNum,
                }));

                setTagVolClientData(tag_vol_query_client);
                setTagVolServerData(tag_vol_query_server);

                break;

            case "site-performance":
                // Rendering site performance bar charts
                perTagData = formatSitePerfChart(site_performance_per_tag, "perTagPerformance");
                setSitePerfPerTagLabels(perTagData.labels);
                setSitePerfPerTagData(perTagData.datasets);
                setSitePerfPerTagOptions(perTagData.options);

                perTriggerData = formatSitePerfChart(site_performance_per_trigger, "perTriggerPerformance");
                setSitePerfPerTriggerLabels(perTriggerData.labels);
                setSitePerfPerTriggerData(perTriggerData.datasets);
                setSitePerfPerTriggerOptions(perTriggerData.options);
                break;
            case "consent-monitoring":
                setConsentMonitoringData(consent_monitoring);
                setAggregatedConsentMonitoringData(aggregateConsentMonitoringData(consent_monitoring));
                break;
            case "cloud-run":
                setCloudRunData(cloud_run);
                break;

            case "all":
                //errors
                setClientErrorsData(renderLineChart({
                    dataArr: error_query_client,
                    aggrParam: "total_fails_percent",
                    setLabelFunc: setClientErrorsLabels,
                    chartOptionFunc: setClientErrorsOptions,
                    chartOptionType: "error-monitoring",
                    chartElementsNum: clientErrorsEntriesNum,
                }));

                setServerErrorsData(renderLineChart({
                    dataArr: error_query_server,
                    aggrParam: "total_fails_percent",
                    setLabelFunc: setServerErrorsLabels,
                    chartOptionFunc: setServerErrorsOptions,
                    chartOptionType: "error-monitoring",
                    chartElementsNum: serverErrorsEntriesNum,
                }));

                setClientErrorsTable(aggregateErrorsByTagName(error_query_client));
                setServerErrorsTable(aggregateErrorsByTagName(error_query_server));


                //tag vol
                setTagVolTotalClientData(renderLineChart({
                    dataArr: tag_vol_total_calls_client,
                    aggrParam: "total_calls",
                    setLabelFunc: setTagVolTotalClientLabels,
                    chartOptionFunc: setTagVolTotalClientOptions,
                    chartOptionType: "tag-volume",
                    chartElementsNum: clientTagVolEntriesNum,
                }));

                setTagVolTotalServerData(renderLineChart({
                    dataArr: tag_vol_total_calls_server,
                    aggrParam: "total_calls",
                    setLabelFunc: setTagVolTotalServerLabels,
                    chartOptionFunc: setTagVolTotalServerOptions,
                    chartOptionType: "tag-volume",
                    chartElementsNum: serverTagVolEntriesNum,
                }));

                setTagVolClientData(tag_vol_query_client);
                setTagVolServerData(tag_vol_query_server);


                // Rendering site performance bar charts
                perTagData = formatSitePerfChart(site_performance_per_tag, "perTagPerformance");
                setSitePerfPerTagLabels(perTagData.labels);
                setSitePerfPerTagData(perTagData.datasets);
                setSitePerfPerTagOptions(perTagData.options);

                perTriggerData = formatSitePerfChart(site_performance_per_trigger, "perTriggerPerformance");
                setSitePerfPerTriggerLabels(perTriggerData.labels);
                setSitePerfPerTriggerData(perTriggerData.datasets);
                setSitePerfPerTriggerOptions(perTriggerData.options);

                setConsentMonitoringData(consent_monitoring);
                setAggregatedConsentMonitoringData(aggregateConsentMonitoringData(consent_monitoring));

                setCloudRunData(cloud_run);

                break;
        }
    }


    const retrieveTimestampLabels = (arr, labelField) => {
        const sortedTimestamps = [...new Set(arr.map(item => item[labelField]))].sort((a, b) => {
            const timeA = new Date("1970/01/01 " + a);
            const timeB = new Date("1970/01/01 " + b);
            return timeA - timeB;
        });

        return sortedTimestamps;
    };

    const generateTimestamps = (startDate, endDate) => {
        const timestampsList = [];
        const now = new Date();
    
        const amsterdamTime = now.toLocaleString('en-US', { timeZone: clientTimeStamp });
        const amsterdamHour = new Date(amsterdamTime).getHours();
        const amsterdamDate = new Date(amsterdamTime);
    
        const lastHourStart = new Date(amsterdamDate.setHours(amsterdamDate.getHours() - 1));
        lastHourStart.setMinutes(amsterdamDate.getMinutes());
    
        const timeSpan = companyID !== companyId ? null : differenceOneDayOrOneHour;
    
        let startDateNewDate = startDate;
        let endDateNewDate = endDate;
    
        const formatDate = (date) => {
            // Extract year, month, and day
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
            let day = date.getDate().toString().padStart(2, '0');
    
            // Format the date as YYYY-MM-DD
            let formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        }
    
        switch (timeSpan) {
            case "one_day":
            case "last_24_hours":
                for (let i = amsterdamHour; i >= 0; i--) {
                    const hour = `${i.toString()}:00`;
                    const date = formatDate(new Date(endDateNewDate));
                    const fullDateString = `${date} ${hour}`
                    timestampsList.push(fullDateString);
                }
    
                for (let i = 23; i >= amsterdamHour; i--) {
                    const hour = `${i.toString()}:00`;
                    const date = formatDate(new Date(startDateNewDate));
                    const fullDateString = `${date} ${hour}`
                    timestampsList.push(fullDateString);
                }
                break;
    
            case "last_hour":
                for (let i = 0; i < 60; i++) {
                    const timeEntry = new Date(lastHourStart.getTime() + i * 60000);
                    const hours = ('0' + timeEntry.getHours()).slice(-2); // Extract hours and pad with leading zero if necessary
                    const minutes = ('0' + timeEntry.getMinutes()).slice(-2); // Extract minutes and pad with leading zero if necessary
                    const formattedTime = `${hours}:${minutes}`;
                    timestampsList.push(formattedTime);
                }
                break;
    
            case "today":
                for (let i = 0; i <= amsterdamHour; i++) {
                    const hour = `${i.toString()}:00`;
                    const date = formatDate(new Date(now));
                    const fullDateString = `${date} ${hour}`
                    timestampsList.push(fullDateString);
                }
                break;
    
            default:
                while (startDateNewDate <= endDateNewDate) {
                    timestampsList.push(startDateNewDate.format("YYYY-MM-DD")); // Format as "YYYY-MM-DD"
                    startDateNewDate = startDateNewDate.add(1, "d"); // Increment the date by 1 day
                }
                break;
    
        }

        return timestampsList;
    }

    const addMissingTimestamps = (list, aggrParam) => {

        const timestampsList = generateTimestamps(startDate, endDate);

        // Group the results by the combination of tag_id and container_id
        const groupedData = {};
        const errordata = aggrParam === "total_fails_percent" ? true : false;

        list?.forEach((item) => {
            const key = `${item.tag_id}_${item.container_id}`;

            if (!groupedData[key]) {
                groupedData[key] = [];

            }
            groupedData[key].push(item);
        });


        // Add missing timestamps with 0 total_calls for other tags
        for (const key in groupedData) {
            let currentTagData = groupedData[key];

            if (currentTagData) {
                const missingTimestamps = timestampsList.filter((timestamp) => !currentTagData.some((item) => item.timestamp === timestamp));

                if (currentTagData.length > 0) {
                    missingTimestamps.forEach((timestamp) => {
                        currentTagData.push({
                            container_id: currentTagData[0].container_id,
                            tag_id: currentTagData[0].tag_id,
                            tag_name: currentTagData[0].tag_name,
                            timestamp: timestamp,
                            total_calls: 0,
                            data_type: errordata ? currentTagData[0].data_type : null,
                            total_fails: errordata ? 0 : null,
                            total_fails_percent: errordata ? 0 : null,
                        });
                    });
                }
            }
        }

        // extract all objects inside the nested arrays and create a new list
        let arrayOfArrays = Object.values(groupedData);
        const results = arrayOfArrays.reduce((accumulator, currentArray) => {
            return [...accumulator, ...currentArray];
        }, []);


        return results;
    }

    const getTimestamps = (dataArr, aggrParam, setLabelFunc) => {
        let sortedData;
    
        const addedMissingTimestampsArrData = addMissingTimestamps(dataArr, aggrParam);
        const timeSpan = companyID !== companyId ? null : differenceOneDayOrOneHour;
    
        switch (timeSpan) {
            case "one_day":
            case "last_24_hours":
            case "today":
                // sort the timestamps when they are in the format of "YYYY-MM-DD HH:MM"
                function parseTimestamp(timestamp) {
                    if (!timestamp) return new Date(0); // Return a default date if timestamp is undefined
                    const [date, time] = timestamp.split(" ");
                    if (!date || !time) return new Date(0); // Return a default date if date or time is undefined
                    const [year, month, day] = date.split("-").map(Number);
                    const [hours, minutes] = time.split(":").map(Number);
                    return new Date(year, month - 1, day, hours, minutes);
                }
    
                // Sort the array of objects by timestamp property in ascending order
                sortedData = addedMissingTimestampsArrData.sort(
                    (a, b) => parseTimestamp(a.timestamp) - parseTimestamp(b.timestamp)
                );
                break;
    
            case "last_hour":
                // sort the timestamps when they are in the format of "HH:MM"
                function timeToMinutes(time) {
                    if (!time) return 0; // Return 0 if time is undefined
                    const [hours, minutes] = time.split(":").map(Number);
                    return hours * 60 + minutes;
                }
    
                // Sort the array of objects by timestamp property in ascending order
                sortedData = addedMissingTimestampsArrData.sort(
                    (a, b) => timeToMinutes(a.timestamp) - timeToMinutes(b.timestamp)
                );
                break;
    
            default:
                // sort the timestamps when they are in the format of regular dates
                sortedData = addedMissingTimestampsArrData.sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                );
        }
    
        setLabelFunc(retrieveTimestampLabels(sortedData, "timestamp"));
        return sortedData;
    }

     const renderLineChart = (props) => {
        const {
            dataArr,
            aggrParam,
            setLabelFunc,
            chartOptionType,
            chartOptionFunc,
            chartElementsNum = 10,
        } = props;
    
        let sortedData = getTimestamps(dataArr, aggrParam, setLabelFunc);
        const dataGroupedByTagAndContainerId = {};
    
        sortedData.forEach(elem => {
            const key = `${elem.tag_id}_${elem.container_id}`; // Creating composite key
            if (!dataGroupedByTagAndContainerId[key]) {
                dataGroupedByTagAndContainerId[key] = {
                    tagId: elem.tag_id,
                    containerId: elem.container_id,
                    tagName: elem.tag_name,
                    rows: [],
                    aggrValue: 0,
                };
            }
    
            dataGroupedByTagAndContainerId[key].rows.push(elem);
            dataGroupedByTagAndContainerId[key].aggrValue += elem[aggrParam];
        });
    
        const sortedGroupedData =
         Object.keys(dataGroupedByTagAndContainerId)
            .sort((a, b) => dataGroupedByTagAndContainerId[b].aggrValue - dataGroupedByTagAndContainerId[a].aggrValue)
            .map(key => dataGroupedByTagAndContainerId[key]);
    
        return convertArrToChartData({
            dataArr: sortedGroupedData,
            aggrParam,
            chartOptionType,
            chartOptionFunc,
            chartElementsNum,
        });
    };


    const aggregateErrors = (dataArr) => {
        let aggregateErrorsByTagName = [];
    
        aggregateErrorsByTagName = dataArr.map((elem) => {
            const columns = {
                aggrValue: elem.aggrValue,
                containerId: elem.containerId,
                tagId: elem.tagId,
                tagName: elem.tagName,
                eventName: elem.eventName // Include event_name in the aggregation
            };
    
            const rows = [];
    
            for (let row of elem.rows) {
                const newRow = {
                    container_id: row.container_id,
                    data_type: row.data_type,
                    tag_id: row.tag_id,
                    tag_name: row.tag_name,
                    event_name: row.event_name, // Include event_name in the aggregation
                    timestamp: row.timestamp,
                    total_calls: row.total_calls,
                    total_fails: row.total_fails,
                    total_fails_percent: row.total_fails_percent,
                };
    
                const exists = rows.some(obj => (
                    elem.containerId === obj.container_id &&
                    elem.tagId === obj.tag_id &&
                    row.timestamp === obj.timestamp &&
                    row.tag_name === obj.tag_name &&
                    row.event_name === obj.event_name // Include event_name in the aggregation
                ));
    
                if (!exists) {
                    rows.push(newRow);
                }
                else {
                    const sameRow = rows.find(obj => (
                        elem.containerId === obj.container_id &&
                        elem.tagId === obj.tag_id &&
                        row.timestamp === obj.timestamp &&
                        row.tag_name === obj.tag_name &&
                        row.event_name === obj.event_name // Include event_name in the aggregation
                    ));
    
                    sameRow["total_calls"] += row["total_calls"];
                    sameRow["total_fails"] += row["total_fails"];
                    sameRow["total_fails_percent"] = sameRow["total_calls"] == 0 ? 0 : ((sameRow["total_fails"] / sameRow["total_calls"]) * 100).toFixed(2);
    
                    Object.assign(sameRow, sameRow);
                }
            }
    
            columns["rows"] = rows;
            return columns;
        });
    
        return aggregateErrorsByTagName;
    };


    const convertArrToChartData = (props) => {
        const {
            dataArr,
            aggrParam,
            chartOptionType,
            chartOptionFunc,
            chartElementsNum = 10,
        } = props;

        let aggregatedErrorsByTagName = [];

        const tagColors = ['#6535F5', '#00E1C7', '#2B2BB5', '#FF661F',
            '#f74f02', '#bc74f7', '#41fabf', '#fa9a6e', '#f75289', "#FF5733", "#33A8FF", "#FFC733", "#33FF57",
            "#FF33A8", "#3357FF", "#33FFA8", "#FF9C33", "#A8FF33", "#A833FF", "#33D4FF", "#FF33FF", "#FFD433",
            "#5733FF", "#33FF9C", "#FF5733", "#33FFC7", "#FF3357", "#57FF33", "#33FF33", "#33F2FF", "#FF57A8",
            "#33A8A8", "#FFA8A8", "#A8FFA8", "#FF5733", "#33D4FF", "#FF9C57", "#57A8FF", "#FF33A8", "#33FFC7",
            "#FFA833", "#57FFA8", "#5733FF", "#33A8FF", "#FF5733", "#33FFA8", "#3357FF", "#FF33FF", "#33FF57",
        ];

        // aggregate errors by tag name regardless of their event_name for line chart
        if (chartOptionType === "error-monitoring") {
            aggregatedErrorsByTagName = aggregateErrors(dataArr);
        }

        const shortenedDataArr = chartOptionType === "error-monitoring" ? aggregatedErrorsByTagName.slice(0, chartElementsNum) : dataArr.slice(0, chartElementsNum);

        const chartData = shortenedDataArr.map((elem, idx) => {
            return {
                label: elem.tagName !== "undefined" && elem.tagName ? elem.tagName : elem.tagId,
                data: elem.rows.map(row => row[aggrParam]),
                borderColor: tagColors[idx], // Assign unique color
                backgroundColor: tagColors[idx], // Area fill color
                borderWidth: 3, // Adjust line thickness
                fill: false, // Fill the area under the line
                tension: 0.4, // Adjust curve tension (0.4 for moderate curve)
                pointRadius: 0, // Remove data points
            }
        });

        // get the maximum value of the data for the line chart to modify the Y axis range accordingly
        let maxValue;
        const valueList = [];

        if (chartData.length > 0) {
            for (let data of chartData) {
                for (let i of data.data) {
                    valueList.push(i)
                }
            }
        }

        if (valueList.length > 0) {
            maxValue = Math.max(...valueList);
        }

        chartOptionFunc(errorsChartOptions(chartOptionType, maxValue));

        return chartData;
    }

    const generateColorShades = (length) => {
        // Start color: #6535F5 (dark purple)
        const startColor = [101, 53, 245];

        // End color: #FFFFFF (white)
        const endColor = [255, 255, 255];

        const colorShades = [];

        for (let i = 0; i < length; i++) {
            // Calculate intermediate color
            const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * (i / (length - 1)));
            const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * (i / (length - 1)));
            const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * (i / (length - 1)));

            // Convert RGB to hex
            const hexColor = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

            // Add the color to the list
            colorShades.push(hexColor);
        }

        return colorShades;
    }


    const formatSitePerfChart = (arr, chartType) => {
        let colorList;
        let labels;
        let chartTitleText;
        let XaxixTitle;
        let sortedChartData;

        // Sort chartData based on avg_ex_time in descending order
        sortedChartData = arr?.sort((a, b) => b.avg_ex_time - a.avg_ex_time);


        switch (chartType) {
            case "perTagPerformance":
                // purple shades

                colorList = generateColorShades(sortedChartData?.length || 0);
                labels = sortedChartData?.map(entry => entry.tag_name);
                chartTitleText = "Average execution time (milliseconds)";
                XaxixTitle = "Total impact on performance per tag";

                break;
            case "perTriggerPerformance":
                // blue shades
                colorList = [
                    "rgba(0, 225, 199, 1)", "rgba(0, 225, 199, .9)",
                    "rgba(0, 225, 199, .8)", "rgba(0, 225, 199, .7)",
                    "rgba(0, 225, 199, .6)", "rgba(0, 225, 199, .5)",
                    "rgba(0, 225, 199, .4)", "rgba(0, 225, 199, .3)",
                    "rgba(0, 225, 199, .2)", "rgba(0, 225, 199, .1)"
                ]

                labels = sortedChartData?.map(entry => entry.event_name);
                chartTitleText = "Average execution time (milliseconds)";
                XaxixTitle = "Total impact on performance per trigger";
                break;

            default:
        }

        // Prepare datasets for Chart.js
        const datasets = [{
            data: sortedChartData?.map(entry => entry.avg_ex_time),
            backgroundColor: colorList?.slice(0, sortedChartData?.length), // You can customize the color
            borderColor: "#fff", // You can customize the color
            borderWidth: 0,
        }];

        const options = {
            indexAxis: 'y',
            scales: {
                x: {
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: chartTitleText,
                        padding: {
                            top: 30,
                            bottom: 20
                        }
                    }
                },
                y: {
                    grid: {
                        display: false
                    }
                }

            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: XaxixTitle,
                    fullSize: true,
                    position: "top",
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            barThickness: 20,
        }

        return { datasets, labels, options };
    }


    // Sort the error columns properly based on integers not strings
    const errorPercentageSorting = (rowA, rowB) => {

        const intRowA = parseFloat(rowA?.total_fails_percent);
        const intRowB = parseFloat(rowB?.total_fails_percent);

        if (intRowA > intRowB) {
            return 1;
        }

        if (intRowB > intRowA) {
            return -1;
        }

        return 0;

    }

    const timeAgo = (timestamp) => {
        const currentDate = new Date();
        const pastDate = new Date(timestamp);

        const amsterdamTime = currentDate.toLocaleString('en-US', { timeZone: clientTimeStamp });
        const amsterdamDate = new Date(amsterdamTime);


        const timeDifference = amsterdamDate - pastDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day(s) and ${hours % 24} hour(s) ago`;
        } else if (hours > 0) {
            return `${hours} hour(s) and ${minutes % 60} minute(s) ago`;
        } else if (minutes > 0) {
            return `${minutes} minute(s) ago`;
        } else {
            return `${seconds} second(s) ago`;
        }
    }

    const CaretComponent = ({ row }) => {
        const handleClick = (event) => {
            event.stopPropagation();
            handleSnapshotAreaUpdate(row);
        };

        const isExpanded = snapshotToReview === row && snapshotExpanded;

        return (
            <div onClick={handleClick}>
                {isExpanded ? <CaretUpFill size={14} /> : <CaretDownFill size={14} />}
            </div>
        );
    };

    // Tables
    const tagVolumeTableColumns = [
        {
            name: 'Tag name',
            sortable: true,
            width: '23%', // Adjusted width
            selector: row => row?.tag_name !== "undefined" && row?.tag_name ? row?.tag_name : row?.tag_id,
        },
        {
            name: 'Event name',
            sortable: true,
            width: '15%', // Adjusted width
            selector: row => row?.event_name,
        },
        {
            name: 'Container ID',
            sortable: true,
            width: '11%',
            selector: row => row?.container_id,
        },
        {
            name: 'Events analysed',
            sortable: true,
            width: '14%', // Adjusted width
            selector: row => row?.total_calls,
        },
        {
            name: 'Last time collected',
            sortable: true,
            width: '14%', // Adjusted width
            selector: row => timeAgo(row?.last_timestamp),
        },
        {
            name: '% compared to last hour',
            sortable: true,
            width: '19%', // Adjusted width
            selector: row => row?.perc_last_hour,
            cell: row => (
                <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {row?.perc_last_hour}
                </div>
            ),
        }
    ];

    const errorTableColumns = [
        {
            name: 'Tag name',
            sortable: true,
            width: '30%',
            selector: row => row?.tag_name !== "undefined" && row?.tag_name ? row?.tag_name : row?.tag_id,
            cell: row => (
                <span
                    className={isTagExcluded(row) ? 'excluded-tag' : ''}
                    onClick={() => rowClickFunction(row)} // Add onClick handler here
                >
                    {row?.tag_name !== "undefined" && row?.tag_name ? row?.tag_name : row?.tag_id}
                    {isTagExcluded(row) && (
                        <>
                            <StopOutlined className="excluded-icon" />
                            <span className="excluded-text">excluded</span>
                        </>
                    )}
                </span>
            ),
        },
        {
            name: 'Event Name',
            sortable: true,
            width: '22%',
            selector: row => row?.event_name,
        },
        {
            name: 'Container ID',
            sortable: true,
            width: '13%',
            selector: row => row?.container_id,
        },
        {
            name: 'Events analysed',
            sortable: true,
            width: '13%',
            selector: row => row?.total_calls,
        },
        {
            name: 'Error %',
            sortable: true,
            width: '8%',
            selector: row => row?.total_fails_percent,
            sortFunction: errorPercentageSorting,
        },
        {
            name: 'Error %',
            sortable: true,
            width: '10%',
            selector: row => row?.total_fails_percent,
            sortFunction: errorPercentageSorting,
            cell: (row) =>
                <div className="error-column">
                    <div className="error-bar" style={{ width: `${row?.total_fails_percent}%` }}></div>
                </div>,
        },
    ];

    const cloudRunTableColumns = [
        {
            name: 'Date / Time',
            sortable: true,
            width: '13%',
            selector: row => row?.formatted_timestamp || "-",
        },
        {
            name: 'Project ID',
            sortable: true,
            width: '10%',
            selector: row => row?.project_id
        },
        {
            name: 'Service Name',
            sortable: true,
            width: '18%',
            selector: row => row?.service_name,
        },
        {
            name: 'Summary',
            sortable: true,
            width: '58%',
            selector: row => row?.summary,
            cell: row => (
                <AntdTooltip title={row.summary}>
                    <div style={{
                        whiteSpace: 'nowrap', // Keeps text in a single line
                        overflow: 'hidden',   // Hides overflow
                        textOverflow: 'ellipsis', // Adds ellipsis at the end if text overflows
                        maxWidth: '100%', // Ensures the cell respects table boundaries
                    }}>
                        {row.summary}
                    </div>
                </AntdTooltip>
            ),
        },
        {
            name: '',
            sortable: false,
            compact: true,
            width: '1%',
            format: () => <CaretDownFill size={14} />,
            selector: (row, index) => index,
            cell: row => <CaretComponent row={row} />,
        },
    ];


    const consentMonitoringTableColumns = [
        {
            name: 'Consent Type',
            sortable: true,
            width: '24%',
            selector: row => row?.consent_name,
        },
        {
            name: 'Tags collected',
            sortable: true,
            width: '24%',
            selector: row => row?.total_values,
        },
        {
            name: 'Denied pageview events',
            sortable: true,
            width: '24%',
            selector: row => row?.denied,
        },
        {
            name: 'Approval in %',
            sortable: true,
            width: '19%',
            selector: row => row?.approval_percent + "%",
        },
    ];

    const aggregateErrorsByTagName = (data = []) => {
        // Create an object to store the aggregated data
        const aggregatedData = {};
    
        data.forEach(item => {
            const { tag_id, tag_name, total_fails, total_calls, container_id, event_name } = item;
            const key = `${tag_id}-${container_id}-${event_name}`;
    
            // Check if the tag_name already exists in the aggregatedData object
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    tag_id: tag_id,
                    tag_name: tag_name,
                    container_id: container_id,
                    event_name: event_name,
                    total_fails: 0,
                    total_calls: 0,
                    total_fails_percent: 0,
                };
            }
    
            aggregatedData[key].total_fails += total_fails;
            aggregatedData[key].total_calls += total_calls;
            aggregatedData[key].total_fails_percent = ((aggregatedData[key].total_fails / aggregatedData[key].total_calls) * 100).toFixed(2);
        });
    
        // Convert the aggregatedData object into an array of objects
        return Object.values(aggregatedData).sort((a, b) => b.total_fails_percent - a.total_fails_percent);
    };


    const rowClickFunction = (event) => {
        /*
            This function filters the data for the selected tag from the 
            table entries and updates the charts based on the selected tag 
            on the chart.
        */
    
        const {
            tag_name: tagName,
            tag_id: tagId,
            container_id: containerId,
            event_name: eventName
        } = event;
    
        let filteredData, chartData;
    
        // const filterConditions = { tag_name: tagName, tag_id: tagId, container_id: containerId };
        const filterConditions = { tag_id: tagId, container_id: containerId, event_name: eventName };
    
        const processData = (data, setDataFunc, aggrParam, setLabelFunc, chartType) => {
            // filter data for the selected tag
            filteredData = data?.filter((item) =>
                Object.entries(filterConditions).every(([key, value]) => item[key] === value)
            );
    
            let chartOptionFunc;
    
            switch (chartType) {
                case "client":
                    chartOptionFunc =
                        aggrParam === "total_fails_percent" ? setClientErrorsOptions : setTagVolTotalClientOptions;
                    break;
                default:
                    chartOptionFunc =
                        aggrParam === "total_fails_percent" ? setServerErrorsOptions : setTagVolTotalServerOptions;
            }
    
            // update the chart with the filtered data for the selected tag
            // setLabelFunc(retrieveTimestampLabels(data, "timestamp"));  
            setDataFunc(renderLineChart({
                dataArr: filteredData,
                aggrParam: aggrParam,
                setLabelFunc: setLabelFunc,
                chartOptionType: aggrParam === "total_fails_percent" ? "error-monitoring" : "tag-volume",
                chartOptionFunc: chartOptionFunc
            }));
        };
    
        switch (tabType) {
            case "errors_client":
                chartData = refresh ? initialData.error_query_client : freshData.error_query_client;
                processData(chartData, setClientErrorsData, "total_fails_percent", setClientErrorsLabels, "client");
                break;
    
            case "errors_server":
                chartData = refresh ? initialData.error_query_server : freshData.error_query_server;
                processData(chartData, setServerErrorsData, "total_fails_percent", setServerErrorsLabels, "server");
                break;
    
            case "tag_volume_client":
                chartData = refresh ? initialData.tag_vol_total_calls_client : freshData.tag_vol_total_calls_client;
                processData(chartData, setTagVolTotalClientData, "total_calls", setTagVolTotalClientLabels, "client");
                break;
    
            case "tag_volume_server":
                chartData = refresh ? initialData.tag_vol_total_calls_server : freshData.tag_vol_total_calls_server;
                processData(chartData, setTagVolTotalServerData, "total_calls", setTagVolTotalServerLabels, "server");
                break;
    
            default:
        }
    };


    const updateFiltersData = (data) => {
        /**
            *This function updates the data for the values
            inside the filter dropdowns. 
        */

        const filtersClientSide = data?.context?.filters_client_side || [];
        const filtersServerSide = data?.context?.filters_server_side || [];
        const excludedTagsClient = data.context.tags_monitoring_exclude_client || [];
        const excludedTagsServer = data.context.tags_monitoring_exclude_server || [];

        setFiltersClientSide(filtersClientSide);
        setFiltersServerSide(filtersServerSide);
        setExcludedTagsClient(excludedTagsClient);
        setExcludedTagsServer(excludedTagsServer);

        switch (filtersType) {
            case "client":
                setFiltersData(filtersClientSide);
                setTagsList(filtersClientSide || []);

                break;
            case "server":
                setFiltersData(filtersServerSide);
                setTagsList(filtersServerSide || []);
                break;
            default:
                setFiltersData([])
        }
    }


    const updateChartData = (data, chart, setDataFunc) => {
        /**
         * This function updates either the initialData or
         * freshData variables.
         */
        switch (chart) {
            case "errors":
                setDataFunc((prev) => ({
                    ...prev,
                    ["error_query_client"]: data?.context?.error_query_client,
                    ["error_query_server"]: data?.context?.error_query_server,
                }));
                break;

            case "tag-vol":
                setDataFunc((prev) => ({
                    ...prev,
                    ["tag_vol_query_client"]: data?.context?.tag_vol_query_client,
                    ["tag_vol_query_server"]: data?.context?.tag_vol_query_server,
                    ["tag_vol_total_calls_client"]: data?.context?.tag_vol_total_calls_client,
                    ["tag_vol_total_calls_server"]: data?.context?.tag_vol_total_calls_server,
                }));
                break;

            case "site-performance":
                setDataFunc((prev) => ({
                    ...prev,
                    ["site_performance_per_tag"]: data?.context?.site_performance_per_tag,
                    ["site_performance_per_trigger"]: data?.context?.site_performance_per_trigger,
                }));
                break;

            case "consent-monitoring":
                setDataFunc((prev) => ({
                    ...prev,
                    ["consent_monitoring"]: data?.context?.consent_monitoring,
                }));
                break;

            case "cloud-run":
                setDataFunc((prev) => ({
                    ...prev,
                    ["cloud_run"]: data?.context?.cloud_run,
                }));
                break;

            default:
                break;
        }
    };


    const updateData = (data, chart) => {
        if (companyID === companyId) {
            if (!initialLoad) {
                updateChartData(data, chart, setInitialData);
            }
        }
        else {
            setClientTimeStamp("Europe/Amsterdam");
            setDifferenceOneDayOrOneHour(null);
            updateChartData(data, chart, setInitialData);
        }

        updateChartData(data, chart, setFreshData);
        updateCharts(data?.context, chart);
    }

    //chart loading states
    const [errorsChartsLoading, setErrorsChartsLoading] = useState(true);
    const [tagVolChartsLoading, setTagVolChartsLoading] = useState(true);
    const [sitePerfChartsLoading, setSitePerfChartsLoading] = useState(true);
    const [consentMonitoringLoading, setConsentMonitoringLoading] = useState(true);
    const [cloudRunLoading, setCloudRunLoading] = useState(true);
    const [mainPageLoading, setMainPageLoading] = useState(false);

    const getChartsData = async (reqObj) => {
        const chartsType = ["filters", "errors", "tag-vol", "site-performance", "consent-monitoring", "cloud-run"];
        let filtersCompleted, errorsCompleted, tagVolCompleted, SitePerfComplleted, consentMonitoringCompleted, cloudRunCompleted = false;

        chartsType.forEach(async (chart) => {
            let loadingChart;

            switch (chart) {
                case "errors":
                    loadingChart = setErrorsChartsLoading;
                    errorsCompleted = true;
                    break;
                case "tag-vol":
                    loadingChart = setTagVolChartsLoading;
                    tagVolCompleted = true;
                    break;
                case "site-performance":
                    loadingChart = setSitePerfChartsLoading;
                    SitePerfComplleted = true;
                    break;
                case "consent-monitoring":
                    loadingChart = setConsentMonitoringLoading;
                    consentMonitoringCompleted = true;
                    break;
                case "cloud-run":
                    loadingChart = setCloudRunLoading;
                    cloudRunCompleted = true;
                    break;
                default:
                    loadingChart = setErrorsChartsLoading;
            }

            loadingChart(true);

            const res = await DashboardService.getTagMonitorDashboard(reqObj, chart);
            const response = await res.json();

            const dataset_exists = response?.context?.dataset_exists;
            const BQClientTimestamp = response?.context?.BQ_client_timestamp;
            setClientTimeStamp(BQClientTimestamp);

            if (dataset_exists) {
                if (chart === "filters") {
                    updateFiltersData(response);

                    if (initialLoad || companyID !== companyId) {
                        setInitialFilters(response);
                        setInitialSnapshotsList(() => response?.context?.templates);
                    } else {
                        if (!initialLoad) {
                            setInitialFilters(response);
                        }
                    }
                    filtersCompleted = true;

                }
                else {
                    updateData(response, chart);
                    setInitialLoad(true);
                    setCompanyID(companyId);
                    setDatasetExists(true);
                    loadingChart(false);
                }
            }
            else {
                setDatasetExists(false);
            }

            setMainPageLoading(false);

            // enable the filters once all charts have been updated
            if (filtersCompleted && errorsCompleted &&
                tagVolCompleted && SitePerfComplleted &&
                consentMonitoringCompleted && cloudRunCompleted) {
                setFiltersDisabled(false);
            }
        });
    }

    //specify the type (client/server) of the selected filters
    const [filtersDataType, setFiltersDataType] = useState(filtersType);

    const getFilterObject = () => {
        let setDifferenceOneDayOrOneHourParam,
            chosenContainerIdParam,
            chosenEventNameParam,
            chosenDomainParam,
            searchUrlValueParam,
            tagNamesList;

        // If the company is changed reset all the filters and empty tag list
        if (companyID !== companyId) {
            setDifferenceOneDayOrOneHour(null);
            tagNamesList = [];
            setClientTimeStamp("Europe/Amsterdam")
            setChosenContainerId([]);
            setChosenEventName([]);
            setChosenDomain([]);
            setSearchUrlValue("");
            setSelectedTags([]);

            setCurrentChosenContainerId([]);
            setCurrentChosenDomain([]);
            setChosenEventName([]);
            setCurrentSelectedTags([]);
            setCurrentSearchUrlValue("");

            setStartDate(dayjs().add(-7, 'd'));
            setEndDate(dayjs());
            setDifferenceOneDayOrOneHourParam = null;
            chosenContainerIdParam = "";
            chosenEventNameParam = "";
            chosenDomainParam = "";
            searchUrlValueParam = "";
        }
        else {
            tagNamesList = selectedTags;
            setDifferenceOneDayOrOneHourParam = differenceOneDayOrOneHour;
            chosenContainerIdParam = chosenContainerId;
            chosenEventNameParam = chosenEventName;
            chosenDomainParam = chosenDomain;
            searchUrlValueParam = searchUrlValue;
            setCurrentChosenContainerId(chosenContainerId);
            setCurrentChosenDomain(chosenDomain);
            setCurrentChosenEventName(chosenEventName);
            setCurrentSearchUrlValue(searchUrlValue);
            setCurrentSelectedTags(selectedTags);
        }

        const reqObj = {
            companyID: companyId,
            startDate: startDate.format(timestampFormat),
            endDate: endDate.format(timestampFormat),
            chosenContainerId: chosenContainerIdParam,
            chosenEventName: chosenEventNameParam,
            chosenDomain: chosenDomainParam,
            searchUrlValue: searchUrlValueParam,
            tagNamesList: [...new Set(tagNamesList)],
            differenceOneDayOrOneHour: setDifferenceOneDayOrOneHourParam,
            filtersType,
            tagsMonitoringExcludeClient: excludedTagsClient,
            tagsMonitoringExcludeServer: excludedTagsServer

        }

        setFiltersDataType(filtersType);
        return reqObj;
    }


    // Refreshing the page data on company change
    useEffect(() => {
        (async () => {
            if (companyID !== companyId && !datasetExists) {
                setMainPageLoading(true);
                setDefaultDateRange();
                setSnapshotsList([]);
            }

            setFiltersDisabled(true);
            setRefresh(false);

            // Send a request to check if the session is active
            const session = await DashboardService.checkSession();
            const sessionData = await session?.json();
            const sessionExists = sessionData?.session_exists;

            if (sessionExists) {
                const filterObject = getFilterObject();
                const respData = await getChartsData(filterObject);
            }
            else {
                localStorageService.clearLocalStorage();
                navigate('/login');
            }
        })();
    }, [companyId, updateLoadFlag]);


    useEffect(() => {
        // toggle the tag name dropdown values based on the filter type
        const data = filtersType === "server" ? filtersServerSide : filtersClientSide || [];
        const tagsDropdownData = generateDropdownContent(data);
        setTagsDropdownList(tagsDropdownData);
    }, [tabType, filtersType, updateLoadFlag]);


    // updates the values for filers
    const [tagOptions, setTagOptions] = useState({});
    const [cidOptions, setCidOptions] = useState({});
    const [hostnameOptions, setHostnameOptions] = useState({});
    const [eventsOptions, seteEventsOptions] = useState({});


    // useEffects for updating the values inside the filter dropdowns
    useEffect(() => {
        const tags = [... new Set(tagsDropdownList.map((elem) => elem?.tag_name))];
        setTagOptions(tags?.map(elem => ({
            label: elem,
            value: elem,
        })) || []);
    }, [tagsDropdownList]);

    useEffect(() => {
        setCidOptions(cidList?.map(elem => ({
            label: elem,
            value: elem,
        })) || []);
    }, [cidList]);


    useEffect(() => {
        setHostnameOptions(hostsList?.map(elem => ({
            label: elem,
            value: elem,
        })) || []);
    }, [hostsList]);

    useEffect(() => {
        seteEventsOptions(eventNameList?.map(elem => ({
            label: elem,
            value: elem,
        })) || []);
    }, [eventNameList]);


    const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;

        for (let i = 0; i <= arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        return true;
    };

    const handleFilterChange = (e, updateFunction) => updateFunction(e);


    const handleClear = (updateFunction) => {
        updateFunction([]);
        handleLoseFocus();
    }


    const handleLoseFocus = () => {
        const selectedTagsChanged = !arraysEqual(currentSelectedTags, selectedTags);
        const cidListChanged = !arraysEqual(currentChosenContainerId, chosenContainerId);
        const domainListChanged = !arraysEqual(currentChosenDomain, chosenDomain);
        const eventNameListChanged = !arraysEqual(currentChosenEventName, chosenEventName);

        if (selectedTagsChanged || cidListChanged ||
            domainListChanged || eventNameListChanged ||
            currentSearchUrlValue !== searchUrlValue) {

            if (selectedTags.length > 0 || chosenContainerId.length > 0 ||
                chosenDomain.length > 0 || chosenEventName.length > 0 ||
                searchUrlValue !== ""
            ) {
                setUpdateLoadFlag(!updateLoadFlag);
            }
            else {
                refreshFilters();
            }
        }
    };

    const handleEnterPress = (e) => (currentSearchUrlValue !== searchUrlValue ? setUpdateLoadFlag(!updateLoadFlag) : undefined);

    const tagVolTabs = [
        {
            key: '1',
            label: (
                <div className="dropdown-item client-side"
                    onClick={() => handleDashTypeChangeValue("client", "tag_volume_client", "tag_volume")}>
                    Client-side
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className="dropdown-item server-side"
                    onClick={() => handleDashTypeChangeValue("server", "tag_volume_server", "tag_volume")}>
                    Server-side
                </div>
            ),
            disabled: !tagMonitorServer,

        }
    ];

    const errorTabs = [
        {
            key: '1',
            label: (
                <div className="dropdown-item client-side"
                    onClick={() => handleDashTypeChangeValue("client", "errors_client", "error_monitoring")}>
                    Client-side
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className="dropdown-item server-side"
                    onClick={() => handleDashTypeChangeValue("server", "errors_server", "error_monitoring")}>
                    Server-side
                </div>
            ),
            disabled: !tagMonitorServer,
        },
    ];


    // Chart group by parameter data
    const chartGroupOptions = ['tag name', 'event name'];

    const handleChartGroupOptionChange = (value, setValueFunction) => {
        if (chartGroupOptions.indexOf(value) > -1) setValueFunction(value);
    }

    const [clientErrorsGroupOption, setClientErrorsGroupOption] = useState(chartGroupOptions[0]);
    const [serverErrorsGroupOption, setServerErrorsGroupOption] = useState(chartGroupOptions[0]);
    const [clientTagVolumeGroupOption, setClientTagVolumeGroupOption] = useState(chartGroupOptions[0]);
    const [serverTagVolumeGroupOption, setServerTagVolumeGroupOption] = useState(chartGroupOptions[0]);

    // Chart max entries change
    const chartEntriesNumOptions = [10, 20, 30, 40, 50];
    const handleChartEntriesNumChange = (value, setValueFunction) => {
        if (chartEntriesNumOptions.indexOf(value) > -1) setValueFunction(value);
    }
    const chartEntriesOptions = chartEntriesNumOptions.map(elem => ({
        label: elem,
        value: elem,
    }));

    const [clientErrorsEntriesNum, setClientErrorsEntriesNum] = useState(chartEntriesNumOptions[0]);
    const [serverErrorsEntriesNum, setServerErrorsEntriesNum] = useState(chartEntriesNumOptions[0]);
    const [clientTagVolEntriesNum, setClientTagVolEntriesNum] = useState(chartEntriesNumOptions[0]);
    const [serverTagVolEntriesNum, setServerTagVolEntriesNum] = useState(chartEntriesNumOptions[0]);

    useEffect(() => {
        updateCharts(freshData, "all");
    }, [clientErrorsEntriesNum, serverErrorsEntriesNum, clientTagVolEntriesNum, serverTagVolEntriesNum]);

    return (
        <Content>
            {datasetExists ? (
                <Space direction="vertical" className="container-content" size="large">
                    <Flex justify="space-between" align="flex-start">
                        <PageHeader categoryName="dashboard" pageName="Tag Monitor" />
                    </Flex>

                    <Tabs
                        items={[
                            {
                                name: "error_monitoring",
                                title: "error monitoring",
                                onClickFunction: () => handleDashTypeChangeValue("client", "errors_client", "error_monitoring"),
                                menuItems: errorTabs,
                            },
                            {
                                name: "tag_volume",
                                title: "tag volume",
                                onClickFunction: () => handleDashTypeChangeValue("client", "tag_volume_client", "tag_volume"),
                                menuItems: tagVolTabs,
                            },
                            {
                                name: "consent_monitoring",
                                title: "consent monitoring",
                                onClickFunction: () => handleDashTypeChangeValue("client", "consent_monitoring", "consent_monitoring"),
                            },
                            {
                                name: "site_performance",
                                title: "site performance",
                                onClickFunction: () => handleDashTypeChangeValue("client", "site_performance", "site_performance"),
                            },
                            {
                                name: "cloud_run",
                                title: "cloud run",
                                onClickFunction: () => handleDashTypeChangeValue("client", "cloud_run", "cloud_run"),
                            },
                        ]}
                        activeTab={activeTab}
                    />

                    <Col span={24}>
                        <h5 className="header-letter-spacing">{chartTitle}</h5>
                        <p className="dashboard-desc-area">{chartDesc} Do you want to update your configuration? Please check your configuration settings <Link to="/tag_monitor_config">here.</Link></p>
                    </Col>

                    <Row className="filters-row">
                        <Flex style={{ width: '100%' }} gap="small" justify={'flex-start'} align={'flex-start'}>
                            <Space style={{ minWidth: '14.5rem' }} direction="vertical">
                                <RangePicker
                                    presets={[
                                        ...rangePresets,
                                    ]}
                                    showTime={false}
                                    format={timestampFormatInput}
                                    onChange={onRangeChange}
                                    variant="filled"
                                    placeholder="time & date"
                                    defaultValue={[startDate, endDate]}
                                    value={[startDate, endDate]}
                                />
                            </Space>
                            {activeTab !== 'cloud_run' && (
                                <>
                                    <Space style={{ width: '100%' }} direction="vertical">
                                        <Select
                                            popupMatchSelectWidth={false}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="tag name"
                                            onChange={(e) => handleFilterChange(e, setSelectedTags)}
                                            options={tagOptions}
                                            showSearch={true}
                                            maxTagCount={0}
                                            value={selectedTags}
                                            disabled={filtersDisabled}
                                            onBlur={handleLoseFocus}
                                            onClear={() => handleClear(setSelectedTags)}
                                            variant="filled"
                                            suffixicon={<ChevronDown />}
                                        />
                                    </Space>

                                    <Space style={{ width: '100%' }} direction="vertical">
                                        <Select
                                            popupMatchSelectWidth={false}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="event name"
                                            onChange={(e) => handleFilterChange(e, setChosenEventName)}
                                            options={eventsOptions}
                                            showSearch={true}
                                            maxTagCount={0}
                                            value={chosenEventName}
                                            disabled={filtersDisabled}
                                            onBlur={handleLoseFocus}
                                            onClear={() => handleClear(setChosenEventName)}
                                            variant="filled"
                                            suffixicon={<ChevronDown />}
                                        />
                                    </Space>

                                    <Space style={{ width: '75%' }} direction="vertical">
                                        <Select
                                            popupMatchSelectWidth={false}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="GTM container"
                                            onChange={(e) => handleFilterChange(e, setChosenContainerId)}
                                            options={cidOptions}
                                            showSearch={true}
                                            maxTagCount={0}
                                            value={chosenContainerId}
                                            disabled={filtersDisabled}
                                            onBlur={handleLoseFocus}
                                            onClear={() => handleClear(setChosenContainerId)}
                                            variant="filled"
                                            suffixicon={<ChevronDown />}
                                        />
                                    </Space>

                                    <Space style={{ width: '75%' }} direction="vertical">
                                        <Select
                                            popupMatchSelectWidth={false}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="domain"
                                            onChange={(e) => handleFilterChange(e, setChosenDomain)}
                                            options={hostnameOptions}
                                            showSearch={true}
                                            maxTagCount={0}
                                            value={chosenDomain}
                                            disabled={filtersDisabled}
                                            onBlur={handleLoseFocus}
                                            onClear={() => handleClear(setChosenDomain)}
                                            variant="filled"
                                            suffixicon={<ChevronDown />}
                                        />
                                    </Space>

                                    <Space style={{ minWidth: '14rem' }} direction="vertical">
                                        <Input
                                            placeholder="https://www.code-cube.io"
                                            variant="filled"
                                            type="text"
                                            value={searchUrlValue || ""}
                                            onChange={handleSearchUrlValue}
                                            disabled={filtersDisabled}
                                            onPressEnter={handleEnterPress}
                                            allowClear={true}
                                            suffixicon={<ChevronDown />}
                                        />
                                    </Space>
                                </>
                            )}
                            <Col align="center" className="filters-refresh">
                                <ArrowCounterclockwise
                                    size={22}
                                    onClick={() => refreshFilters()}
                                />
                            </Col>
                        </Flex>
                    </Row>
                    <Col>
                        <div className={`charts-area ${tabsVisibility["errors_client"] ? "visible" : ""}`}>
                            <Space direction="vertical" size="small" style={{ position: "relative", width: "100%" }}>
                                <Flex align="center" justify="end" gap={10}>
                                    <div>Num of elements</div>
                                    <Select
                                        onChange={(e) =>
                                            handleChartEntriesNumChange(e, setClientErrorsEntriesNum)
                                        }
                                        options={chartEntriesOptions}
                                        showSearch={false}
                                        value={clientErrorsEntriesNum}
                                        disabled={errorsChartsLoading}
                                        variant="filled"
                                        suffixicon={<ChevronDown />}
                                    />
                                    {/* <div>Group by</div> */}
                                    {/* <Segmented
                                        options={chartGroupOptions}
                                        onChange={(value) =>
                                            handleChartGroupOptionChange(value, setClientErrorsGroupOption)
                                        }
                                        value={clientErrorsGroupOption}
                                    /> */}
                                </Flex>
                                <Chart type='line'
                                    data={{
                                        labels: clientErrorsLabels,
                                        datasets: clientErrorsData,
                                    }}
                                    width={1539} height={520}
                                    options={clientErrorsOptions}
                                    plugins={[customMarginPlugin]}
                                    className="charts-area-canvas"
                                    key={clientErrorsEntriesNum}
                                />
                            </Space>

                            <div>
                                <LoadingScreen isActive={errorsChartsLoading} />
                                <Table
                                    columns={errorTableColumns}
                                    tableData={clientErrorsTable}
                                    rowClickFunction={(e) => { rowClickFunction(e) }}
                                    filterParam="tag_name"
                                />
                            </div>
                        </div>
                        <Space className={`charts-area ${tabsVisibility["errors_server"] ? "visible" : ""}`}>
                            <Space direction="vertical" size="large" style={{ position: "relative", width: "100%" }}>
                                <Flex align="center" justify="end" gap={10}>
                                    <div>Num of elements</div>
                                    <Select
                                        onChange={(e) =>
                                            handleChartEntriesNumChange(e, setServerErrorsEntriesNum)
                                        }
                                        options={chartEntriesOptions}
                                        showSearch={false}
                                        value={serverErrorsEntriesNum}
                                        disabled={errorsChartsLoading}
                                        variant="filled"
                                        suffixicon={<ChevronDown />}
                                    />
                                    {/* <div>Group by</div> */}
                                    {/* <Segmented
                                        options={chartGroupOptions}
                                        onChange={(value) =>
                                            handleChartGroupOptionChange(value, setServerErrorsGroupOption)
                                        }
                                        value={serverErrorsGroupOption}
                                    /> */}
                                </Flex>
                                <Chart type='line'
                                    data={{
                                        labels: serverErrorsLabels,
                                        datasets: serverErrorsData,
                                    }}
                                    width={1539} height={520}
                                    options={serverErrorsOptions}
                                    plugins={[customMarginPlugin]}
                                    className="charts-area-canvas"
                                    key={serverErrorsEntriesNum}
                                />
                            </Space>

                            <div style={{ position: "relative" }}>
                                <LoadingScreen isActive={errorsChartsLoading} />
                                <Table
                                    columns={errorTableColumns}
                                    tableData={serverErrorsTable}
                                    rowClickFunction={(e) => { rowClickFunction(e) }}
                                    filterParam="tag_name"
                                />
                            </div>
                        </Space>
                        <Space className={`charts-area ${tabsVisibility["tag_volume_client"] ? "visible" : ""}`}>
                            <Space direction="vertical" size="large" style={{ position: "relative", width: "100%" }}>
                                <Flex align="center" justify="end" gap={10}>
                                    <div>Num of elements</div>
                                    <Select
                                        onChange={(e) =>
                                            handleChartEntriesNumChange(e, setClientTagVolEntriesNum)
                                        }
                                        options={chartEntriesOptions}
                                        showSearch={false}
                                        value={clientTagVolEntriesNum}
                                        disabled={tagVolChartsLoading}
                                        variant="filled"
                                        suffixicon={<ChevronDown />}
                                    />
                                    {/* <div>Group by</div> */}
                                    {/* <Segmented
                                        options={chartGroupOptions}
                                        onChange={(value) =>
                                            handleChartGroupOptionChange(value, setClientTagVolumeGroupOption)
                                        }
                                        value={clientTagVolumeGroupOption}
                                    /> */}
                                </Flex>
                                <Chart type='line'
                                    data={{
                                        labels: tagVolTotalClientLabels,
                                        datasets: tagVolTotalClientData,
                                    }}
                                    width={1539} height={520}
                                    options={tagVolTotalClientOptions}
                                    plugins={[customMarginPlugin]}
                                    className="charts-area-canvas"
                                    key={clientTagVolEntriesNum}
                                />
                            </Space>

                            <div>
                                <LoadingScreen isActive={tagVolChartsLoading} />
                                <Table
                                    columns={tagVolumeTableColumns}
                                    tableData={tagVolClientData}
                                    rowClickFunction={(e) => { rowClickFunction(e) }}
                                    filterParam="tag_name"
                                />
                            </div>
                        </Space>

                        <Space className={`charts-area ${tabsVisibility["tag_volume_server"] ? "visible" : ""}`}>
                            <Space direction="vertical" size="large" style={{ position: "relative", width: "100%" }}>
                                <Flex align="center" justify="end" gap={10}>
                                    <div>Num of elements</div>
                                    <Select
                                        onChange={(e) =>
                                            handleChartEntriesNumChange(e, setServerTagVolEntriesNum)
                                        }
                                        options={chartEntriesOptions}
                                        showSearch={false}
                                        value={serverTagVolEntriesNum}
                                        disabled={tagVolChartsLoading}
                                        variant="filled"
                                        suffixicon={<ChevronDown />}
                                    />
                                    {/* <div>Group by</div> */}
                                    {/* <Segmented
                                        options={chartGroupOptions}
                                        onChange={(value) =>
                                            handleChartGroupOptionChange(value, setServerTagVolumeGroupOption)
                                        }
                                        value={serverTagVolumeGroupOption}
                                    /> */}
                                </Flex>
                                <Chart type='line'
                                    data={{
                                        labels: tagVolTotalServerLabels,
                                        datasets: tagVolTotalServerData,
                                    }}
                                    width={1539} height={520}
                                    options={tagVolTotalServerOptions}
                                    plugins={[customMarginPlugin]}
                                    className="charts-area-canvas"
                                    key={serverTagVolEntriesNum}
                                />
                            </Space>

                            <div>
                                <LoadingScreen isActive={tagVolChartsLoading} />
                                <Table
                                    columns={tagVolumeTableColumns}
                                    tableData={tagVolServerData}
                                    rowClickFunction={(e) => { rowClickFunction(e) }}
                                    filterParam="tag_name"
                                />
                            </div>
                        </Space>

                        {/* consent monitoring chart */}
                        <Space className={`charts-area ${tabsVisibility["consent_monitoring"] ? "visible" : ""}`}>
                            <Flex className="consent-monitoring-charts" justify="space-between" wrap>
                                <div className="consent-monitoring-chart">
                                    <BarChart
                                        data={consentMonitoringData}
                                        transformChartData={consentMonitoring}
                                    />
                                </div>

                                <div className="consent-monitoring-chart">
                                    <LineChart
                                        data={consentMonitoringData}
                                        width={600} height={320}
                                    />
                                </div>
                            </Flex>
                            <div>
                                <LoadingScreen isActive={consentMonitoringLoading} />
                                <Table
                                    columns={consentMonitoringTableColumns}
                                    tableData={aggregatedConsentMonitoringData}
                                    rowClickFunction={(e) => { rowClickFunction(e) }}
                                    filterParam="tag_name"
                                />
                            </div>
                        </Space>

                        {/* end */}

                        {/* cloud run table */}
                        <Space className={`charts-area ${tabsVisibility["cloud_run"] ? "visible" : ""}`}>
                            <LoadingScreen isActive={cloudRunLoading} />
                            <div>
                                <Table
                                    columns={cloudRunTableColumns}
                                    tableData={cloudRunData}
                                    rowClickFunction={handleSnapshotAreaUpdate}
                                    includeIndexColumn={false} // Disable index column for this table
                                    showSubHeader={false} // Disable subheader for this table
                                />
                                <div className={`mb-3 ${snapshotExpanded ? "slide-down" : "slide-up"}`}>
                                    <div className="row" style={{ display: "flex", margin: 0, padding: 0 }}>
                                        <div className="elems-list col-6 equal-height-div">
                                            <h5><strong>Cloud Run in detail</strong></h5>
                                            <div className="error-details-desc">
                                                <div><strong>Date / Time:</strong> {snapshotToReview?.formatted_timestamp}</div>
                                                <div><strong>Project ID:</strong> {snapshotToReview?.project_id}</div>
                                                <div><strong>Service Name:</strong> {snapshotToReview?.service_name}</div>
                                                <div><strong>Summary:</strong> {snapshotToReview?.summary}</div>
                                                <div><strong>Location:</strong> {snapshotToReview?.location}</div>
                                                <div><strong>Observed Value:</strong> {snapshotToReview?.observed_value}</div>
                                                <div><strong>Threshold Value:</strong> {snapshotToReview?.threshold_value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Space>
                        {/* end */}
                        {/* site performance chart */}
                        <Space className={`charts-area ${tabsVisibility["site_performance"] ? "visible" : ""}`}>
                            <div>
                                <LoadingScreen isActive={sitePerfChartsLoading} />
                                <Chart type='bar'
                                    data={{
                                        labels: sitePerfPerTagLabels,
                                        datasets: sitePerfPerTagData,
                                    }}
                                    height={90}
                                    options={sitePerfPerTagOptions}
                                />
                            </div>

                            <div>
                                <LoadingScreen isActive={sitePerfChartsLoading} />
                                <Chart type='bar'
                                    data={{
                                        labels: sitePerfPerTriggerLabels,
                                        datasets: sitePerfPerTriggerData,
                                    }}
                                    height={90}
                                    options={sitePerfPerTriggerOptions}
                                />
                            </div>
                        </Space>
                        {/* end */}
                    </Col>
                </Space>
            ) : (
                <Content>
                    <LoadingScreen isActive={mainPageLoading} />
                    <Space direction="vertical" className="container-content" size="large">
                        <Flex justify="space-between" align="flex-start">
                            <PageHeader categoryName="dashboard" pageName="Tag Monitor" />
                        </Flex>

                        <Row>
                            <h5 className="header-letter-spacing">Dataset not found!</h5>
                        </Row>
                    </Space>
                </Content>
            )}
        </Content>
    );
};

