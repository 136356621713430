import FetchService from './fetch.service';
import localStorageService from './localStorage.service';

const fetchService = new FetchService();


class ConfigService {
    async getTagMonitorConfigData(companyId) {
        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = fetchService(`/configurations/get_tag_monitor_configuration/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }


    async getCloudRunMonitoringData(companyId) {
        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = fetchService(`/configurations/get_cloud_run_monitoring/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }

    async getDataLayerGuardConfigData(companyId) {
        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = fetchService(`/configurations/get_datalayer_guard_configuration/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }
    
    async getSitespeedMonitorConfigData(companyId) {
        if (!companyId) companyId = localStorageService.getCompanyId()

        const response = fetchService(`/configurations/get_sitespeed_monitor_configuration/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }

    async saveDataLayerGuardConfigData(props) {
        const { configObj, companyId } = props;
        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = fetchService(`/configurations/save_datalayer_guard_configuration/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({
                company_id: companyId,
                config_data: configObj,
            }),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }

    async saveSitespeedMonitorConfigData(props) {
        const { configObj, companyId } = props;
        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = fetchService(`/configurations/save_sitespeed_monitor_configuration/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({
                company_id: companyId,
                config_data: configObj,
            }),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }

    async saveTagMonitorConfigData(props) {
        const { configObj, companyId } = props;
        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = fetchService(`/configurations/save_tag_monitor_configuration/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({
                company_id: companyId,
                config_data: configObj,
            }),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
        });

        return response;
    }


    async saveGoogleCloudData(props) {
        const { googleCloudDataForm, companyId } = props;
        if (!companyId) companyId = localStorageService.getCompanyId();

        const formData = new FormData();
        formData.append('company_id', companyId);
        formData.append('config_data', JSON.stringify(googleCloudDataForm));

        const fileData = googleCloudDataForm["upload"][0];
        if (fileData && fileData.originFileObj) {
            const file = fileData.originFileObj;
            formData.append('file', file);
        }


        const response = fetchService(`/configurations/setup_cloud_run/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body : formData,
            headers: {},
        });

        return response;
    }
}

export default new ConfigService();
