import React, { useState, useEffect } from 'react';
import loadingIcon from '../../assets/loading.svg';

import './style.scss';

const FullLoadingScreen = (props) => {
    const { isActive } = props;

    const [loadingScreenClass, setLoadingScreenClass] = useState("visible");

    useEffect(() => {
        setLoadingScreenClass(isActive ? "visible" : "");
    }, [isActive]);

    return (
        <div className={`loading-screen-with-text ${loadingScreenClass} mx-auto`} >
            <img className="loading-icon-with-text" src={loadingIcon} height="60" />
                <p style={{ textAlign: "center", opacity: 1 }}>
                    This might take a while, please leave this screen 
                    <br></br>
                    open and come back in a few minutes 
                </p>
        </div>
    );
};

export default FullLoadingScreen;