import { React, useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc, InfoCircleFill, Pen, DashCircle, ArrowClockwise, ChevronDown, ArrowLeft, Trash3, PlusLg, Copy } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import JSON5 from 'json5';
import { jsonrepair } from 'jsonrepair';
import { simplifyDLGEventJson } from './index';
import { AuthContext } from '../../services/auth.service';
import { Select, ConfigProvider, Input, Flex, Modal, Space } from 'antd';

import {
    ProdSettingsContext,
    DataLayerEventsContext,
    MonitoringContext,
} from './index';

import { defaultMenuProps } from './defaultEventTemplates';

import { transformDataObject, NestedObjectTable } from '../../components/elements/JsonObjTable';
import { TimezoneDropdown } from '../../components/elements/TimezoneDropdown';
import PageHeader from '../../components/elements/PageHeader';

import { DropdownMenu } from '../../components/elements/DropdownMenu';
import { ActionButton } from '../../components/elements/Button';

const { TextArea } = Input;

const generateUniqueId = () => uuidv4();


const addUniqueIdRows = (data) => {
    const newList = [];

    data?.forEach((obj) => {
        const newObj = { ...obj };
        if (!(newObj.hasOwnProperty('uniqueId'))) {
            newObj.uniqueId = generateUniqueId();
        }
        newList.push(newObj);
    });

    return newList;
};


export const ProductSettinsModal = () => {
    const {
        companyId,
        productDescLink,
        timezones,
        timezoneName,
        curTimezone,
        dlgCheckFreq,
        curCheckFreq,
        setCurTimezone,
        setCurCheckFreq,
        setConfigParams,
        companyDomains,
        setCompanyDomains
    } = useContext(ProdSettingsContext);

    const [choosenTimezone, setChoosenTimezone] = useState(curTimezone);
    const [choosenCheckFreq, setChoosenCheckFreq] = useState(curCheckFreq);
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [showGuideDivDomain, setShowGuideDivDomain] = useState(false);

    const [curCompanyDomains, setCurCompanyDomains] = useState(() => {
        return companyDomains.length > 0 ? companyDomains : [""];
    });

    const { adminUser } = useContext(AuthContext);


    useEffect(() => {
        setCurCompanyDomains(companyDomains.length > 0 ? companyDomains : [""])
    }, [companyDomains])

    useEffect(() => {
        setChoosenTimezone(curTimezone);
        setChoosenCheckFreq(curCheckFreq);
    }, [curTimezone, curCheckFreq]);

    const handleTimezoneChange = (newTimezone) => {
        setCurTimezone(newTimezone);
        setChoosenTimezone(newTimezone);
    }
    const handleCheckFreqChange = (newCheckFreq) => {
        setCurCheckFreq(newCheckFreq);
        setChoosenCheckFreq(newCheckFreq);
    }

    const handleElemAdd = () => {
        setCurCompanyDomains([...curCompanyDomains, {}]);
        setCompanyDomains([...curCompanyDomains, {}])
    }

    const handleElemDelete = (idx) => {
        const newDomainsList = curCompanyDomains?.filter((elem, index) => index !== idx);
        setCurCompanyDomains(newDomainsList);
        setCompanyDomains(newDomainsList)
    }



    const handleInputValueChange = (idx, field, event) => {
        const newDomainsList = [...curCompanyDomains];
        const elem = { ...newDomainsList[idx] };
        elem[field] = event.target.value;
        newDomainsList[idx] = elem;
        setCurCompanyDomains(newDomainsList);
        setCompanyDomains(newDomainsList)
    }



    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.timezone = choosenTimezone;
        newObject.check_frequency = choosenCheckFreq;
        newObject.company_domains = curCompanyDomains;
        setConfigParamsToSave(newObject);
    }, [choosenTimezone, choosenCheckFreq, curCompanyDomains]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return (
        <div key={companyId} className="modal-elems-rows" id='products-settings-main'>
            <div>
                <h5 className='small-heading'>configuration</h5>
                <h2 className='header-letter-spacing'>General Settings</h2>
            </div>
            <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={productDescLink}>
                        <div className="file-icon" style={{ color: '$cc-blue' }}>
                            <FiletypeDoc size={30} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set-up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                {showGuideDivDomain &&
                    <div className='hoverable-div-parent' style={{ left: '6.8em' }}>
                        <p className='hover-content'>
                            Only the domain(s) added to this list will be
                            activated for alerting and will be shown in the
                            dashboards.
                        </p>
                    </div>
                }
                <h3 style={{ marginBottom: 0 }} className="modal-category">
                    Domains
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDivDomain(true)}
                        onMouseLeave={() => setShowGuideDivDomain(false)}
                    />
                </h3>
                <p style={{ marginBottom: 0 }}>
                    Add the domain(s) for which you would like to activate the DataLayer Guard in the list below.
                </p>
            </div>
            <div className="elems-list">
                <div className="list-col-2-add">
                    {curCompanyDomains?.map((elem, idx) =>
                        <div key={idx} className="inputs-row-3-col-single d-flex flex-row">
                            <Input
                                type="text"
                                placeholder="code-cube.io"
                                value={elem?.domain || ""}
                                onChange={(event) => handleInputValueChange(idx, "domain", event)}
                                style={{ width: "40%" }}
                            />
                            {adminUser && <Input
                                type="text"
                                placeholder="Sitemap URL"
                                value={elem?.sitemap_url || ""}
                                onChange={(event) => handleInputValueChange(idx, "sitemap_url", event)}
                                style={{ width: "40%" }}
                            />}
                            <div>
                                <DashCircle className='color-blue' size={18} onClick={() => handleElemDelete(idx)}></DashCircle>
                            </div>
                        </div>
                    )
                    }
                </div>
                <div>
                    <div className="btn btn-sm cc-btn-outline-blue" onClick={handleElemAdd}>+ add domain</div>
                </div>
            </div>
            <div>
                <h3 style={{ marginBottom: 0 }} className="modal-category">How often to run?</h3>
                <p>How often should the DataLayer Guard run?</p>
                <div>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle btn-light text-start"
                            type="button" id="dlg_check_freq" data-bs-toggle="dropdown" aria-expanded="false">
                            {choosenCheckFreq ? dlgCheckFreq[choosenCheckFreq] : "Choose frequency"}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dlg_check_freq">
                            {Object.keys(dlgCheckFreq).map(key =>
                                <li className="pointer-on-hover" key={key} onClick={() => handleCheckFreqChange(key)}>
                                    <div className="dropdown-item">{dlgCheckFreq[key]}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <TimezoneDropdown
                choosenTimezone={choosenTimezone}
                timezones={timezones}
                timezoneName={timezoneName}
                handleTimezoneChange={handleTimezoneChange}
            />

        </div>
    );
}

export const DataLayerEventsModal = () => {
    const {
        companyId,
        scrapedEventsDescLink,
        dlEvents,
        setDlEvents,
        setConfigParams,
        companyDomains,
        newTemplateVersionAvailable,
        pushDefaultEcommerceObject,
        cleanJsonObject,
        handleEventAdd,
        handleEventRemove,
        error,
    } = useContext(DataLayerEventsContext);

    const [curEvents, setCurEvents] = useState(addUniqueIdRows(dlEvents));
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [selectedCompanyDoamain, setSelectedCompanyDoamain] = useState("All domains");
    const [filteredCurEvents, setFilteredCurEvents] = useState(curEvents);

    const { adminUser } = useContext(AuthContext);

    const [editedEventIdx, setEditedEventIdx] = useState(null);
    const [editedEventName, setEditedEventName] = useState("");
    const [element, setElement] = useState("");
    const [urlMatchType, setUrlMatchType] = useState("");
    const [elementMatchType, setElementMatchType] = useState("");
    const [elementRegexValue, setElementRegexValue] = useState("");
    const [editedEventType, setEditedEventType] = useState("");
    const [editedEventStatus, setEditedEventStatus] = useState("");
    const [editedEventUrls, setEditedEventUrls] = useState("");
    const [editedEventTemplate, setEditedEventTemplate] = useState("");
    const [editedEventTemplateNewVersion, setEditedEventTemplateNewVersion] = useState("");

    const [modalContentCode, setModalContentCode] = useState("main");

    const [modalState, setModalState] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(<></>);
    const [editedTextfieldValue, setEditedTextfieldValue] = useState("");

    const [jsonBtnText, setJsonBtnText] = useState("Add JSON");
    const [jsonBtnIcon, setJsonBtnIcon] = useState(<PlusLg />);


    // Add ecommerce event button functionality
    const [isTemplateObjectEmpty, setIsTemplateObjectEmpty] = useState(false);
    useEffect(() => {
        switch (editedEventTemplateNewVersion) {
            case "":
                setIsTemplateObjectEmpty(true);
                break;
            default:
                setIsTemplateObjectEmpty(editedEventTemplateNewVersion?.length > 1);
        }
    }, [editedEventTemplate, editedEventTemplateNewVersion]);

    const menuProps = {
        items: defaultMenuProps,
        onClick: (e) => handlePushDefaultEcommerceObject(e?.key, editedEventIdx),
    };

    const handlePushDefaultEcommerceObject = (key, idx) => {
        const curEventsItem = curEvents.find(elem => elem.uniqueId === idx);
        const newTemplates = pushDefaultEcommerceObject(key, idx, curEventsItem);

        setEditedEventTemplate(newTemplates);
        setEditedEventTemplateNewVersion(transformDataObject(newTemplates));
    }

    const handleCleanJsonTemplate = () => {
        const curEventsItem = curEvents.find(elem => elem.uniqueId === editedEventIdx);
        cleanJsonObject(editedEventIdx, curEventsItem);

        setEditedEventTemplate("");
        setEditedEventTemplateNewVersion("");
    }


    useEffect(() => {
        // check if the new template version exist if not update the events to add it
        if (!newTemplateVersionAvailable) {
            dlEvents?.forEach((event) => {
                const templatesCopy = event.templates;
                let parsedTemplate;

                switch (typeof templatesCopy) {
                    case "string":
                        const repairedJson = templatesCopy && templatesCopy !== '' ? jsonrepair(templatesCopy) : '';
                        parsedTemplate = repairedJson !== '' ? JSON5.parse(repairedJson) : {};
                        break;
                    default:
                        parsedTemplate = templatesCopy;
                }

                const newVersionTemplateObject = transformDataObject(parsedTemplate);
                event.main_templates = newVersionTemplateObject;
            })
        }

        setCurEvents(addUniqueIdRows(dlEvents) || []);
        setFilteredCurEvents(addUniqueIdRows(dlEvents) || []);
        setConfigParamsToSave({ templates: { "events": dlEvents } });
    }, [dlEvents]);


    const updateParamsToSave = (params) => {
        const { newEventsList } = params;
        const newConfigParams = { ...configParamsToSave };
        const curEventsListStr = { "events": newEventsList };
        newConfigParams.templates = curEventsListStr;

        setConfigParamsToSave(newConfigParams);
    }


    const handleEditEventClick = (elem, uniqueId) => {
        setEditedEventIdx(uniqueId);
        setEditedEventName(elem?.event_name || "");
        setElement(elem?.element || "");
        setUrlMatchType(elem?.url_match_type || "");
        setElementMatchType(elem?.element_match_type || "");
        setElementRegexValue(elem?.element_regex_value || "");
        setEditedEventType(elem?.event_type || "");
        setEditedEventStatus(elem?.event_status || "");
        setEditedEventUrls(elem?.urls || "");
        setEditedEventTemplate(elem?.main_templates || elem?.templates || "");
        setEditedEventTemplateNewVersion(elem?.main_templates || "")
        setModalContentCode("edit");
    }

    // update the useStatet parameters when switching between companies
    useEffect(() => {
        setEditedEventIdx();
        setEditedEventName("");
        setElement("");
        setUrlMatchType("");
        setElementMatchType("");
        setElementRegexValue("");
        setEditedEventType("");
        setEditedEventStatus("");
        setEditedEventUrls("");
        setEditedEventTemplate("");
        setEditedEventTemplateNewVersion("");
        setModalContentCode("main");
    }, [companyId]);


    useEffect(() => {
        const newEventsList = curEvents || [];
        const updatingElem = newEventsList.find(elem => elem.uniqueId === editedEventIdx);

        if (updatingElem) {
            updatingElem.event_name = editedEventName;
            updatingElem.element = element;
            updatingElem.url_match_type = urlMatchType;
            updatingElem.element_match_type = elementMatchType;
            updatingElem.element_regex_value = elementRegexValue;
            updatingElem.event_type = editedEventType;
            updatingElem.event_status = editedEventStatus;
            updatingElem.urls = editedEventUrls;
            updatingElem.templates = editedEventTemplate;
            updatingElem.main_templates = editedEventTemplateNewVersion;

            setCurEvents(newEventsList);
            setDlEvents(newEventsList);
            updateParamsToSave({ newEventsList });
        }

        const template = editedEventTemplate !== "" &&
            typeof (editedEventTemplate) === "string" ? JSON5.parse(jsonrepair(editedEventTemplate)) : editedEventTemplate
        const initialTemplate = template?.length === 1 && template[0].key === "" && template[0].value === "";
        const text = initialTemplate ? "Add JSON" : "Edit JSON";
        const icon = initialTemplate ? <PlusLg /> : <Pen />;
        setJsonBtnText(text);
        setJsonBtnIcon(icon)

        setModalContent(defaultModalContent(editedEventIdx, editedEventTemplate));

    }, [modalContentCode, editedEventName,
        editedEventType, editedEventStatus,
        editedEventUrls, editedEventTemplate,
        urlMatchType, element, elementMatchType,
        elementRegexValue
    ]);

    const handleHeaderClick = () => {
        // removes empty events from the list of events
        const newEventsList = curEvents?.filter((elem) =>
            elem.element !== "" ||
            elem.event_name !== "" ||
            elem.urls !== "" ||
            elem.element_regex_value !== "" ||
            elem.main_templates.length > 1
        );

        setCurEvents(() => newEventsList);
        setDlEvents(newEventsList);
        updateParamsToSave({ newEventsList });
        setModalContentCode("main");
    };

    const typesOptions = {
        "": "type",
        "page_load": "page load",
        "interaction": "click",
    };

    const elementOptions = {
        "html_class": "HTML class",
        "html_element": "HTML element",
        "html_id": "HTML ID",
    }

    const urlOptions = {
        "matches_regex": "matches regex",
        "contains ": "contains ",
        "exact_matches": "exact matches ",
    }

    const statusesOptions = [
        {
            label: "Status",
            key: "",
        },
        {
            label: "active",
            key: "active",
        },
        {
            label: "inactive",
            key: "inactive",
        },
    ];
    const statusesProps = {
        items: statusesOptions,
        onClick: (e) => setEditedEventStatus(e?.key),
    };


    const eventAdd = () => {
        const {
            newEvent,
            newEventsList,
            newEventUniqueId,
        } = handleEventAdd();

        setEditedEventTemplate(null);
        setEditedEventTemplateNewVersion(null);
        setCurEvents(newEventsList);
        setModalContentCode("edit");
        updateParamsToSave({ newEventsList });
        handleEditEventClick(newEvent, newEventUniqueId);
    }

    const eventRemove = (idx) => {
        const newEventsList = handleEventRemove(idx);
        
        setCurEvents(() => newEventsList);
        setModalContentCode("main");
        updateParamsToSave({ newEventsList });
    }


    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    // Center modal functionality
    const defaultModalContent = (idx, templates = "") => {
        let defaultValueTemplate = "";
        let parsedTemplate = {};

        const placeholderObj = {
            "event": "event_name",
            "ecommerce": {
                "currency": "string",
                "value": "number",
                "items": [
                    {
                        "item_id": "string",
                        "item_name": "string",
                    }
                ]
            }
        };
        const placeholderText = JSON.stringify(placeholderObj, undefined, 2);

        switch (typeof templates) {
            case "object":
                parsedTemplate = templates;
                break;
            default:
                const repairedJson = templates && templates !== '' ? jsonrepair(templates) : '';
                parsedTemplate = repairedJson !== '' ? JSON5.parse(repairedJson) : {};
        }

        const templateIsArray = Array.isArray(parsedTemplate);

        switch (templateIsArray) {
            case true:
                const tempLn = parsedTemplate.length;

                if (tempLn === 0) {
                    defaultValueTemplate = "";
                }
                else if (tempLn === 1 && parsedTemplate[0].key === "" &&
                    parsedTemplate[0].value === "") {
                    defaultValueTemplate = "";
                }
                else if (Object.keys(parsedTemplate).length > 0) {
                    const simplifiedDLGEventJson = simplifyDLGEventJson(parsedTemplate);
                    defaultValueTemplate = JSON.stringify(simplifiedDLGEventJson, null, 2);
                }
                break;
            default:
                defaultValueTemplate = JSON.stringify(parsedTemplate, null, 2);
        }

        setModalTitle("Template DataLayer object");

        return (
            <>
                <p>Add your dataLayer template in the box below.</p>
                <TextArea
                    key={idx}
                    rows={12}
                    placeholder={placeholderText}
                    defaultValue={defaultValueTemplate}
                    onChange={(e) => { setEditedTextfieldValue(e.target.value) }}
                    style={{
                        fontFamily: "monospace",
                        fontSize: "14px",
                        backgroundColor: "#f7f7f7",
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "4px",
                        whiteSpace: "pre-wrap", // Preserve whitespace for formatting
                        
                    }}
                />
            </>
        );
    }

    const handleEditTemplateEvent = (idx) => {
        const newEventsList = curEvents || [];
        const updatingElem = newEventsList.find(elem => elem.uniqueId === idx);

        setEditedEventTemplate(updatingElem?.templates || "");
        setEditedEventTemplateNewVersion(updatingElem?.main_templates || "");
        setModalContent(defaultModalContent(idx, updatingElem?.templates));
        setModalState(true);
    }


    const updateEditedTemplate = (template = {}) => {
        setEditedEventTemplate(JSON.stringify(template));
        setEditedEventTemplateNewVersion(template);
    }

    // Modal window functions
    const handleOk = () => {
        try {
            setEditedEventTemplate(jsonrepair(editedTextfieldValue));
            setModalState(false);
        }
        catch (err) {
            error("Incorrect value! Please check the JSON object and try again.");
        }
    };
    const handleCancel = () => setModalState(false);


    // Create options for hostname dropdown
    const companyDomainsOptions = companyDomains.map((domain) => {
        return {
            value: domain.domain,
            label: domain.domain,
        }
    });

    companyDomainsOptions.unshift({
        value: "All domains",
        label: "All domains",
    });


    // update the selectedCompanyDoamain when switching between the companies
    useEffect(() => setSelectedCompanyDoamain("All domains"), [companyDomains, companyId, dlEvents])


    useEffect(() => {
        switch (selectedCompanyDoamain) {
            case "All domains":
                setFilteredCurEvents(curEvents)
                break;
            default:
                const newCurEvents = curEvents.filter(item => item.urls.includes(selectedCompanyDoamain));
                setFilteredCurEvents(newCurEvents)
        }
    }, [selectedCompanyDoamain, curEvents]);


    return (
        <>
            <Modal
                key={editedEventTemplate}
                title={modalTitle}
                open={modalState}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <Flex gap={5}>
                        <CancelBtn />
                        <OkBtn />
                    </Flex>
                )}>
                {modalContent}
            </Modal>
            <div key={`${companyId}_event_list`} className={`modal-elems-rows ${modalContentCode === "main" ? "visible" : "hidden"}`}>
                <div>
                    <h5 className='small-heading'>configuration</h5>
                    <h2 className='header-letter-spacing'>Monitoring via scraped events</h2>
                </div>
                <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                    <div>
                        <Link className="panel-button-container grey-bg" target="_blank" to={scrapedEventsDescLink}>
                            <div className="file-icon" style={{ color: '$cc-blue' }}>
                                <FiletypeDoc size={30} />
                            </div>
                            <div className="panel-button-text">
                                <div className="panel-button-header">Implementation guide</div>
                                <div className="panel-button-desc">Guide how to set-up events</div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="dropdown domains-dropdown">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        selectorBg: '#e7e2fd',
                                        clearBg: '#e7e2fd',
                                        optionActiveBg: '#e7e2fd',
                                        optionSelectedBg: '#e7e2fd',
                                    },
                                },
                            }}
                        >
                            <Select
                                labelInValue
                                className="transparent-border"
                                defaultValue={{
                                    value: 'All domains',
                                    label: 'All domains',
                                }}
                                style={{
                                    width: 200,
                                    borderColor: "transparent"
                                }}

                                onChange={(e) => setSelectedCompanyDoamain(e.value)}
                                options={companyDomainsOptions}
                            />
                        </ConfigProvider>

                    </div>
                    <p className='fst-italic pt-2'>
                        Make sure domain is added to domain list in Product settings
                    </p>
                </div>
                <div>
                    <h3 className="modal-category">Overview of your dataLayer events</h3>
                    <p>Update, add or delete the dataLayer events that need to be monitored.</p>
                </div>
                {filteredCurEvents.length > 0 && (
                    <div className="elems-list">
                        <div>

                            <div className="temp-row row-labels">
                                <div>Type event</div>
                                <div>Event name</div>
                                {adminUser && (
                                    <div>
                                        Status
                                    </div>
                                )}
                                <div></div>
                            </div>
                            <div
                                className="page-separator"
                                style={adminUser ? { width: "70%" } : { width: "50%" }}>
                            </div>

                            <div className="list-col-2-add">
                                {filteredCurEvents?.map((elem, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className={adminUser ? "temp-row-admin-user" : "temp-row"}>
                                                <div>{elem?.event_type || ''}</div>
                                                <div>{elem?.event_name || ''}</div>
                                                {adminUser && (
                                                    <div>{elem?.event_status || ''}</div>
                                                )}
                                                <div>
                                                    <Pen title="Edit event template" onClick={() => handleEditEventClick(elem, elem?.uniqueId)} size={18}></Pen>
                                                </div>
                                            </div>
                                            <div
                                                className="page-separator"
                                                style={adminUser ? { width: "70%" } : { width: "50%" }}>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                        </div>

                    </div>
                )}
                <div>
                    <div
                        className="btn btn-sm cc-btn-outline-blue"
                        onClick={eventAdd}>
                        + add event
                    </div>
                </div>

            </div>
            <div key={`${companyId}_event_edit`} className={`modal-elems-rows ${modalContentCode === "edit" ? "visible" : "hidden"}`}>

                <div>
                    <h5 className='header-submodal small-heading'>configuration</h5>
                    <h2 className="header-submodal header-letter-spacing">
                        DataLayer events via scraper
                    </h2>
                </div>

                <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                    <div>
                        <Link className="panel-button-container grey-bg" target="_blank" to={scrapedEventsDescLink}>
                            <div className="file-icon" style={{ color: '$cc-blue' }}>
                                <FiletypeDoc size={30} />
                            </div>
                            <div className="panel-button-text">
                                <div className="panel-button-header">Implementation guide</div>
                                <div className="panel-button-desc">Guide how to set-up events</div>
                            </div>
                        </Link>
                    </div>
                </div>


                <div>
                    <p className="modal-category fw-bold mb-0" onClick={handleHeaderClick}>
                        <ArrowLeft size={24} className='pe-2' />
                        Overview dataLayer events
                    </p>
                </div>

                <div>
                    <div>
                        <h3 className='fw-bold mb-2 modal-category'>Event name</h3>
                        <Input className='bordered-input' value={editedEventName} placeholder="page_view"
                            onChange={(e) => setEditedEventName(e.target.value)}
                            style={{ width: "25%" }}
                        />

                    </div>
                </div>
                <div>
                    <h3 className="modal-category fw-bold">
                        Add event details
                    </h3>
                    <p className='mb-0'>
                        To enable monitoring for this event, details on the dataLayer event should be
                        shared in the table below.
                        <br></br>
                        For a quick start you can can copy / paste your JSON object via the button below.
                    </p>
                </div>

                <Flex gap="small">
                    <ActionButton
                        text={jsonBtnText}
                        type=""
                        icon={jsonBtnIcon}
                        onClickFunction={() => handleEditTemplateEvent(editedEventIdx)}
                        width={120}
                    />
                    <DropdownMenu
                        text="Add ecommerce event"
                        type="primary"
                        disabled={isTemplateObjectEmpty}
                        menuProps={menuProps}
                        loading={false}
                        icon={<ChevronDown />}
                    />
                    <ActionButton
                        text="Clean JSON"
                        type=""
                        icon={<ArrowClockwise />}
                        onClickFunction={() => handleCleanJsonTemplate()}
                    />
                </Flex>


                {editedEventIdx &&
                    <div>

                        <NestedObjectTable
                            data={typeof editedEventTemplate === "string" ? editedEventTemplate : JSON.stringify(editedEventTemplate)}
                            updateTemplateFunction={updateEditedTemplate}
                            setEditedEventTemplateNewVersion={setEditedEventTemplateNewVersion}
                        />

                    </div>
                }


                <div>
                    <h3 className="fw-bold mt-4 modal-category">
                        Define trigger for dataLayer event
                    </h3>
                    <p className='mb-3'>
                        Define how and where the dataLayer event can be found via the fields below.
                    </p>
                </div>

                <table id='triggers-table'>
                    <tbody>
                        <tr>
                            <td>
                                <p className='fw-bold mb-0'>How does this event fire?</p>
                            </td>
                            <td>
                                <select className="form-select" value={editedEventType} aria-label="Type select"
                                    onChange={(e) => setEditedEventType(e.target.value)} >
                                    {Object.keys(typesOptions).map((elem, idx) =>
                                        <option key={elem} value={elem}>
                                            {typesOptions[elem]}
                                        </option>)
                                    }
                                </select>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <p className='fw-bold mb-0'>Fires on URL</p>
                            </td>
                            <td>
                                <select className="form-select" value={urlMatchType} aria-label="Type select"
                                    onChange={(e) => setUrlMatchType(e.target.value)}
                                >
                                    {Object.keys(urlOptions).map((elem, idx) =>
                                        <option key={elem} value={elem}>
                                            {urlOptions[elem]}
                                        </option>)
                                    }

                                </select>
                            </td>
                            <td>
                                <Input value={editedEventUrls} placeholder="https://code-cube.io/"
                                    onChange={(e) => setEditedEventUrls(e.target.value)} />
                            </td>
                            <td></td>

                        </tr>

                        <tr className={editedEventType !== "interaction" ? `content-muted` : ''}>
                            <td>
                                <p className='fw-bold mb-0'>On which click element?</p>
                            </td>
                            <td>
                                <select className="form-select" value={element} aria-label="Type select"
                                    onChange={(e) => setElement(e.target.value)}
                                    disabled={editedEventType !== "interaction"}
                                >
                                    {Object.keys(elementOptions).map((elem, idx) =>
                                        <option key={elem} value={elem}>
                                            {elementOptions[elem]}
                                        </option>)
                                    }

                                </select>
                            </td>

                            <td>
                                <Input value={elementRegexValue} placeholder="regex value"
                                    onChange={(e) => setElementRegexValue(e.target.value)}
                                    disabled={editedEventType !== "interaction"}
                                />
                            </td>
                            <td>
                                <select className="form-select" value={elementMatchType} aria-label="Type select"
                                    onChange={(e) => setElementMatchType(e.target.value)}
                                    disabled={editedEventType !== "interaction"}
                                >

                                    {Object.keys(urlOptions).map((elem, idx) =>
                                        <option key={elem} value={elem}>
                                            {urlOptions[elem]}
                                        </option>)
                                    }

                                </select>
                            </td>
                        </tr>

                    </tbody>
                </table>

                {adminUser && (
                    <Space direction="vertical" size="medium" block="true">
                        <h3 className="fw-bold mt-4 modal-category pb-1">
                            Status of the event
                        </h3>

                        <DropdownMenu
                            text={editedEventStatus || "Select status"}
                            type=""
                            disabled={false}
                            menuProps={statusesProps}
                            loading={false}
                            icon={<ChevronDown />}
                            width={141}
                        />
                    </Space>
                )}

                <Space>
                    <ActionButton
                        text="Remove event"
                        type=""
                        icon={<Trash3 size={14} />}
                        onClickFunction={() => eventRemove(editedEventIdx)}
                        danger={true}
                    />
                </Space>
            </div>
        </>
    );
}


export const MonitoringModal = () => {
    const {
        databaseName,
        companyId,
        tagMangerEventsDescLink,
        transactionEvents,
        setTransactionEvents,
        setConfigParams,
        companyDomains,
        newTransTempVersionAvailable,
        pushDefaultEcommerceObject,
        cleanJsonObject,
        handleEventAdd,
        handleEventRemove,
        error,
    } = useContext(MonitoringContext);

    const [curEvents, setCurEvents] = useState(addUniqueIdRows(transactionEvents));
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [selectedCompanyDoamain, setSelectedCompanyDoamain] = useState("All domains");
    const [filteredCurEvents, setFilteredCurEvents] = useState(curEvents);

    const { adminUser } = useContext(AuthContext);

    const [editedEventIdx, setEditedEventIdx] = useState(null);
    const [editedEventName, setEditedEventName] = useState("");
    const [element, setElement] = useState("");
    const [urlMatchType, setUrlMatchType] = useState("");
    const [elementMatchType, setElementMatchType] = useState("");
    const [elementRegexValue, setElementRegexValue] = useState("");
    const [editedEventType, setEditedEventType] = useState("");
    const [editedEventStatus, setEditedEventStatus] = useState("");
    const [editedEventUrls, setEditedEventUrls] = useState("");
    const [editedEventTemplate, setEditedEventTemplate] = useState("");
    const [editedEventTemplateNewVersion, setEditedEventTemplateNewVersion] = useState("");

    const [modalContentCode, setModalContentCode] = useState("main");

    const [modalState, setModalState] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(<></>);
    const [editedTextfieldValue, setEditedTextfieldValue] = useState("");

    const [jsonBtnText, setJsonBtnText] = useState("Add JSON");
    const [jsonBtnIcon, setJsonBtnIcon] = useState(<PlusLg />);

    const inputRefDataset = useRef(null);

    const copyToClipboard = (text) => {
        const inputValue = text.current.value;
        navigator.clipboard.writeText(inputValue);
    }

    // Add ecommerce event button functionality
    const [isTemplateObjectEmpty, setIsTemplateObjectEmpty] = useState(false);
    useEffect(() => {
        switch (editedEventTemplateNewVersion) {
            case "":
                setIsTemplateObjectEmpty(true);
                break;
            default:
                setIsTemplateObjectEmpty(editedEventTemplateNewVersion?.length > 1);
        }
    }, [editedEventTemplate, editedEventTemplateNewVersion]);

    const menuProps = {
        items: defaultMenuProps,
        onClick: (e) => handlePushDefaultEcommerceObject(e?.key, editedEventIdx),
    };

    const handlePushDefaultEcommerceObject = (key, idx) => {
        const curEventsItem = curEvents.find(elem => elem.uniqueId === idx);
        const newTemplates = pushDefaultEcommerceObject(key, idx, curEventsItem);

        setEditedEventTemplate(newTemplates);
        setEditedEventTemplateNewVersion(transformDataObject(newTemplates));
    }

    const handleCleanJsonTemplate = () => {
        const curEventsItem = curEvents.find(elem => elem.uniqueId === editedEventIdx);
        cleanJsonObject(editedEventIdx, curEventsItem);

        setEditedEventTemplate("");
        setEditedEventTemplateNewVersion("");
    }


    useEffect(() => {
        // check if the new template version exist if not update the events to add it
        if (!newTransTempVersionAvailable) {
            transactionEvents?.forEach((event) => {
                const templatesCopy = event.templates;
                let parsedTemplate;

                switch (typeof templatesCopy) {
                    case "string":
                        const repairedJson = templatesCopy && templatesCopy !== '' ? jsonrepair(templatesCopy) : '';
                        parsedTemplate = repairedJson !== '' ? JSON5.parse(repairedJson) : {};
                        break;
                    default:
                        parsedTemplate = templatesCopy;
                }

                const newVersionTemplateObject = transformDataObject(parsedTemplate);
                event.main_templates = newVersionTemplateObject;
            })
        }

        setCurEvents(addUniqueIdRows(transactionEvents) || []);
        setFilteredCurEvents(addUniqueIdRows(transactionEvents) || []);
        setConfigParamsToSave({ transaction_templates: { "events": transactionEvents } });
    }, [transactionEvents]);


    const updateParamsToSave = (params) => {
        const { newEventsList } = params;
        const newConfigParams = { ...configParamsToSave };
        const curEventsListStr = { "events": newEventsList };
        newConfigParams.transaction_templates = curEventsListStr;

        setConfigParamsToSave(newConfigParams);
    }


    const handleEditEventClick = (elem, uniqueId) => {
        setEditedEventIdx(uniqueId);
        setEditedEventName(elem?.event_name || "");
        setElement(elem?.element || "");
        setUrlMatchType(elem?.url_match_type || "");
        setElementMatchType(elem?.element_match_type || "");
        setElementRegexValue(elem?.element_regex_value || "");
        setEditedEventType(elem?.event_type || "");
        setEditedEventStatus(elem?.event_status || "");
        setEditedEventUrls(elem?.urls || "");
        setEditedEventTemplate(elem?.main_templates || elem?.templates || "");
        setEditedEventTemplateNewVersion(elem?.main_templates || "");
        setModalContentCode("edit");
    }

    // update the useStatet parameters when switching between companies
    useEffect(() => {
        setEditedEventIdx();
        setEditedEventName("");
        setElement("");
        setUrlMatchType("");
        setElementMatchType("");
        setElementRegexValue("");
        setEditedEventType("");
        setEditedEventStatus("");
        setEditedEventUrls("");
        setEditedEventTemplate("");
        setEditedEventTemplateNewVersion("");
        setModalContentCode("main");
    }, [companyId]);

    useEffect(() => {
        const newEventsList = curEvents || [];
        const updatingElem = newEventsList.find(elem => elem.uniqueId === editedEventIdx);

        if (updatingElem) {
            updatingElem.event_name = editedEventName;
            updatingElem.element = element;
            updatingElem.url_match_type = urlMatchType;
            updatingElem.element_match_type = elementMatchType;
            updatingElem.element_regex_value = elementRegexValue;
            updatingElem.event_type = editedEventType;
            updatingElem.event_status = editedEventStatus;
            updatingElem.urls = editedEventUrls;
            updatingElem.templates = editedEventTemplate;
            updatingElem.main_templates = editedEventTemplateNewVersion;

            setCurEvents(newEventsList);
            setTransactionEvents(newEventsList);
            updateParamsToSave({ newEventsList });
        }

        const template = editedEventTemplate !== "" &&
            typeof (editedEventTemplate) === "string" ? JSON5.parse(jsonrepair(editedEventTemplate)) : editedEventTemplate
        const initialTemplate = template?.length === 1 && template[0].key === "" && template[0].value === "";
        const text = initialTemplate ? "Add JSON" : "Edit JSON";
        const icon = initialTemplate ? <PlusLg /> : <Pen />;
        setJsonBtnText(text);
        setJsonBtnIcon(icon)

        setModalContent(defaultModalContent(editedEventIdx, editedEventTemplate));

    }, [modalContentCode, editedEventName,
        editedEventType, editedEventStatus,
        editedEventUrls, editedEventTemplate,
        urlMatchType, element, elementMatchType,
        elementRegexValue
    ]);

    const handleHeaderClick = () => {
        // removes empty events from the list of events
        const newEventsList = curEvents?.filter((elem) =>
            elem.element !== "" ||
            elem.event_name !== "" ||
            elem.urls !== "" ||
            elem.element_regex_value !== "" ||
            elem.main_templates?.length > 1
        );

        setCurEvents(() => newEventsList);
        setTransactionEvents(newEventsList);
        updateParamsToSave({ newEventsList });
        setModalContentCode("main");
    };

    const urlOptions = {
        "matches_regex": "matches regex",
        "contains ": "contains ",
        "exact_matches": "exact matches ",
    }

    const statusesOptions = [
        {
            label: "Status",
            key: "",
        },
        {
            label: "active",
            key: "active",
        },
        {
            label: "inactive",
            key: "inactive",
        },
    ];
    const statusesProps = {
        items: statusesOptions,
        onClick: (e) => setEditedEventStatus(e?.key),
    };

    const eventAdd = () => {
        const {
            newEvent,
            newEventsList,
            newEventUniqueId,
        } = handleEventAdd();

        setEditedEventTemplate(null);
        setEditedEventTemplateNewVersion(null);
        setCurEvents(newEventsList);
        setModalContentCode("edit");
        updateParamsToSave({ newEventsList });
        handleEditEventClick(newEvent, newEventUniqueId);
    }

    const eventRemove = (idx) => {
        const newEventsList = handleEventRemove(idx);
        
        setCurEvents(() => newEventsList);
        setModalContentCode("main");
        updateParamsToSave({ newEventsList });
    }

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    // Center modal functionality
    const defaultModalContent = (idx, templates = "") => {
        let defaultValueTemplate = "";
        let parsedTemplate = {};

        const placeholderObj = {
            "event": "event_name",
            "ecommerce": {
                "currency": "string",
                "value": "number",
                "items": [
                    {
                        "item_id": "string",
                        "item_name": "string",
                    }
                ]
            }
        };
        const placeholderText = JSON.stringify(placeholderObj, undefined, 2);

        switch (typeof templates) {
            case "object":
                parsedTemplate = templates;
                break;
            default:
                const repairedJson = templates && templates !== '' ? jsonrepair(templates) : '';
                parsedTemplate = repairedJson !== '' ? JSON5.parse(repairedJson) : {};
        }

        const templateIsArray = Array.isArray(parsedTemplate);

        switch (templateIsArray) {
            case true:
                const tempLn = parsedTemplate.length;

                if (tempLn === 0) {
                    defaultValueTemplate = "";
                }
                else if (tempLn === 1 && parsedTemplate[0].key === "" &&
                    parsedTemplate[0].value === "") {
                    defaultValueTemplate = "";
                }
                else if (Object.keys(parsedTemplate).length > 0) {
                    const simplifiedDLGEventJson = simplifyDLGEventJson(parsedTemplate);
                    defaultValueTemplate = JSON.stringify(simplifiedDLGEventJson, null, 2);
                }
                break;
            default:
                defaultValueTemplate = JSON.stringify(parsedTemplate, null, 2);
        }

        setModalTitle("Template DataLayer object");

        return (
            <>
                <p>Add your dataLayer template in the box below.</p>
                <TextArea
                    key={idx}
                    rows={12}
                    placeholder={placeholderText}
                    defaultValue={defaultValueTemplate}
                    onChange={(e) => { setEditedTextfieldValue(e.target.value) }}
                    style={{
                        fontFamily: "monospace",
                        fontSize: "14px",
                        backgroundColor: "#f7f7f7",
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "4px",
                        whiteSpace: "pre-wrap", // Preserve whitespace for formatting
                    }}
                />
            </>
        );
    }

    const handleEditTemplateEvent = (idx) => {
        const newEventsList = curEvents || [];
        const updatingElem = newEventsList.find(elem => elem.uniqueId === idx);

        setEditedEventTemplate(updatingElem?.templates || "");
        setEditedEventTemplateNewVersion(updatingElem?.main_templates || "");
        setModalContent(defaultModalContent(idx, updatingElem?.templates));
        setModalState(true);
    }


    const updateEditedTemplate = (template = {}) => {
        setEditedEventTemplate(JSON.stringify(template));
        setEditedEventTemplateNewVersion(template);
    }

    // Modal window functions
    const handleOk = () => {
        try {
            setEditedEventTemplate(jsonrepair(editedTextfieldValue));
            setModalState(false);
        }
        catch (err) {
            error("Incorrect value! Please check the JSON object and try again.");
        }
    };
    const handleCancel = () => setModalState(false);


    // Create options for hostname dropdown
    const companyDomainsOptions = companyDomains.map((domain) => {
        return {
            value: domain.domain,
            label: domain.domain,
        }
    });

    companyDomainsOptions.unshift({
        value: "All domains",
        label: "All domains",
    });


    // update the selectedCompanyDoamain when switching between the companies
    useEffect(() => setSelectedCompanyDoamain("All domains"), [companyDomains, companyId, transactionEvents])

    useEffect(() => setModalContent(defaultModalContent(editedEventIdx, editedEventTemplate)), [editedEventTemplate])


    useEffect(() => {
        switch (selectedCompanyDoamain) {
            case "All domains":
                setFilteredCurEvents(curEvents);
                break;
            default:
                const newCurEvents = curEvents.filter(item => item.urls.includes(selectedCompanyDoamain));
                setFilteredCurEvents(newCurEvents);
        }
    }, [selectedCompanyDoamain, curEvents]);


    return (
        <>
            <Modal
                key={editedEventTemplate}
                title={modalTitle}
                open={modalState}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <Flex gap={5}>
                        <CancelBtn />
                        <OkBtn />
                    </Flex>
                )}>
                {modalContent}
            </Modal>
            <div key={`${companyId}_event_list`} className={`modal-elems-rows ${modalContentCode === "main" ? "visible" : "hidden"}`}>
                <PageHeader categoryName="configuration" pageName="Monitoring via Tag Manager" />
                <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                    <div>
                        <Link className="panel-button-container grey-bg" target="_blank" to={tagMangerEventsDescLink}>
                            <div className="file-icon" style={{ color: '$cc-blue' }}>
                                <FiletypeDoc size={30} />
                            </div>
                            <div className="panel-button-text">
                                <div className="panel-button-header">Implementation guide</div>
                                <div className="panel-button-desc">Guide how to set-up events</div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="dropdown domains-dropdown">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        selectorBg: '#e7e2fd',
                                        clearBg: '#e7e2fd',
                                        optionActiveBg: '#e7e2fd',
                                        optionSelectedBg: '#e7e2fd',
                                    },
                                },
                            }}
                        >
                            <Select
                                labelInValue
                                className="transparent-border"
                                defaultValue={{
                                    value: 'All domains',
                                    label: 'All domains',
                                }}
                                style={{
                                    width: 200,
                                    borderColor: "transparent"
                                }}

                                onChange={(e) => setSelectedCompanyDoamain(e.value)}
                                options={companyDomainsOptions}
                            />
                        </ConfigProvider>

                    </div>
                    <p className='fst-italic pt-2'>
                        Make sure domain is added to domain list in Product settings
                    </p>
                </div>

                <div>
                    <h3 className="modal-category">Database name for your configuration</h3>
                    <p>
                        When implementing the new monitoring tag, the field “Dataset name” needs to be added manually.
                        Copy the name in the field below and add this value to your tag template.
                    </p>

                    <div className='d-flex flex-row' style={{ gap: '2em' }}>
                        <div>
                            <p className="modal-category fw-bold">Dataset name</p>
                            <div>
                                <input
                                    className='disabled-input'
                                    type="text"
                                    placeholder="Database name"
                                    value={databaseName || ""}
                                    disabled
                                    ref={inputRefDataset}
                                />
                                <Copy
                                    className='copy-icon'
                                    size={21}
                                    onClick={() => copyToClipboard(inputRefDataset)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h3 className="modal-category">Overview of your dataLayer events</h3>
                    <p>Update, add or delete the dataLayer events that need to be monitored.</p>
                </div>
                {filteredCurEvents.length > 0 && (
                    <div className="elems-list">
                        <div>

                            <div className="temp-row row-labels">
                                <div>Event name</div>
                                {adminUser && (
                                    <div>
                                        Status
                                    </div>
                                )}
                                <div>URL</div>
                                <div></div>
                            </div>
                            <div
                                className="page-separator"
                                style={adminUser ? { width: "70%" } : { width: "50%" }}>
                            </div>

                            <div className="list-col-2-add">
                                {filteredCurEvents?.map((elem, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className={adminUser ? "temp-row-admin-user" : "temp-row"}>
                                                <div>{elem?.event_name || ''}</div>
                                                {adminUser && (
                                                    <div>{elem?.event_status || ''}</div>
                                                )}
                                                <div>{elem?.urls || ''}</div>
                                                <div>
                                                    <Pen title="Edit event template" onClick={() => handleEditEventClick(elem, elem?.uniqueId)} size={18}></Pen>
                                                </div>
                                            </div>
                                            <div
                                                className="page-separator"
                                                style={adminUser ? { width: "70%" } : { width: "50%" }}>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                        </div>

                    </div>
                )}
                <div>
                    <div
                        className="btn btn-sm cc-btn-outline-blue"
                        onClick={eventAdd}>
                        + add event
                    </div>
                </div>

            </div>
            <div key={`${companyId}_event_edit`} className={`modal-elems-rows ${modalContentCode === "edit" ? "visible" : "hidden"}`}>

                <PageHeader categoryName="configuration" pageName="Monitoring via Tag Manager" />

                <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                    <div>
                        <Link className="panel-button-container grey-bg" target="_blank" to={tagMangerEventsDescLink}>
                            <div className="file-icon" style={{ color: '$cc-blue' }}>
                                <FiletypeDoc size={30} />
                            </div>
                            <div className="panel-button-text">
                                <div className="panel-button-header">Implementation guide</div>
                                <div className="panel-button-desc">Guide how to set-up events</div>
                            </div>
                        </Link>
                    </div>
                </div>


                <div>
                    <p className="modal-category fw-bold mb-0" onClick={handleHeaderClick}>
                        <ArrowLeft size={24} className='pe-2' />
                        Overview dataLayer events
                    </p>
                </div>

                <div>
                    <div>
                        <h3 className='fw-bold mb-2 modal-category'>Event name</h3>
                        <Input className='bordered-input' value={editedEventName} placeholder="page_view"
                            onChange={(e) => setEditedEventName(e.target.value)}
                            style={{ width: "25%" }}
                        />

                    </div>
                </div>
                <div>
                    <h3 className="modal-category fw-bold">
                        Add event details
                    </h3>
                    <p className='mb-0'>
                        To enable monitoring for this event, details on the dataLayer event should be
                        shared in the table below.
                        <br></br>
                        For a quick start you can can copy / paste your JSON object via the button below.
                    </p>
                </div>

                <Flex gap="small">
                    <ActionButton
                        text={jsonBtnText}
                        type=""
                        icon={jsonBtnIcon}
                        onClickFunction={() => handleEditTemplateEvent(editedEventIdx)}
                        width={120}
                    />
                    <DropdownMenu
                        text="Add ecommerce event"
                        type="primary"
                        disabled={isTemplateObjectEmpty}
                        menuProps={menuProps}
                        loading={false}
                        icon={<ChevronDown />}
                    />
                    <ActionButton
                        text="Clean JSON"
                        type=""
                        icon={<ArrowClockwise />}
                        onClickFunction={() => handleCleanJsonTemplate()}
                    />
                </Flex>

                <div>
                    <div
                        className="btn btn-sm cc-btn-outline-blue"
                        onClick={() => handleEditTemplateEvent(editedEventIdx)}
                    >
                        {jsonBtnText}
                    </div>
                </div>

                {editedEventIdx &&
                    <div>

                        <NestedObjectTable
                            data={typeof editedEventTemplate === "string" ? editedEventTemplate : JSON.stringify(editedEventTemplate)}
                            updateTemplateFunction={updateEditedTemplate}
                            setEditedEventTemplateNewVersion={setEditedEventTemplateNewVersion}
                        />

                    </div>
                }


                <div>
                    <h3 className="fw-bold mt-4 modal-category">
                        Define trigger for dataLayer event
                    </h3>
                    <p className='mb-3'>
                        Define how and where the dataLayer event can be found via the fields below.
                    </p>
                </div>

                <table id='triggers-table'>
                    <tbody>
                        <tr>
                            <td>
                                <p className='fw-bold mb-0'>Fires on URL</p>
                            </td>
                            <td>
                                <select className="form-select" value={urlMatchType} aria-label="Type select"
                                    onChange={(e) => setUrlMatchType(e.target.value)}
                                >
                                    {Object.keys(urlOptions).map((elem, idx) =>
                                        <option key={elem} value={elem}>
                                            {urlOptions[elem]}
                                        </option>)
                                    }

                                </select>
                            </td>
                            <td>
                                <Input value={editedEventUrls} placeholder="https://code-cube.io/"
                                    onChange={(e) => setEditedEventUrls(e.target.value)} />
                            </td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>


                {adminUser && (
                    <Space direction="vertical" size="medium" block="true">
                        <h3 className="fw-bold mt-4 modal-category pb-1">
                            Status of the event
                        </h3>

                        <DropdownMenu
                            text={editedEventStatus || "Select status"}
                            type=""
                            disabled={false}
                            menuProps={statusesProps}
                            loading={false}
                            icon={<ChevronDown />}
                            width={141}
                        />
                    </Space>
                )}

                <Space>
                    <ActionButton
                        text="Remove event"
                        type=""
                        icon={<Trash3 size={14} />}
                        onClickFunction={() => eventRemove(editedEventIdx)}
                        danger={true}
                    />
                </Space>
            </div>
        </>
    );
}
