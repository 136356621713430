import { React, useState, useEffect, createContext, useContext } from 'react';
import { Display, Stopwatch, HddStack, Envelope, Slack, MicrosoftTeams, Tools, WindowDesktop, Binoculars, ShieldCheck } from 'react-bootstrap-icons';

import ConfigService from '../../services/config.service';
import ProductSettingsService from '../../services/product.service';
import { AuthContext } from '../../services/auth.service';
import LoadingScreen from '../../components/elements/LoadingScreen';

import "./styles.scss";

import PageHeader from '../../components/elements/PageHeader';
import { SideMenu } from '../../components/layouts/MainLayout/SideMenu';
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";
import { EmailsNotifModal } from '../../components/layouts/MainLayout/SideMenu/EmailNotifModal';
import { SlackNotifModal } from '../../components/layouts/MainLayout/SideMenu/SlackNotifModal';
import { TeamsNotifModal } from '../../components/layouts/MainLayout/SideMenu/TeamsNotifModal';

import {
    ProductSettingsModal,
    CSTSettingsModal,
    SSTSettingsModal,
    TagInactivitySettingsModal,
    CloudMonitoringSettingsModal
} from "./configModals";

import { useMessage } from "../../components/elements/MessageCenter";
import colors from '../../styles/_colors.scss';

const _ = require('lodash');

// Save function
export const SaveConfigData = async (params) => {
    const {
        companyId,
        currentConfig,
        setCurrentConfig,
        configParams,
        modalSaveFunction,
        handlePanelClick,
        setLoadingActive,
        success,
        error,
    } = params;

    setLoadingActive(true);

    const configObj = { ...currentConfig };

    Object.keys(configParams).forEach(key => {
        configObj[key] = configParams[key];
    });

    const resp = await modalSaveFunction({ configObj, companyId });
    const respData = await resp?.json() || resp;

    switch (respData?.result) {
        case true:
            success("Configuration data successfully saved!");
            setCurrentConfig(configObj);
            break;
        default:
            error();
    }

    handlePanelClick("empty");
    setLoadingActive(false);
}

export const ProductSettingsContext = createContext();
export const CSTSettingsContext = createContext();
export const SSTSettingsContext = createContext();
export const TagInactivitySettingsContext = createContext();
export const cloudRunMonitoringContext = createContext();
export const EmailsNotifContext = createContext();
export const SlackNotifContext = createContext();
export const TeamsNotifContext = createContext();

const productDescLink = `https://docs.code-cube.io/`;
const CSImplmenetationLink = `https://docs.code-cube.io/tag-monitor/error-monitoring-client-side/`;
const SSImplmenetationLink = `https://docs.code-cube.io/tag-monitor/error-monitoring-server-side/`;
const TIImplmenetationLink = `https://docs.code-cube.io/tag-monitor/tag-inactivity/`;
const clientSideTemplateLink = `https://storage.googleapis.com/portal_dev_bucket/gtm_templates/Code%20Cube%20-%20Client-side%20Tag%20Monitor%20template.tpl`;
const serverSideTemplateLink = `https://storage.googleapis.com/portal_dev_bucket/gtm_templates/Code%20Cube%20-%20Server-side%20Tag%20Monitor%20template.tpl`;
const notifImplementationLink = `https://docs.code-cube.io/notifications/`;

export const TagMonitorConfig = () => {
    const { companyId, companyInfo } = useContext(AuthContext);
    const [currCompanyInfo, setCurrCompanyInfo] = useState(companyInfo);
    const [modalSaveStatus, setModalSaveStatus] = useState(false);

    // Using popup messages
    const { success, warning, error } = useMessage();

    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);

    const [loadingActive, setLoadingActive] = useState(true);

    const [currentConfig, setCurrentConfig] = useState({});
    const [configParams, setConfigParams] = useState({});

    const [clientSideAvailable, setClientSideAvailable] = useState(false);
    const [serverSideAvailable, setServerSideAvailable] = useState(false);
    const [tagInactivityAvailable, setTagInactivityAvailable] = useState(false);
    const [emailNotifAvailable, setEmailNotifAvailable] = useState(false);
    const [slackNotifAvailable, setSlackNotifAvailable] = useState(false);
    const [teamsNotifAvailable, setTeamsNotifAvailable] = useState(false);

    const [timezones, setTimezones] = useState([]);
    const [databaseName, setDatabaseName] = useState("");

    const [customNmbrCid, setCustomNmbrCid] = useState("");
    const [subscriptionModel, setSubscriptionModel] = useState("");
    const [cloudRunData, setCloudRunData] = useState({});

    const [slackWebhook, setSlackWebhook] = useState("");



    // Reloading the page content only if the new company profile is different
    useEffect(() => _.isEqual(companyInfo, currCompanyInfo) ? undefined : setCurrCompanyInfo(companyInfo), [companyInfo]);

    useEffect(() => {
        (async () => {
            setLoadingActive(true);

            const resp = await ConfigService.getTagMonitorConfigData(companyId);
            const respData = await resp.json();
            
            const cloudRunData = await ConfigService.getCloudRunMonitoringData(companyId);
            const cloudRunDataResponseData = await cloudRunData.json();
            setCloudRunData(cloudRunDataResponseData || {});

            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);

            setDatabaseName(respData?.config_data?.current_config?.database_name);
            setLoadingActive(true);
            setSideMenuState(false);

            setCurrentConfig(respData?.config_data?.current_config);

            const configData = respData?.config_data || {};
            setSlackWebhook(configData?.slack_webhook || "");

            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);

            setDatabaseName(respData?.config_data?.current_config?.database_name);

            setCustomNmbrCid(respData?.config_data?.current_config.custom_nmbr_cid);
            setSubscriptionModel(respData?.config_data?.current_config.subscription_package);

            const subLevel = companyInfo?.tag_monitor_subscription_model?.toLowerCase();
            const settings = await ProductSettingsService.getProductSettings();
            const subSettings = settings?.tag_monitor_settings[subLevel];
            setClientSideAvailable(subSettings?.client_side_monitoring);
            setServerSideAvailable(subSettings?.server_side_monitoring);
            setTagInactivityAvailable(subSettings?.tag_inactivity);
            setEmailNotifAvailable(subSettings?.email_notif);
            setSlackNotifAvailable(subSettings?.slack_notif);
            setTeamsNotifAvailable(subSettings?.teams_notif);

            setCurrentModal(<></>);
            setLoadingActive(false);
        })();
    }, [currCompanyInfo]);

    const modalFunc = {
        "empty": <></>,
        "general":
            <ProductSettingsContext.Provider value={{
                productDescLink,
                timezones,
                timezoneName: currentConfig?.timezone_name,
                curTimezone: currentConfig?.timezone,
                companyDomains: currentConfig?.company_domains,
                setConfigParams,
                setModalSaveStatus,
            }}>
                <ProductSettingsModal context={ProductSettingsContext} />
            </ProductSettingsContext.Provider>,
        "cst_monitoring":
            <CSTSettingsContext.Provider value={{
                databaseName,
                CSImplmenetationLink,
                clientSideTemplateLink,
                clientErrorsThreshold: currentConfig?.client_errors_threshold,
                gtmContainers: currentConfig?.gtm_containers_client || [],
                tags_monitoring_exclude_client: currentConfig?.tags_monitoring_exclude_client,
                setConfigParams,
                customNmbrCid,
                subscriptionModel,
                setModalSaveStatus,
            }}>
                <CSTSettingsModal context={CSTSettingsContext} />
            </CSTSettingsContext.Provider>,
        "sst_monitoring":
            <SSTSettingsContext.Provider value={{
                databaseName,
                SSImplmenetationLink,
                serverSideTemplateLink,
                serverErrorsThreshold: currentConfig?.server_errors_threshold,
                gtmContainers: currentConfig?.gtm_containers_server || [],
                tags_monitoring_exclude_server: currentConfig?.tags_monitoring_exclude_server,
                setConfigParams,
                serverSideAvailable,
                customNmbrCid,
                subscriptionModel,
                setModalSaveStatus,
            }}>
                <SSTSettingsModal context={SSTSettingsContext} />
            </SSTSettingsContext.Provider>,
        "tag_inactivity":
            <TagInactivitySettingsContext.Provider value={{
                TIImplmenetationLink,
                tagsClientSide: currentConfig?.tags_client_side?.split(",") || [],
                tagsServerSide: currentConfig?.tags_server_side?.split(",") || [],
                companyDomains: currentConfig?.company_domains,
                checkFrequency: currentConfig?.check_frequency,
                setConfigParams,
                setModalSaveStatus,
            }}>
                <TagInactivitySettingsModal context={TagInactivitySettingsContext} />
            </TagInactivitySettingsContext.Provider>,

        "cloud_run":
            <cloudRunMonitoringContext.Provider value={{
                companyId,
                cloudRunData,
                companyInfo,
                setModalSaveStatus,
            }}>
                <CloudMonitoringSettingsModal context={cloudRunMonitoringContext} />
            </cloudRunMonitoringContext.Provider>,

        "email_notif":
            <EmailsNotifContext.Provider value={{
                notifImplementationLink,
                enableEmailNotif: currentConfig?.email_updates || false,
                curEmailsList: currentConfig?.emails?.split(";") || [''],
                setConfigParams,
                setModalSaveStatus,
            }}>
                <EmailsNotifModal context={EmailsNotifContext} />
            </EmailsNotifContext.Provider>,
        "slack_notif":
            <SlackNotifContext.Provider value={{
                notifImplementationLink,
                enableSlackNotif: currentConfig?.slack_updates || false,
                slackChannel: currentConfig?.slack_channel || "",
                slackWorkspaceId: currentConfig?.slack_workspace_id || "",
                slackWebhook: slackWebhook || "",
                setConfigParams,
                setModalSaveStatus,
            }}>
                <SlackNotifModal context={SlackNotifContext} />
            </SlackNotifContext.Provider>,
        "teams_notif":
            <TeamsNotifContext.Provider value={{
                notifImplementationLink,
                enableTeamsNotif: currentConfig?.teams_updates || false,
                teamsChannel: currentConfig?.teams_channel || "",
                teamsWebhook: currentConfig?.teams_webhook || "",
                setConfigParams,
                setModalSaveStatus,
            }}>
                <TeamsNotifModal context={TeamsNotifContext} />
            </TeamsNotifContext.Provider>,
    }

    const handlePanelClick = (page) => {

        setSideMenuState(!sideMenuState);
        setCurrentModal(modalFunc[page]);
    }

    return (
        <>
            <SideMenu
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                popoverMessage={<>No changes to save.</>}
                saveFunction={() => SaveConfigData({
                    companyId,
                    currentConfig,
                    setCurrentConfig,
                    configParams,
                    modalSaveFunction: ConfigService.saveTagMonitorConfigData,
                    handlePanelClick,
                    setLoadingActive,
                    success,
                    error,
                    warning,
                    setModalSaveStatus,
                })}
                saveStatus={modalSaveStatus}
                confirmationNeeded={true}
            />
            <div className="row">
                <LoadingScreen isActive={loadingActive} />
                <div className="col-12 col-sm-10 col-md-8 text-start">
                    <div>
                        <div className="row">
                            <PageHeader categoryName="configuration" pageName="Tag Monitor" />
                            <div className="container-rows mt-4">
                                <div>
                                    <InteractivePanel
                                        icon={<Tools style={{color: "#ff661f"}} size={21} />}
                                        headerText="General settings"
                                        descriptionText="Settings for standard configuration like domain(s) to monitor and your time zone"
                                        available={true}
                                        clickFunction={() => handlePanelClick("general")}

                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon = {
                                            <svg width="21" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.55556 21H20.3333C20.7016 21 21 20.7016 21 20.3333V1.66667C21 1.29848 20.7016 1 20.3333 1H1.66667C1.29848 1 1 1.29848 1 1.66667V15.4444" stroke="#00E1C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.77734 4.33301H17.6662" stroke="#00E1C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4.33398 4.33301H5.4451" stroke="#00E1C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.55469 20.4444L10.9991 11M10.9991 11V15.4444M10.9991 11H6.55469" stroke="#00E1C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }
                                        headerText="Client-side error monitoring"
                                        descriptionText="Settings for monitoring of the client-side tag manager container"
                                        available={clientSideAvailable}
                                        clickFunction={() => handlePanelClick("cst_monitoring")}

                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<HddStack style={{color: "rgb(101, 53, 245)"}} size={21} />}
                                        headerText="Server-side error monitoring"
                                        descriptionText="Settings for monitoring of the server-side tag manager container"
                                        available={serverSideAvailable}
                                        clickFunction={() => handlePanelClick("sst_monitoring")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<ShieldCheck style={{color: "#F610B5"}} size={21} />}
                                        headerText="Cloud Run monitoring"
                                        descriptionText="Settings for health monitoring of the server-side tagging instance in Cloud Run (GCP)"
                                        available={serverSideAvailable}
                                        clickFunction={() => handlePanelClick("cloud_run")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon = {
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 2H5.8C4.11984 2 3.27976 2 2.63803 2.32698C2.07354 2.6146 1.6146 3.07354 1.32698 3.63803C1 4.27976 1 5.11984 1 6.8V15.2C1 16.8802 1 17.7202 1.32698 18.362C1.6146 18.9265 2.07354 19.3854 2.63803 19.673C3.27976 20 4.11984 20 5.8 20H14.2C15.8802 20 16.7202 20 17.362 19.673C17.9265 19.3854 18.3854 18.9265 18.673 18.362C19 17.7202 19 16.8802 19 15.2V12M10 7H14V11M13.5 2.5V1M17.4393 3.56066L18.5 2.5M18.5103 7.5H20.0103M1 12.3471C1.65194 12.4478 2.31987 12.5 3 12.5C7.38636 12.5 11.2653 10.3276 13.6197 7" stroke="#3100C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>}
                                        headerText="Tag Volume monitoring"
                                        descriptionText="Settings for monitoring of tag volume in real-time"
                                        available={tagInactivityAvailable}
                                        clickFunction={() => handlePanelClick("tag_inactivity")}
                                    />
                                </div>
                            </div>
                            <h3 style={{ fontSize: '1em' }} className="page-category">NOTIFICATION SETTINGS</h3>
                            <div className="container-rows">
                                <div>
                                    <InteractivePanel
                                        style={{color: "#ff661f"}}
                                        icon={<Envelope style={{color: "#ff661f"}} size={21} />}
                                        headerText="Email notifications"
                                        descriptionText="Settings to configure email notifications"
                                        available={emailNotifAvailable}
                                        clickFunction={() => handlePanelClick("email_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Slack size={21} />}
                                        headerText="Slack notifications"
                                        descriptionText="Settings to configure Slack notifications"
                                        available={slackNotifAvailable}
                                        clickFunction={() => handlePanelClick("slack_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<MicrosoftTeams size={21} />}
                                        headerText="Teams notifications"
                                        descriptionText="Settings to configure Teams notifications"
                                        available={teamsNotifAvailable}
                                        clickFunction={() => handlePanelClick("teams_notif")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
