import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Person, Buildings, Coin, ShieldLock } from "react-bootstrap-icons";
import { Space } from "antd";
import ProfileService from "../../services/profile.service";
import { AuthContext } from "../../services/auth.service";
import LoadingScreen from "../../components/elements/LoadingScreen";
import PageHeader from "../../components/elements/PageHeader";
import { SideMenu } from "../../components/layouts/MainLayout/SideMenu";
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";
import { ConfirmModal } from '../../components/elements/ConfirmModal';
import { useMessage } from "../../components/elements/MessageCenter";

import {
    ProfileDetails,
    CompanyDetails,
    BillingDetails,
} from "./configModals";

/**
* Saving data function
* 
* Sending request to save profile data for user, company and billing profiles.
* 
* @param {Object} props Parameters for the checkup
* @param {Number} props.companyId Company ID
* @param {String} props.firstName First Name
* @param {String} props.lastName Last Name
* @param {String} props.email Email
* @param {String} props.companyName COmpany Name
* @param {String} props.chamberOfCommerceNumber Chamber of Commerce Number
* @param {String} props.vatNumber VAT Number
* @param {String} props.invoiceRefNumber Invoice Reference Number
* @param {String} props.billingFullName Billing - Full name
* @param {String} props.billingEmail Billing - Email
* @param {String} props.billingStreet1 Billing - Street 1
* @param {String} props.billingStreet2 Billing - Street 2
* @param {String} props.billingPostcode Billing - Postcode
* @param {String} props.billingCity Billing - City
* @param {String} props.billingCountry Billing - Country
* @param {Function} props.modalSaveFunction Function to send data to backend
* @param {Function} props.handlePanelClick Function to imitate clicking on panel
* @param {Function} props.setLoadingActive Function to swtich loading page component
* @param {Function} props.success Function to call a popup success message
* @param {Function} props.error Function to call a popup error message
* @param {Function} props.warning Function to call a popup warning message
* @param {Function} props.setModalSaveStatus Function to set status for the save button inside side menu
* 
* @returns {null}
*/
export const SaveConfigData = async (params) => {
    const {
        companyId,
        currentConfig,
        configParams,
        modalSaveFunction,
        curModalSaveFunc,
        handlePanelClick,
        setLoadingActive,
        setCurrentConfig,
        updateConfigFields,
        success,
        error,
        setModalSaveStatus,
    } = params;

    setLoadingActive(true);

    const configObj = { ...currentConfig };

    Object.keys(configParams?.user_profile || {})?.forEach(key =>
        configObj["user_profile"][key] = configParams?.user_profile[key]
    );

    configObj["user_profile"]["full_name"] = (
        configObj?.user_profile["first_name"] + " " + configObj?.user_profile["last_name"]
    ).trim();

    Object.keys(configParams?.company_profile || {})?.forEach(key =>
        configObj["company_profile"][key] = configParams?.company_profile[key]
    );

    Object.keys(configParams?.billing_profile || {})?.forEach(key =>
        configObj["billing_profile"][key] = configParams?.billing_profile[key]
    );

    const resp = await modalSaveFunction({
        companyId,
        configObj,
        curModalSaveFunc,
    });
    const respData = await resp?.json() || resp;

    switch (respData?.result) {
        case true:
            success("Profile data successfully saved!");
            break;
        default:
            error();
    }

    setCurrentConfig(configObj);
    updateConfigFields(configObj);

    handlePanelClick("empty");
    setModalSaveStatus(false);

    setLoadingActive(false);
}

export const SendInvoiceMail = async (params) => {
    const {
        companyId,
        modalConfirmFunction,
        setLoadingActive,
        success,
        error,
        setIsModalVisible,
        firstName,
        lastName
    } = params;

    setLoadingActive(true);

    // Combine first name and last name to form full name
    const fullName = `${firstName} ${lastName}`.trim();

    try {
        // Call the modal confirm function with the necessary parameters
        const resp = await modalConfirmFunction({ companyId, fullName });
        const respData = await resp?.json() || resp;

        // Handle the response
        if (respData?.result === true) {
            success("Invoice mail has been sent successfully!");
            setIsModalVisible(false); // Close modal after success
        } else {
            error("Failed to send invoice mail. Please try again.");
        }
    } catch (err) {
        console.error("Error:", err);
        error("An error occurred while sending the invoice mail. Please try again.");
    } finally {
        setLoadingActive(false);
    }
}

export const ProfileDetailsContext = createContext();
export const CompanyDetailsContext = createContext();
export const BillingDetailsContext = createContext();
export const SendInvoiceMailContext = createContext();

/**
* Account settings page component
* 
* @returns {ReactNode} Component with account settings content
*/

export const AccountSettings = () => {
    const navigate = useNavigate();

    // Using popup messages
    const { success, warning, error } = useMessage();

    // Using authentication context to get company info
    const { companyId, companyInfo } = useContext(AuthContext);
    const [currCompanyInfo, setCurrCompanyInfo] = useState(companyInfo);

    // Defining states for side menu
    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);
    const [curModalSaveFunc, setCurModalSaveFunc] = useState("");
    const [modalSaveStatus, setModalSaveStatus] = useState(false);

    // Controlling loading window status
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [loadingActive, setLoadingActive] = useState(true);

    // Account properties - user, company and billing profiles
    const [currentConfig, setCurrentConfig] = useState({});
    const [configParams, setConfigParams] = useState({});

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [chamberOfCommerceNumber, setChamberOfCommerceNumber] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [moneybirdCid, setMoneybirdCid] = useState("");
    const [billingStreet1, setBillingStreet1] = useState("");
    const [billingStreet2, setBillingStreet2] = useState("");
    const [billingPostcode, setBillingPostcode] = useState("");
    const [billingProvince, setBillingProvince] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingCountry, setBillingCountry] = useState("");

    const [billingFullName, setBillingFullName] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [invoiceRefNumber, setInvoiceRefNumber] = useState("");


    const updateConfigFields = (context = {}) => {
        setFirstName(context?.user_profile?.first_name || "");
        setLastName(context?.user_profile?.last_name || "");
        setEmail(context?.user_profile?.email || "");

        setCompanyName(context?.company_profile?.display_company_name || "");
        setChamberOfCommerceNumber(context?.company_profile?.company_cocn_number || "");
        setVatNumber(context?.company_profile?.company_vat_number || "");
        setMoneybirdCid(context?.company_profile?.moneybird_cid || "");
        setBillingStreet1(context?.company_profile?.billing_street_address_1 || "");
        setBillingStreet2(context?.company_profile?.billing_street_address_2 || "");
        setBillingPostcode(context?.company_profile?.billing_postal_code || "");
        setBillingProvince(context?.company_profile?.billing_province || "");
        setBillingCity(context?.company_profile?.billing_city || "");
        setBillingCountry(context?.company_profile?.billing_country || "");

        setBillingFullName(context?.billing_profile?.billing_fullname || "");
        setBillingEmail(context?.billing_profile?.billing_email || "");
        setInvoiceRefNumber(context?.billing_profile?.company_invoice_ref_number || "");
    };


    // Initial page loading. Calling every time use changing the company profile
    useEffect(() => {
        (async () => {
            // Showing the loading screen while we retrieving the data from backend
            setLoadingActive(true);

            setSideMenuState(false);

            // Hiding the loading screen and aborting execution if there"s no company chosen 
            if (!companyId) {
                setLoadingActive(false);
                return;
            }

            // Retrieving the data from backend
            const reqObj = { companyId };

            const response = await ProfileService.getCompanyProfileInfo(reqObj);
            const respData = await response.json();

            const context = respData?.context;
            setCurrentConfig(context);
            updateConfigFields(context);

            // Hiding the loading screen
            setLoadingActive(false);
        })();
    }, [currCompanyInfo]);

    // Update chosen company info
    useEffect(() => setCurrCompanyInfo(companyInfo), [companyInfo]);

    // Context providers for each side menu options
    const modalFunc = {
        "empty": <></>,
        "profile_details":
            <ProfileDetailsContext.Provider value={{
                firstName,
                lastName,
                email,
                companyId,
                setModalSaveStatus,
                setConfigParams,
            }}>
                <ProfileDetails context={ProfileDetailsContext} />
            </ProfileDetailsContext.Provider>,
        "company_details":
            <CompanyDetailsContext.Provider value={{
                companyName,
                chamberOfCommerceNumber,
                vatNumber,
                moneybirdCid,
                billingStreet1,
                billingStreet2,
                billingPostcode,
                billingProvince,
                billingCity,
                billingCountry,
                companyId,
                setModalSaveStatus,
                setConfigParams,
            }}>
                <CompanyDetails context={CompanyDetailsContext} />
            </CompanyDetailsContext.Provider>,
        "billing_details":
            <BillingDetailsContext.Provider value={{
                billingFullName,
                billingEmail,
                invoiceRefNumber,
                companyId,
                setModalSaveStatus,
                setConfigParams,
            }}>
                <BillingDetails context={BillingDetailsContext} />
            </BillingDetailsContext.Provider>,
    }

    // Handling a click on one of panels to update the side menu data 
    const handlePanelClick = (page) => {
        setSideMenuState(!sideMenuState);
        setCurrentModal(modalFunc[page]);
        setCurModalSaveFunc(page);
    }

    // Handling the modal visibility
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Handling the modal confirmation
    const handleConfirm = async () => {
        await SendInvoiceMail({
            companyId,
            firstName,
            lastName,
            modalConfirmFunction: ProfileService.sendInvoiceMail,
            setLoadingActive,
            success,
            error,
            setIsModalVisible
        });
    };

    // Returning page content
    return (
        <>
            {/* Initializing side menu functionality */}
            <SideMenu
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                saveFunction={() => SaveConfigData({
                    companyId,
                    modalSaveFunction: ProfileService.saveProfileData,
                    handlePanelClick,
                    setLoadingActive,
                    curModalSaveFunc,
                    currentConfig,
                    configParams,
                    setCurrentConfig,
                    updateConfigFields,
                    success,
                    error,
                    warning,
                    setModalSaveStatus,
                })}
                saveStatus={modalSaveStatus}
                confirmationNeeded={true}
            />
            <Space block="true">
                <LoadingScreen isActive={loadingActive} />
                <Space direction="vertical" size="large" block="true">
                    <PageHeader categoryName="profile" pageName="Account Settings" />
                    <Space direction="vertical" className="container-rows">
                        <InteractivePanel
                            icon={<Person size={21} />}
                            headerText="Personal details"
                            descriptionText="Personal details linked to your account."
                            available={true}
                            clickFunction={() => handlePanelClick("profile_details")}
                        />
                        <InteractivePanel
                            icon={<Buildings size={21} />}
                            headerText="Company details"
                            descriptionText="Company name, VAT number, Chamber of Commerce number and Invoice reference number."
                            available={true}
                            clickFunction={() => handlePanelClick("company_details")}
                        />
                        <InteractivePanel
                            icon={<Coin size={21} />}
                            headerText="Billing details"
                            descriptionText="Your company billing details."
                            available={true}
                            clickFunction={() => handlePanelClick("billing_details")}
                        />
                        <InteractivePanel
                            icon={<ShieldLock size={21} />}
                            headerText="Reset password"
                            descriptionText="Change your account password."
                            available={true}
                            clickFunction={() => navigate("/password-reset")}
                        />
                        <InteractivePanel
                            icon={<ShieldLock size={21} />}
                            headerText="Send invoice mail"
                            descriptionText="Send an invoice mail to this."
                            available={true}
                            clickFunction={() => setIsModalVisible(true)} // Open modal on click
                        />
                    </Space>
                </Space>
            </Space>
            <ConfirmModal
                visible={isModalVisible}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                title="Send Invoice Mail"
                content="Are you sure you want to send the invoice mail?"
                okText="Send"
                cancelText="Cancel"
            />
        </>
    );
}