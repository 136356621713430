import React, { useState, useEffect, useRef, useContext } from 'react';
import CountryDropdown from '../../components/elements/CountryDropdown'; // Import the CountryDropdown component
import { Input, Space, Typography } from 'antd';
const { Title, Paragraph, Text, Link } = Typography;

const modalHeaderName = ("settings");

// Side menu content for Profile Details
export const ProfileDetails = ({ context }) => {
    const {
        firstName,
        lastName,
        email,
        companyId,
        setModalSaveStatus,
        setConfigParams,
    } = useContext(context);

    const initialFirstName = useRef(firstName);
    const initialLastName = useRef(lastName);
    const initialEmail = useRef(email);

    const [curFirstName, setCurFirstName] = useState(firstName);
    const [curLastName, setCurLastName] = useState(lastName);
    const [curEmail, setCurEmail] = useState(email);

    const [firstNameStatus, setFirstNameStatus] = useState(firstName?.length > 0);
    const [lastNameStatus, setLastNameStatus] = useState(lastName?.length > 0);
    const [emailStatus, setEmailStatus] = useState(email?.length > 0);

    const [saveStatus, setSaveStatus] = useState(false);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const checkSaveStatus = () => {
        setSaveStatus(
            curFirstName?.length > 0 &&
            curLastName?.length > 0 &&
            curEmail?.length > 0 &&
            (curFirstName !== initialFirstName.current || curLastName !== initialLastName.current || curEmail !== initialEmail.current)
        );
    };

    useEffect(() => {
        setCurFirstName(firstName);
        setCurLastName(lastName);
        setCurEmail(email);
    }, [firstName, lastName, email]);

    useEffect(() => {
        setFirstNameStatus(curFirstName?.length > 0);
        setLastNameStatus(curLastName?.length > 0);
        setEmailStatus(curEmail?.length > 0);

        checkSaveStatus();

        const newObject = { ...configParamsToSave };
        const userProfile = {
            first_name: curFirstName,
            last_name: curLastName,
            email: curEmail,
        };
        newObject["user_profile"] = userProfile;

        setConfigParamsToSave(newObject);
    }, [curFirstName, curLastName, curEmail]);

    useEffect(() => setSaveStatus(false), []);

    useEffect(() => setModalSaveStatus(saveStatus), [saveStatus]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);


    return (
        <Space key={companyId} direction="vertical" size="large">
            <Space direction="vertical" size="small">
                <h5 className='small-heading'>{modalHeaderName}</h5>
                <h2 className='header-letter-spacing'>Personal details</h2>
                <p>Fill in your personal details, which will be linked to your Code Cube account.</p>
            </Space>
            <Space direction="vertical" size="large">
                <Space direction="vertical" size="small">
                    <Text strong>First name *</Text>
                    <Input
                        value={curFirstName}
                        onChange={(e) => setCurFirstName(e.target.value)}
                        status={firstNameStatus ? "" : "error"}
                    />
                </Space>
                <Space direction="vertical" size="small">
                    <Text strong>Last name *</Text>
                    <Input
                        value={curLastName}
                        onChange={(e) => setCurLastName(e.target.value)}
                        status={lastNameStatus ? "" : "error"}
                    />
                </Space>
                <Space direction="vertical" size="small">
                    <Text strong>Email *</Text>
                    <Text>Add your personal email address, this email will also be used as your username in the portal.</Text>
                    <Space direction="vertical" size="small">
                        <Input
                            placeholder="hello@code-cube.io"
                            value={curEmail}
                            type="email"
                            onChange={(e) => setCurEmail(e.target.value)}
                            status={emailStatus ? "" : "error"}
                        />
                    </Space>
                </Space>
            </Space>
        </Space>
    );
};

// Side menu content for Company Details
export const CompanyDetails = ({ context }) => {
    const {
        companyName,
        chamberOfCommerceNumber,
        vatNumber,
        moneybirdCid,
        billingStreet1,
        billingStreet2,
        billingPostcode,
        billingProvince,
        billingCity,
        billingCountry,
        companyId,
        setModalSaveStatus,
        setConfigParams,
    } = useContext(context);

    const initialCompanyName = useRef(companyName);
    const initialChamberOfCommerceNumber = useRef(chamberOfCommerceNumber);
    const initialVatNumber = useRef(vatNumber);
    const initialMoneybirdCid = useRef(moneybirdCid);
    const initialBillingStreet1 = useRef(billingStreet1);
    const initialBillingStreet2 = useRef(billingStreet2);
    const initialBillingPostcode = useRef(billingPostcode);
    const initialBillingProvince = useRef(billingProvince);
    const initialBillingCity = useRef(billingCity);
    const initialBillingCountry = useRef(billingCountry);

    const [curCompanyName, setCurCompanyName] = useState(companyName);
    const [curChamberOfCommerceNumber, setCurChamberOfCommerceNumber] = useState(chamberOfCommerceNumber);
    const [curVatNumber, setCurVatNumber] = useState(vatNumber);
    const [curMoneybirdCid, setCurMoneybirdCid] = useState(moneybirdCid);
    const [curBillingStreet1, setCurBillingStreet1] = useState(billingStreet1);
    const [curBillingStreet2, setCurBillingStreet2] = useState(billingStreet2);
    const [curBillingPostcode, setCurBillingPostcode] = useState(billingPostcode);
    const [curBillingProvince, setCurBillingProvince] = useState(billingProvince);
    const [curBillingCity, setCurBillingCity] = useState(billingCity);
    const [curBillingCountry, setCurBillingCountry] = useState(billingCountry);

    const [companyNameStatus, setCompanyNameStatus] = useState(companyName?.length > 0);
    const [chamberOfCommerceStatus, setChamberOfCommerceStatus] = useState(chamberOfCommerceNumber?.length > 0);
    const [billingStreet1Status, setBillingStreet1Status] = useState(billingStreet1?.length > 0);
    const [billingCountryStatus, setBillingCountryStatus] = useState(billingCountry?.length > 0);

    const [saveStatus, setSaveStatus] = useState(false);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const checkSaveStatus = () => {
        const requiredFieldsFilled = curCompanyName?.length > 0 && curChamberOfCommerceNumber?.length > 0 && curBillingStreet1?.length > 0 && curBillingCountry?.length > 0;
        const anyFieldChanged = curCompanyName !== initialCompanyName.current ||
            curChamberOfCommerceNumber !== initialChamberOfCommerceNumber.current ||
            curVatNumber !== initialVatNumber.current ||
            curMoneybirdCid !== initialMoneybirdCid.current ||
            curBillingStreet1 !== initialBillingStreet1.current ||
            curBillingStreet2 !== initialBillingStreet2.current ||
            curBillingPostcode !== initialBillingPostcode.current ||
            curBillingProvince !== initialBillingProvince.current ||
            curBillingCity !== initialBillingCity.current ||
            curBillingCountry !== initialBillingCountry.current;

        setSaveStatus(anyFieldChanged ? (requiredFieldsFilled ? true : false) : false);
    };

    useEffect(() => {
        setCurCompanyName(companyName);
        setCurChamberOfCommerceNumber(chamberOfCommerceNumber);
        setCurVatNumber(vatNumber);
        setCurMoneybirdCid(moneybirdCid);
        setCurBillingStreet1(billingStreet1);
        setCurBillingStreet2(billingStreet2);
        setCurBillingPostcode(billingPostcode);
        setCurBillingProvince(billingProvince);
        setCurBillingCity(billingCity);
        setCurBillingCountry(billingCountry);
    }, [companyName, chamberOfCommerceNumber, vatNumber, moneybirdCid, billingStreet1, billingStreet2, billingPostcode, billingProvince, billingCity, billingCountry]);

    useEffect(() => {
        setCompanyNameStatus(curCompanyName?.length > 0);
        setChamberOfCommerceStatus(curChamberOfCommerceNumber?.length > 0);
        setBillingStreet1Status(curBillingStreet1?.length > 0);
        setBillingCountryStatus(curBillingCountry?.length > 0);

        checkSaveStatus();

        const newObject = { ...configParamsToSave };
        const companyProfile = {
            display_company_name: curCompanyName,
            company_cocn_number: curChamberOfCommerceNumber,
            company_vat_number: curVatNumber,
            moneybird_cid: curMoneybirdCid,
            billing_street_address_1: curBillingStreet1,
            billing_street_address_2: curBillingStreet2,
            billing_postal_code: curBillingPostcode,
            billing_province: curBillingProvince,
            billing_city: curBillingCity,
            billing_country: curBillingCountry,
        };
        newObject["company_profile"] = companyProfile;

        setConfigParamsToSave(newObject);
    }, [
        curCompanyName,
        curChamberOfCommerceNumber,
        curVatNumber,
        curMoneybirdCid,
        curBillingStreet1,
        curBillingStreet2,
        curBillingPostcode,
        curBillingProvince,
        curBillingCity,
        curBillingCountry
    ]);

    useEffect(() => setSaveStatus(false), []);

    useEffect(() => setModalSaveStatus(saveStatus), [saveStatus]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);


    return (
        <Space key={companyId} direction="vertical" size={50}>
            <Space direction="vertical" size="small">
                <Space direction="vertical" size="small">
                    <h5 className='small-heading'>{modalHeaderName}</h5>
                    <h2 className='header-letter-spacing'>Company details</h2>
                    <p>Fill in the relevant company details in the fields below.</p>
                </Space>

                <Space direction="vertical" size="middle">
                    <Space direction="vertical" size="small">
                        <Text strong>Company name *</Text>
                        <Space.Compact size="middle">
                            <Input
                                value={curCompanyName}
                                onChange={(e) => setCurCompanyName(e.target.value)}
                                status={companyNameStatus ? "" : "error"}
                            />
                        </Space.Compact>
                    </Space>

                    <Space direction="horizontal" size={50}>
                        <Space direction="vertical" size="small">
                            <Text strong>Chamber of commerce *</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curChamberOfCommerceNumber}
                                    onChange={(e) => setCurChamberOfCommerceNumber(e.target.value)}
                                    status={chamberOfCommerceStatus ? "" : "error"}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small">
                            <Text strong>VAT number</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curVatNumber}
                                    onChange={(e) => setCurVatNumber(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small">
                            <Text strong>Moneybird Contact ID</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curMoneybirdCid}
                                    onChange={(e) => setCurMoneybirdCid(e.target.value)}
                                    // status={moneybirdCidStatus ? "" : "error"}
                                    disabled
                                />
                            </Space.Compact>
                        </Space>
                    </Space>

                </Space>
            </Space>

            <Space direction="vertical" size="large">
                <Space direction="vertical" size="small">
                    <h2 className='header-letter-spacing'>Company address</h2>
                    <p>Fill the information about the company address in the fields below.</p>
                </Space>

                <Space direction="vertical" size="middle">
                    <Space direction="vertical" size="small">
                        <Text strong>Street address *</Text>
                        <Space.Compact size="middle">
                            <Input
                                value={curBillingStreet1}
                                onChange={(e) => setCurBillingStreet1(e.target.value)}
                                status={billingStreet1Status ? "" : "error"}
                            />
                        </Space.Compact>
                    </Space>
                    <Space direction="vertical" size="small">
                        <Text strong>Street address 2</Text>
                        <Space.Compact size="middle">
                            <Input
                                value={curBillingStreet2}
                                onChange={(e) => setCurBillingStreet2(e.target.value)}
                            />
                        </Space.Compact>
                    </Space>

                    <Space direction="horizontal" size={50}>
                        <Space direction="vertical" size="small">
                            <Text strong>Postal Code</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingPostcode}
                                    onChange={(e) => setCurBillingPostcode(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small">
                            <Text strong>State / Province</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingProvince}
                                    onChange={(e) => setCurBillingProvince(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                    </Space>

                    <Space direction="horizontal" size={50}>
                        <Space direction="vertical" size="small">
                            <Text strong>Country *</Text>
                            <Space.Compact size="middle">
                                <CountryDropdown
                                    value={curBillingCountry}
                                    onChange={(value) => setCurBillingCountry(value)}
                                    status={billingCountryStatus ? "" : "error"}
                                />
                            </Space.Compact>
                        </Space>
                        <Space direction="vertical" size="small">
                            <Text strong>City</Text>
                            <Space.Compact size="middle">
                                <Input
                                    value={curBillingCity}
                                    onChange={(e) => setCurBillingCity(e.target.value)}
                                />
                            </Space.Compact>
                        </Space>
                    </Space>

                </Space>
            </Space>
        </Space>
    );
};

// Side menu content for Billing Details
export const BillingDetails = ({ context }) => {
    const {
        billingFullName,
        billingEmail,
        invoiceRefNumber,
        companyId,
        setModalSaveStatus,
        setConfigParams,
    } = useContext(context);

    const initialBillingFullName = useRef(billingFullName);
    const initialBillingEmail = useRef(billingEmail);
    const initialInvoiceRefNumber = useRef(invoiceRefNumber);

    const [curBillingFullName, setCurBillingFullName] = useState(billingFullName);
    const [curBillingEmail, setCurBillingEmail] = useState(billingEmail);
    const [curInvoiceRefNumber, setCurInvoiceRefNumber] = useState(invoiceRefNumber);

    const [fullNameStatus, setFullNameStatus] = useState(billingFullName?.length > 0);
    const [emailStatus, setEmailStatus] = useState(billingEmail?.length > 0);

    const [saveStatus, setSaveStatus] = useState(false);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const checkSaveStatus = () => {
        const requiredFieldsFilled = curBillingFullName?.length > 0 && curBillingEmail?.length > 0;
        const anyFieldChanged = curBillingFullName !== initialBillingFullName.current ||
            curBillingEmail !== initialBillingEmail.current ||
            curInvoiceRefNumber !== initialInvoiceRefNumber.current;

        setSaveStatus(requiredFieldsFilled && anyFieldChanged);
    };

    useEffect(() => {
        setCurBillingFullName(billingFullName);
        setCurBillingEmail(billingEmail);
        setCurInvoiceRefNumber(invoiceRefNumber);
    }, [billingFullName, billingEmail, invoiceRefNumber]);

    useEffect(() => {
        setFullNameStatus(curBillingFullName?.length > 0);
        setEmailStatus(curBillingEmail?.length > 0);

        checkSaveStatus();

        const newObject = { ...configParamsToSave };
        const billingProfile = {
            billing_fullname: curBillingFullName,
            billing_email: curBillingEmail,
            company_invoice_ref_number: curInvoiceRefNumber,
        };
        newObject["billing_profile"] = billingProfile;

        setConfigParamsToSave(newObject);
    }, [curBillingFullName, curBillingEmail, curInvoiceRefNumber]);

    useEffect(() => setSaveStatus(false), []);

    useEffect(() => setModalSaveStatus(saveStatus), [saveStatus]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);


    return (
        <Space key={companyId} direction="vertical" size={50}>
            <Space direction="vertical" size="small">
                <h5 className="small-heading">{modalHeaderName}</h5>
                <h2 className="header-letter-spacing">Billing details</h2>
                <p>Fill in the relevant billing details in the fields below.</p>
            </Space>

            <Space direction="vertical" size="middle">
                <Space direction="vertical" size="small">
                    <Text strong>Full name *</Text>
                    <Space.Compact size="middle">
                        <Input
                            value={curBillingFullName}
                            onChange={(e) => setCurBillingFullName(e.target.value)}
                            status={fullNameStatus ? "" : "error"}
                        />
                    </Space.Compact>
                </Space>

                <Space direction="vertical" size="small">
                    <Text strong>Email *</Text>
                    <Space.Compact size="middle">
                        <Input
                            value={curBillingEmail}
                            onChange={(e) => setCurBillingEmail(e.target.value)}
                            status={emailStatus ? "" : "error"}
                        />
                    </Space.Compact>
                </Space>

                <Space direction="vertical" size="small">
                    <Text strong>Invoice Reference Number</Text>
                    <Space.Compact size="middle">
                        <Input
                            value={curInvoiceRefNumber}
                            onChange={(e) => setCurInvoiceRefNumber(e.target.value)}
                        />
                    </Space.Compact>
                </Space>
            </Space>
        </Space>
    );
};
