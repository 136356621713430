import React, { useState, useEffect } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Checkbox, Space, Select, Input, Flex, Modal, Popover } from "antd";

import { PlayCircleOutlined, FieldTimeOutlined, AimOutlined, HolderOutlined, FormOutlined, SearchOutlined, PlusCircleFilled, DeleteOutlined, FunctionOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';

import "./styles.scss";
import colors from '../../../styles/_colors.scss';
import { ActionButton } from "../../../components/elements/Button";

const generateUniqueId = () => uuidv4();

const defaultStepCellStyle = {
    width: "50rem",
    padding: "1rem",
    margin: ".5rem 0",
    backgroundColor: "#fff",
    border: `.1rem ${colors.ccTurquoise} solid`,
    borderRadius: ".5rem",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    cursor: "grab",
};

const stepTypes = {
    "": "Select action type",
    "click_on_element": "Click on element",
    "collect_dl_object": "Collect dataLayer object",
    "collect_dl_before_navigation": "Collect dataLayer object (before navigation)",
    "date_range_select": "Select date range",
    "fill_input_field": "Fill input field",
    "go_to_page": "Go to page",
    "pick_random_element_from_list": "Pick random element from list",
    "select_option": "Select dropdown menu option",
    "wait_until_element_visible": "Wait until element appear",
};

const stepParamDescriptions = {
    "action_type": "Define an action type that has to be executed on the page.",
    "delay": "A time delay after action complete (in seconds).",
    "optional": "Wether current step is optional and should not interrupt the execution (might be useful for elements that appears in unique cases).",
    "wait_for_page_load": "Wait for a page load after completing the action.",
    "element_selector": "Query selector of the element.",
    "value": "Fill the input value with following text.",
    "datalayer_obj_to_find": "Template of the DataLayer object to find on the page.",
};


const defaultStepObject = () => ({
    step: null,
    stepId: generateUniqueId(),
    delay: 0,
    type: null,
    selector: null,
    optional: false,
    waitForNavigation: true,
    dataLayerObjToFind: null,
    value: null,
});

// Sortable item component
function SortableItem(props) {
    const {
        step = {},
        index = 0,
        handleDataLayerObjEdit = () => { },
        handleStepEdit = () => { },
        handleDeleteStepClick = () => { },
    } = props;

    const [stepId, setStepId] = useState(step?.stepId || "");
    const [stepType, setStepType] = useState(step?.type || "");
    const [stepSelector, setStepSelector] = useState(step?.selector || "");
    const [stepDelay, setStepDelay] = useState(step?.delay || 0);
    const [stepOptional, setStepOptional] = useState(!!step?.optional);
    const [stepWaitForPageLoad, setWaitForPageLoad] = useState(!!step?.wait_for_page_load);
    const [stepValue, setStepValue] = useState(step?.value);
    const [stepDataLayerObj, setStepDataLayerObj] = useState(step?.dataLayerObjToFind || []);

    // Field labels
    const [stepValueLabel, setStepValueLabel] = useState();
    useEffect(() => {
        switch (stepType) {
            case "go_to_page":
                setStepValueLabel("Page address");
                break;
            case "collect_dl_object":
            case "collect_dl_before_navigation":
                setStepValueLabel("Event name");
                break;
            default:
                setStepValueLabel("Input field value");
        }
    }, [stepType]);

    // useSortable hook
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: step.stepId,
        key: step.stepId,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        ...defaultStepCellStyle,
    };

    useEffect(() => {
        const newStepData = step;
        step.step = index;
        step.type = stepType;
        step.selector = stepSelector;
        step.delay = stepDelay;
        step.optional = stepOptional;
        step.waitForNavigation = stepWaitForPageLoad;
        step.value = stepValue;
        step.dataLayerObjToFind = stepDataLayerObj;

        handleStepEdit(stepId, newStepData);
    }, [stepType, stepSelector, stepDelay, stepOptional, stepWaitForPageLoad, stepValue, stepDataLayerObj]);

    useEffect(() => {
        setStepId(step?.stepId);
        setStepType(step?.type);
        setStepSelector(step?.selector);
        setStepDelay(step?.delay);
        setStepOptional(step?.optional);
        setWaitForPageLoad(step?.wait_for_page_load);
        setStepValue(step?.value);
        setStepDataLayerObj(step?.dataLayerObjToFind);
    }, [step]);

    return (
        <Space size={20}>
            <div className="scenario-step-number">{index}</div>
            <div key={index} style={style} ref={setNodeRef} {...attributes} {...listeners}>
                <Flex gap={25} justify="space-between">
                    <Space size={15} direction="vertical">

                        <Flex gap={25} align="center">
                            <Space size={5} direction="vertical">
                                <Popover placement="topLeft" content={stepParamDescriptions.action_type}>
                                    <Space size={5}>
                                        <PlayCircleOutlined />
                                        <label className="scenario-step-label">Action type</label>
                                    </Space>
                                </Popover>
                                <Select
                                    options={
                                        Object.keys(stepTypes).map(key => ({
                                            value: key,
                                            label: stepTypes[key],
                                            disabled: (key === "" ? true : false),
                                        }))
                                    }
                                    value={stepType}
                                    placeholder="Select action type"
                                    onChange={(value) => setStepType(value)}
                                    popupMatchSelectWidth={false}
                                    onPointerDown={(e) => e.stopPropagation()}
                                    style={{
                                        height: 30,
                                    }}
                                />
                            </Space>
                            <Space size={5} direction="vertical">
                                <Popover placement="topLeft" content={stepParamDescriptions.delay}>
                                    <Space size={5}>
                                        <FieldTimeOutlined />
                                        <label className="scenario-step-label">Delay (sec.)</label>
                                    </Space>
                                </Popover>
                                <Input
                                    value={stepDelay}
                                    type="number"
                                    defaultValue={0}
                                    max={10}
                                    min={0}
                                    onChange={(e) => setStepDelay(e.target.value)}
                                    style={{
                                        maxWidth: 80,
                                        height: 30,
                                    }}
                                    onPointerDown={(e) => e.stopPropagation()}
                                />
                            </Space>
                            <Space direction="vertical">
                                <Popover placement="topLeft" content={stepParamDescriptions.optional}>
                                    <Space size={5}>
                                        <Checkbox
                                            checked={stepOptional}
                                            onChange={(e) => setStepOptional(e.target.checked)}
                                            onPointerDown={(e) => e.stopPropagation()}
                                        />
                                        <label>Optional</label>
                                    </Space>
                                </Popover>
                                <Popover placement="right" content={stepParamDescriptions.wait_for_page_load}>
                                    <Space size={5}>
                                        <Checkbox
                                            checked={stepWaitForPageLoad}
                                            onChange={(e) => setWaitForPageLoad(e.target.checked)}
                                            onPointerDown={(e) => e.stopPropagation()}
                                        />
                                        <label>Wait for page load</label>
                                    </Space>
                                </Popover>
                            </Space>
                        </Flex>

                        <Flex gap={25}>
                            {["fill_input_field", "go_to_page"].includes(stepType) && (
                                <Space size={5} direction="vertical">
                                    <Popover placement="topLeft" content={stepParamDescriptions.value}>
                                        <Space size={5}>
                                            <FunctionOutlined />
                                            <label className="scenario-step-label">
                                                {stepValueLabel}
                                            </label>
                                        </Space>
                                    </Popover>
                                    <Input
                                        value={stepValue}
                                        placeholder="Field value"
                                        onChange={(e) => setStepValue(e.target.value)}
                                        onPointerDown={(e) => e.stopPropagation()}
                                        style={{
                                            minWidth: 220,
                                            height: 30,
                                        }}
                                    />
                                </Space>
                            )}

                            {["collect_dl_object", "collect_dl_before_navigation"].includes(stepType) && (
                                <Space size={5} direction="vertical">
                                    <Popover placement="topLeft" content={stepParamDescriptions.value}>
                                        <Space size={5}>
                                            <FunctionOutlined />
                                            <label className="scenario-step-label">
                                                {stepValueLabel}
                                            </label>
                                        </Space>
                                    </Popover>
                                    <Input
                                        value={stepValue}
                                        placeholder="Event name"
                                        onChange={(e) => setStepValue(e.target.value)}
                                        onPointerDown={(e) => e.stopPropagation()}
                                        style={{
                                            minWidth: 220,
                                            height: 30,
                                        }}
                                    />
                                </Space>
                            )}

                            {!["collect_dl_object", "go_to_page"].includes(stepType) && (
                                <Space size={5} direction="vertical">
                                    <Popover placement="topLeft" content={stepParamDescriptions.element_selector}>
                                        <Space size={5}>
                                            <AimOutlined />
                                            <label className="scenario-step-label">Element selector</label>
                                        </Space>
                                    </Popover>
                                    <Input
                                        value={stepSelector}
                                        placeholder="Element selector"
                                        onChange={(e) => setStepSelector(e.target.value)}
                                        onPointerDown={(e) => e.stopPropagation()}
                                        style={{
                                            minWidth: 220,
                                            height: 30,
                                        }}
                                    />
                                </Space>
                            )}

                            {["collect_dl_object", "collect_dl_before_navigation"].includes(stepType) && (
                                <Space size={5} direction="vertical">
                                    <Popover placement="topLeft" content={stepParamDescriptions.datalayer_obj_to_find}>
                                        <Space size={5}>
                                            <SearchOutlined />
                                            <label className="scenario-step-label">
                                                DataLayer object to find
                                            </label>
                                        </Space>
                                    </Popover>
                                    <ActionButton
                                        type={"primary"}
                                        text={<Space size={10}><FormOutlined />Edit object template</Space>}
                                        height={30}
                                        onClickFunction={() => handleDataLayerObjEdit(stepDataLayerObj, stepId)}
                                    />
                                </Space>
                            )}
                        </Flex>

                    </Space>

                    <Space size="middle" direction="vertical" align="center" justify="space-between">
                        <Space>
                            <DeleteOutlined
                                onPointerDown={(e) => e.stopPropagation()}
                                className="scenario-step-remove"
                                onClick={() => handleDeleteStepClick(step.stepId)}
                            />
                        </Space>
                        <Space className="scenario-step-drag">
                            <HolderOutlined style={{ fontSize: 25 }} />
                        </Space>
                    </Space>
                </Flex>
            </div>
        </Space>
    );
}

// Sortable list component
export const SortableList = (props) => {
    const {
        items = [],
        handleDataLayerObjEdit = () => { },
        handleStepsUpdate = () => { },
    } = props;

    // Initialize state for items
    const [curItems, setCurItems] = useState(items);
    const [stepToDeleteId, setStepToDeleteId] = useState(null);

    // Steps list editing
    const [stepRemoveConfirmation, setStepRemoveConfirmation] = useState(false);

    const handleDeleteStepClick = (id) => {
        setStepRemoveConfirmation(true);
        setStepToDeleteId(id);
    }
    const handleDeleteStepConfirmation = () => {
        setStepRemoveConfirmation(false);
        handleStepDelete();
    };
    const handleDeleteStepCancel = () => {
        setStepRemoveConfirmation(false);
        setStepToDeleteId(null);
    };


    // Sensors for drag and drop
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    // Handler when drag ends
    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            setCurItems((prevItems) => {
                const oldIndex = prevItems.findIndex((item) => item.stepId === active?.id);
                const newIndex = prevItems.findIndex((item) => item.stepId === over?.id);
                return arrayMove(prevItems, oldIndex, newIndex);
            });
        }
    };

    const handleStepAdd = () => setCurItems([...curItems, defaultStepObject()]);

    const handleStepEdit = (id, step) => {
        const newItemsList = curItems.map(elem => {
            switch (id === elem.stepId) {
                case true:
                    return step;
                default:
                    return elem;
            }
        });

        handleStepsUpdate(newItemsList);
    };
    
    const handleStepDelete = () => {
        const newItemsList = curItems.filter(elem => elem.stepId !== stepToDeleteId) || []
        handleStepsUpdate(newItemsList);
    };

    // Updating data
    useEffect(() => setCurItems(items), [items]);

    return (
        <>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext key={curItems} items={curItems.map((item) => item.stepId)} strategy={verticalListSortingStrategy}>
                    <Space direction="vertical">
                        {curItems.length ? (curItems.map((step, idx) => (
                            <Space key={idx} size="large">
                                <SortableItem
                                    key={step.stepId}
                                    index={idx + 1}
                                    step={step}
                                    handleDataLayerObjEdit={handleDataLayerObjEdit}
                                    handleStepEdit={handleStepEdit}
                                    handleDeleteStepClick={handleDeleteStepClick}
                                />
                            </Space>
                        ))) : undefined}
                        <Space size="large">
                            <ActionButton
                                text={<Space><PlusCircleFilled />Add another step</Space>}
                                width={868}
                                color="primary"
                                variant="outlined"
                                onClickFunction={() => handleStepAdd()}
                            />
                        </Space>
                    </Space>
                </SortableContext>
            </DndContext>

            <Modal
                open={stepRemoveConfirmation}
                title="Confirm"
                onOk={() => handleDeleteStepConfirmation()}
                onCancel={() => handleDeleteStepCancel()}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <Flex gap={5}>
                        <CancelBtn />
                        <OkBtn />
                    </Flex>
                )}
            >
                <p>Do you want to delete this step?</p>
            </Modal>
        </>
    );
};
