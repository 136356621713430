import { createContext, useState, useEffect } from 'react';
import FetchService from './fetch.service';
import Cookies from "js-cookie";
import localStorageService from './localStorage.service';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [companyId, setCompanyId] = useState(localStorageService.getCompanyId());
    const [companyList, setCompanyList] = useState(localStorageService.getCompanyList());
    const [companyInfo, setCompanyInfo] = useState(localStorageService.getCompanyInfo());
    const [adminUser, setAdminUser] = useState(localStorageService.getAdminUser());

    const handleCompanyChange = (id) => {
        setCompanyId(id);
        localStorageService.setCompanyId(id);
    }

    useEffect(() => {
        setCompanyInfo(localStorageService.getCompanyInfo());
    }, [companyId]);

    const login = async ({ username, password }) => {
        const reqData = { username, password };
        const fetchService = new FetchService();

        const response = await fetchService("/login/", {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        const respData = await response.json();

        const loginResult = respData?.result;
        const userData = {};

        if (loginResult) {
            userData.user_id = respData?.user_data?.user_id;
            userData.full_name = respData?.user_data?.full_name;
            userData.company_id = respData?.user_data?.company_id;
            userData.company_name = respData?.user_data?.company_name;
            userData.email = respData?.user_data?.email;
            userData.phone_number = respData?.user_data?.phone_number;
            userData.companies_list = respData?.companies_list;
            userData.user_is_admin = respData?.user_data?.user_is_admin;

            localStorageService.setToken(respData?.token);
            localStorageService.setCompanyList(userData?.companies_list);
            localStorageService.setCompanyId(userData?.company_id);
            localStorageService.setUserData(userData);
            localStorageService.setAdminUser(respData?.user_data?.user_is_admin);

            const curCompany = userData?.companies_list?.find(elem => elem.id === userData?.company_id) || {};

            setCompanyId(userData?.company_id);
            setCompanyInfo(curCompany || {});
            setCompanyList(userData?.companies_list || []);
            setAdminUser(respData?.user_data?.user_is_admin);

            Cookies.set("userToken", respData?.token, { expires: 7 });
        }

        setIsLoggedIn(loginResult);

        return {
            result: loginResult,
            userData: userData,
        };
    }

    const logout = async () => {
        const fetchService = new FetchService();

        const response = await fetchService("/logout/", {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        await response.json();

        setIsLoggedIn(false);
        Cookies.remove("userToken");

    }

    const resetPassword = async ({ email }) => {
        const fetchService = new FetchService();
    
        const response = await fetchService("/password-reset/", {
          method: "POST",
          cache: "no-cache",
          mode: "cors",
          body: JSON.stringify({ email: email }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
    
        const respData = await response.json();
    
        let responseResult = respData;
    
        return responseResult;
      };
    
      const passwordChange = async ({ token, password }) => {
        const fetchService = new FetchService();
    
        const response = await fetchService("/change-password/", {
          method: "POST",
          cache: "no-cache",
          mode: "cors",
          body: JSON.stringify({ password: password, token: token }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
    
        const respData = await response.json();
    
        let responseResult = respData;
    
        return responseResult;
      };
    

    // Check if the user is already logged in when the app loads
    useEffect(() => {
        // Make an Axios GET request to check the user's login status and update `isLoggedIn` accordingly.
        const token = localStorageService.getToken();
        setIsLoggedIn(token !== null);
    }, []);

    const contextValue = {
        isLoggedIn,
        login,
        logout,
        companyId,
        companyInfo,
        companyList,
        handleCompanyChange,
        resetPassword,
        passwordChange,
        adminUser
    
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
