import { React, useState, useContext } from 'react';
import { AuthContext } from '../../services/auth.service';
import { CompanyLogo } from '../../components/companyLogo';
import Alert from 'react-bootstrap/Alert';


export const PasswordReset = ()=>{
    const { resetPassword } = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    

    const handleSubmit = async (event) => {
            event.preventDefault();
            const submitBtn = document.getElementById("submitBtn");

            const response = await resetPassword({'email' : email});

            if (response?.result) {          
                setShowAlert(true);
                submitBtn.disabled = true;

            } else{
                alert(`Email not found!`);

            }
          
    };
  
    
    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    
    return (
        <div className='full-screen-grey-bg pt-5'>

            <div className="col-lg-4 mx-auto mt-4 pt-4 text-center white-bg-frame px-5">
                
                <CompanyLogo />

                <h1>Password reset</h1>
                <p className='pt-3'>
                    Forgotten your password? Enter your e-mail address below, 
                    and we'll send you an e-mail allowing you to reset it.
                </p>

                {showAlert && 
                    <Alert variant="success" style={{ backgroundColor : "#B2FFEE"}}>
                        <h6 className='text-center fw-bold'>
                            We have sent you a reset password link.
                        </h6>
                        <p className='text-center'>If you have not received it please check your spam folder. 
                            Otherwise contact us if you do not receive it in a few minutes.</p>
                    </Alert>
                }

                <form method="POST" className="row mt-5 mb-5" onSubmit={handleSubmit}>
                    <div id="div_id_auth-email" className="mb-3 col-12">
                        <input
                            type="text"
                            autoFocus=""
                            autoCapitalize="none"
                            autoComplete="email"
                            maxLength="150"
                            className="textinput textInput form-control"
                            required={true}
                            id="id_auth-email"
                            placeholder="Email address"
                            name="email"
                            onChange={handleChange}
                            value={email}

                        />
                        <div className="d-grid mt-5 pe-0">
                            <button id='submitBtn' type="submit" className="btn blue-btn btn-block">Reset Password</button>
                        </div>
                    </div>
                    <br/>
                </form>
            </div>
        </div>
      );
      }