import FetchService from './fetch.service';
import localStorageService from './localStorage.service';

const fetchService = new FetchService();

class ProfileService {
    getCompanyProfileInfo = async (params = {}) => {
        let {
            companyId,
        } = params;

        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = await fetchService(`/profiles/company_details/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;
    }

    saveProfileData = async (params = {}) => {
        const {
            companyId,
            configObj,
            curModalSaveFunc,
        } = params;

        switch (curModalSaveFunc) {
            case "profile_details":
                return await this.saveUserProfileInfo({
                    ...configObj?.user_profile,
                });
            case "company_details":
                return await this.saveCompanyInfo({
                    companyId,
                    ...configObj?.company_profile,
                });
            case "billing_details":
                return await this.saveCompanyBillingInfo({
                    companyId,
                    ...configObj?.billing_profile,
                });
            default:
                return;
        }
    }


    // Send a POST request to the backend to send the invoice mail
    sendInvoiceMail = async (params = {}) => {
        let { companyId } = params;

        if (!companyId) companyId = localStorageService.getCompanyId();

        const response = await fetchService(`/profiles/send_invoice_mail/${companyId}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;
    }



    saveUserProfileInfo = async (params = {}) => {
        const userData = localStorageService.getUserData();

        const reqData = {
            user_id: userData?.user_id,
            first_name: params?.first_name,
            last_name: params?.last_name,
            full_name: params?.full_name,
            email: params?.email,
        };

        const response = await fetchService(`/profiles/save_user_profile_data/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        userData.full_name = reqData?.full_name;
        userData.email = reqData?.email;

        localStorageService.setUserData(userData);

        return response;
    }

    saveCompanyInfo = async (params = {}) => {
        const reqData = {
            company_id: params?.companyId,
            company_name: params?.display_company_name,
            cocn: params?.company_cocn_number,
            vat_number: params?.company_vat_number,
            moneybird_cid: params?.moneybird_cid,
            street1: params?.billing_street_address_1,
            street2: params?.billing_street_address_2,
            postcode: params?.billing_postal_code,
            province: params?.billing_province,
            city: params?.billing_city,
            country: params?.billing_country,
        };

        const response = await fetchService(`/profiles/save_company_profile_data/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        const companyInfo = localStorageService.getCompanyInfo();
        companyInfo.name = reqData?.company_name;

        localStorageService.setCompanyInfo(companyInfo);

        return response;
    }
    
    saveCompanyBillingInfo = async (params = {}) => {
        const reqData = {
            company_id: params?.companyId,
            fullname: params?.billing_fullname,
            email: params?.billing_email,
            invoice_ref_number: params?.company_invoice_ref_number,
        };

        const response = await fetchService(`/profiles/save_billing_profile_data/`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(reqData),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });

        return response;                
    }
}

export default new ProfileService();
