// MessageContext.js
import React, { createContext, useContext, useMemo } from 'react';
import { message } from 'antd';

import './styles.scss';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const value = useMemo(() => ({
        success: (msg) => {
            messageApi.open({
                type: 'success',
                content: msg || 'Operation successfully completed',
            });
        },
        error: (msg) => {
            messageApi.open({
                type: 'error',
                content: msg || 'Something went wrong. Please let us know by sending an email to developers@code-cube.io',
            });
        },
        warning: (msg) => {
            messageApi.open({
                type: 'warning',
                content: msg || "Don't forget to save your changes before moving to another page",
            });
        },
    }), [messageApi]);

    return (
        <MessageContext.Provider value={value}>
            {contextHolder}
            {children}
        </MessageContext.Provider>
    );
};

export const useMessage = () => {
    return useContext(MessageContext);
};
