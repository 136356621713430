import { React, useState, useContext } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import Alert from 'react-bootstrap/Alert';
import { CompanyLogo } from '../../components/companyLogo';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { PasswordSecurityCheck, checkPasswordStrength } from '../../components/passwordSecurityCheck';
import localStorageService from '../../services/localStorage.service';


const ResetPassLink = ()=>{
    return (
        <a href='/password-reset'>password reset</a>
    )
}


export const PasswordChange = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const { passwordChange } = useContext(AuthContext);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [formData, setFormData] = useState({
        password1: '',
        password2: '',
        isPassword1Secure: false,
        isPassword2Secure: false

    });

    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        heading: '',
        body: '',
    });

  
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        const fieldId = e.target.id;
        
        // Check password strength
        const isSecure = checkPasswordStrength(newPassword);

        setFormData({...formData,[e.target.name]: newPassword, [fieldId] : isSecure});

    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        if(formData.password1 === formData.password2){

            const userData = {
                "token": token,
                "password": formData.password1,
            }

            const response = await passwordChange(userData);
    
            if (response?.result) {   
                localStorageService.clearLocalStorage();
                navigate("/login");
            } else if (response?.token === 'invalid' || response?.token === 'not found') {
                setAlertData({
                    heading : 'Invalid Token!',
                    body : `The password reset link was invalid, possibly because it has already been used or it is expired. Please request a new `,
                });
                setShowAlert(true);
    
            }
        } else {
            setAlertData({
                heading : 'Mismatched passwords!',
                body : 'Passwords do not match, please try again!',
            });
            setShowAlert(true);
            setFormData({
                password1: '',
                password2 : '',
                isPassword1Secure: false,
                isPassword2Secure: false
            })
        }
    };

    return (
    <div className='full-screen-grey-bg pt-5'>
        
        <div className="col-lg-4 mx-auto pt-4 mt-4 mb-3 text-center white-bg-frame">
            <CompanyLogo />

        <h1 className='registration-headings'>Change your password</h1>
            {showAlert && 
                <Alert variant="danger" style={{ marginTop : "1.5rem" }}>
                    <Alert.Heading>{alertData.heading}</Alert.Heading>
                    <p>
                        {alertData.body}
                        {alertData.heading === 'Invalid Token!' && 
                            <ResetPassLink />
                        }
                    </p>
                </Alert>
            }

            <form method="POST" className="row mt-5 pt-5 mb-5 registration-forms" onSubmit={handleSubmit}>
                <div id="isPassword1Secure" className="mb-4">
                    <PasswordSecurityCheck 
                        password={formData.password1} 
                        isPasswordSecure={formData.isPassword1Secure}
                    />
                    <input
                        autoComplete="current-password"
                        className="textinput textInput form-control"
                        required=""
                        id="isPassword1Secure"
                        placeholder="Password"
                        name="password1"
                        onChange={handlePasswordChange}
                        value={formData.password1}
                        type={
                            showPassword1 ? "text" : "password"
                        }


                    /> 
                    <label className='eye-slash-icon' htmlFor="check">
                        {showPassword1 ? (
                            <Eye />

                        ) : (
                            <EyeSlash />

                        )
                        }

                    </label>
                    <input
                        id="check"
                        type="checkbox"
                        value={showPassword1}
                        onChange={() =>
                            setShowPassword1((prev) => !prev)
                        }
                    />

                </div>
                <PasswordSecurityCheck 
                    password={formData.password2} 
                    isPasswordSecure={formData.isPassword2Secure}
                />
                <div id="div_id_auth-password_2" className="mb-4">
                    <input
                        autoComplete="current-password"
                        className="textinput textInput form-control"
                        required=""
                        id="isPassword2Secure"
                        placeholder="Confirm password"
                        name="password2"
                        onChange={handlePasswordChange}
                        value={formData.password2}
                        type={
                            showPassword2 ? "text" : "password"
                        }

                    />
                    <label className='eye-slash-icon' htmlFor="check2">
                        {showPassword2 ? (
                            <Eye />
                            ) : (
                            <EyeSlash />
                            )
                        }

                    </label>
                    <input
                        id="check2"
                        type="checkbox"
                        value={showPassword2}
                        onChange={() =>
                            setShowPassword2((prev) => !prev)
                        }
                    />
                </div>
                <div className="d-grid my-5">
                    <button 
                    type="submit"
                    className="disabled-purple-btn btn blue-btn btn-block" 
                    disabled={
                        !formData.isPassword1Secure ||
                        !formData.isPassword2Secure ||
                        formData.password1 !== formData.password2
                        }
                        >
                        Submit
                    </button>

                </div>
            </form>
        </div>
    </div>
      );
      }