import { React, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FiletypeDoc, DashCircle, InfoCircleFill, Download, Copy, XCircleFill, PlusCircle, Gear, Box } from 'react-bootstrap-icons';
import { Select, Popover, Flex, Spin, Space, Alert, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import colors from '../../styles/_colors.scss';

import { TimezoneDropdown } from '../../components/elements/TimezoneDropdown';
import GoogleCloudConfigForm from './GoogleCloudConfigFile';

import ConfigService from '../../services/config.service';

import Dropdown from '../../components/elements/Dropdown';

const { Option } = Select;


const _ = require('lodash');

const checkEmptyArray = (arr) => {
    const check =
        !arr ||
        arr.length === 0 ||
        arr.filter((elem) => Object.keys(elem).length !== 0).length === 0;

    return check;
}

const copyToClipboard = (text) => navigator.clipboard.writeText(text?.current?.value);

export const ProductSettingsModal = ({ context }) => {
    const {
        productDescLink,
        timezones,
        timezoneName,
        curTimezone,
        companyDomains,
        setConfigParams,
        setModalSaveStatus,
    } = useContext(context);

    const [choosenTimezone, setChoosenTimezone] = useState(curTimezone);
    const [curCompanyDomains, setCurCompanyDomains] = useState(companyDomains.length ? companyDomains : [{}]);

    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const [showGuideDivDomain, setShowGuideDivDomain] = useState(false);


    useEffect(() => {
        setChoosenTimezone(curTimezone);
        setCurCompanyDomains(companyDomains);
    }, [curTimezone, companyDomains]);


    const handleTimezoneChange = (newTimezone) => setChoosenTimezone(newTimezone);


    const handleElemDelete = (idx) => {
        const newDomainsList = curCompanyDomains?.filter((elem, index) => index !== idx);
        setCurCompanyDomains(newDomainsList.length ? newDomainsList : [{}]);
    }

    const handleElemAdd = () => {
        setCurCompanyDomains([...curCompanyDomains, {}]);
    }

    const handleInputValueChange = (idx, field, event) => {
        const newDomainsList = [...curCompanyDomains];
        const elem = { ...newDomainsList[idx] };
        elem[field] = event.target.value;
        newDomainsList[idx] = elem;
        setCurCompanyDomains(newDomainsList);
    }


    useEffect(() => {
        setConfigParamsToSave(prevParams => {
            const newObject = {
                ...prevParams,
                timezone: choosenTimezone,
                company_domains: curCompanyDomains,
            };

            return newObject;
        });

        setCurCompanyDomains(curCompanyDomains.length ? curCompanyDomains : [{}]);

        setModalSaveStatus(
            (curCompanyDomains !== companyDomains && !checkEmptyArray(curCompanyDomains)) ||
            choosenTimezone !== curTimezone);
    }, [choosenTimezone, curCompanyDomains]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);


    return (
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>configuration</h5>
                <h2>General Settings</h2>
            </div>
            <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={productDescLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p style={{ marginBottom: 0 }}>
                    Configure the settings below to start the configuration of the Tag Monitor.
                    These are basic settings which will reflect on the complete implementation.
                </p>
            </div>
            <div style={{ position: 'relative' }} className="elems-list">
                {showGuideDivDomain &&
                    <div className='hoverable-div-parent' style={{ left: '6.8em' }}>
                        <p className='hover-content'>
                            Only the domain(s) added to this list will be
                            activated for alerting and will be shown in the
                            dashboards.
                        </p>
                    </div>
                }
                <h3 style={{ marginBottom: 0 }} className="modal-category">
                    Domains
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDivDomain(true)}
                        onMouseLeave={() => setShowGuideDivDomain(false)}
                    />
                </h3>
                <p style={{ marginBottom: 0 }}>Add the domain(s) for which you would
                    like to activate the Tag Monitor in the list below.</p>
                <div className="list-col-2-add">
                    {curCompanyDomains?.map((elem, idx) =>
                        <div key={idx} className="inputs-row-3-col-single d-flex flex-row">
                            <input type="text" placeholder="code-cube.io" value={elem.domain || ""} onChange={(event) => handleInputValueChange(idx, "domain", event)} />
                            <div>
                                <DashCircle className='color-blue' size={18} onClick={() => handleElemDelete(idx)}></DashCircle>
                            </div>
                        </div>
                    )
                    }
                </div>
                <div>
                    <div className="btn btn-sm cc-btn-outline-blue" onClick={handleElemAdd}>+ add domain</div>
                </div>
            </div>

            <TimezoneDropdown
                choosenTimezone={choosenTimezone}
                timezones={timezones}
                timezoneName={timezoneName}
                handleTimezoneChange={handleTimezoneChange}
            />


        </div>
    );

}

export const CSTSettingsModal = ({ context }) => {
    const {
        databaseName,
        CSImplmenetationLink,
        clientSideTemplateLink,
        clientErrorsThreshold,
        setConfigParams,
        setModalSaveStatus,
        gtmContainers,
        customNmbrCid,
        subscriptionModel,
        tags_monitoring_exclude_client,
    } = useContext(context);
    const navigate = useNavigate();

    const percentages = [1, 5, 10, 25, 50, 100];

    const [curThreshold, setCurThreshold] = useState(clientErrorsThreshold);
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [showGuideDiv, setShowGuideDiv] = useState(false);
    const [maxNmbrCids, setMaxNmbrCids] = useState(false);
    const [trigger, setTrigger] = useState("");
    const [showGuideDivGTM, setShowGuideDivGTM] = useState(false);

    const [curGTMContainers, setCurGTMContainers] = useState(gtmContainers);

    const [tagsMonitoringExcludeClient, setTagsMonitoringExcludeClient] = useState(
        tags_monitoring_exclude_client || [{}]
    );

    const inputRefDataset = useRef(null);
    
    const content = (
        <div>
            <p>
                You have reached the maximum number of containers  <br></br>
                allowed by your subscription.  <br></br>
                To add more containers or upgrade your plan, <br></br>
                please contact us
                <a target='blank' href='https://code-cube.io/tag-monitor/upgrade-license/'> here</a>.
            </p>
        </div>
    );
    const excludetagsby = ["tag name", "tag id"];
    const matchOptions = ["exactly matches", "matches regex", "contains"];

    useEffect(() => {
        setTagsMonitoringExcludeClient(tags_monitoring_exclude_client || [{}]);
    }, [tags_monitoring_exclude_client]);

    useEffect(() => {
        // Set the maximum number of containers per subscription model
        switch (customNmbrCid > 0) {
            case true:
                setMaxNmbrCids(customNmbrCid);
                break;
            default:
                switch (subscriptionModel) {
                    case "essentials":
                        setMaxNmbrCids(1);
                        break;
                    case "advanced":
                        setMaxNmbrCids(3);
                        break;
                    case "premium":
                    case "enterprise":
                        setMaxNmbrCids(10);
                        break;
                    default:
                        setMaxNmbrCids(1);
                }
        }
    }, [subscriptionModel]);

    useEffect(() => {
        setTrigger(curGTMContainers?.length >= maxNmbrCids ? "hover" : "");
    }, [maxNmbrCids, curGTMContainers]);


    const handleElemDeleteGTM = (idx) => {
        const newGtmContainerList = curGTMContainers.filter((elem, index) => index !== idx);
        setCurGTMContainers(newGtmContainerList.length ? newGtmContainerList : [{type: "client-side"}]);
    }
    const handleElemAddGTM = () => {
        if (curGTMContainers?.length < maxNmbrCids) {
            setCurGTMContainers([...curGTMContainers, {type: "client-side"}]);
        }
    }

    const handleGTMContainerInputValueChange = (idx, event, valueType) => {
        const newGTMContainerList = [...curGTMContainers];
        const elem = { ...newGTMContainerList[idx] };

        switch (valueType) {
            case "cidType":
                elem['type'] = event.target.value;
                break;
            case "gtmID":
                elem['container'] = event.target.value;
                elem['type'] = 'client-side';
                break;
            default:
        }

        newGTMContainerList[idx] = elem;
        setCurGTMContainers(newGTMContainerList);
    }


    const handleElemAddExcludedClientTag = () => {
        setTagsMonitoringExcludeClient([...tagsMonitoringExcludeClient, {}]);
    }
    const handleElemDeleteExcludedClientTag = (idx) => {
        const newExcludedElemList = tagsMonitoringExcludeClient.filter((elem, index) => index !== idx);
        setTagsMonitoringExcludeClient(newExcludedElemList.length ? newExcludedElemList : [{}]);
    }
    const handleExcludedTagInputValue = (idx, event, valueType) => {
        const newExcludedElemList = [...tagsMonitoringExcludeClient];
        const elem = { ...newExcludedElemList[idx] };

        switch (valueType) {
            case "type":
                elem.type = event;
                elem.type = elem.type === "tag name" ? "tag name" : "tag id";
                break;
            case "match":
                elem.match = event;
                break;
            case "value":
                elem.value = event.target.value;
                break;
            default:
                break;
        }

        newExcludedElemList[idx] = elem;
        setTagsMonitoringExcludeClient(newExcludedElemList);
    }
  
    const handleThresholdChange = (elem) => setCurThreshold(elem);

    useEffect(() => setCurThreshold(clientErrorsThreshold), [clientErrorsThreshold]);


    useEffect(() => {
        setConfigParamsToSave(prevParams => {
            const newObject = {
                ...prevParams,
                gtm_containers_client: curGTMContainers,
                tags_monitoring_exclude_client: tagsMonitoringExcludeClient,
                client_errors_threshold: curThreshold,
            };

            return newObject;
        });

        setCurGTMContainers(curGTMContainers.length ? curGTMContainers : [{}]);

        setTagsMonitoringExcludeClient(tagsMonitoringExcludeClient.length ? tagsMonitoringExcludeClient: [{}])

        setModalSaveStatus((curGTMContainers !== gtmContainers && 
                            !checkEmptyArray(curGTMContainers)) ||
                            (tagsMonitoringExcludeClient !== tags_monitoring_exclude_client));
    }, [curGTMContainers, tagsMonitoringExcludeClient, curThreshold]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    useEffect(() => setModalSaveStatus(Number(curThreshold) !== Number(clientErrorsThreshold)), [curThreshold]);


    return (
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>configuration</h5>
                <h2>Client-side error monitoring</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div style={{marginRight: '10px', flex: '1'}}>
                    <Link className="panel-button-container grey-bg" target="_blank" to={CSImplmenetationLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
                <div style={{marginRight: '10px', flex: '1'}}>
                    <Link className="panel-button-container grey-bg" target="_blank" to={clientSideTemplateLink}>
                        <div className="file-icon">
                            <Download style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Download template</div>
                            <div className="panel-button-desc">For GTM client-side container</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p>
                Set up Tag Monitor in your Google Tag Manager container in 3 simple steps.
                Need help? Check our {" "}
                    <Link target="_blank" to={CSImplmenetationLink}>
                        detailed guide.
                    </Link>
                </p>
            </div>

            <div style={{ position: 'relative', marginBottom: 0 }} className="elems-list">
                <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center' }}>
                    <i style={{ marginRight: '8px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 506 511.591">
                            <path fillRule="nonzero" d="M.041 102.171c-.019-4.703 2.858-9.137 7.523-10.875L249.424.729a11.513 11.513 0 018.144.031l241.001 92.419c5.001 2.4 7.431 6.732 7.431 11.726l-.259 310.616c0 5.047-3.238 9.339-7.751 10.912l-238.822 83.986c-4.718 1.674-7.93 1.476-11.952-.096L7.564 420.501C2.915 418.77.041 414.36.041 409.673H0V103.142l.041-.971zm482.84 18.697l-217.827 84.183v278.787l217.589-76.512.238-286.458zm-229.518-96.94L42.776 102.785l211.532 81.635 207.935-80.36-208.88-80.132zM241.86 483.64V204.394L23.194 120.007v281.675L241.86 483.64z"/>
                        </svg>
                    </i>
                    Container set-up
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDivGTM(true)}
                        onMouseLeave={() => setShowGuideDivGTM(false)}
                    />
                </h3>
                <p>
                    Add your Google Tag Manager container ID'(s) to the list below.
                </p>
                {
                    showGuideDivGTM &&
                    <div className='hoverable-div-parent' style={{ left: '15em' }}>
                        <p className='hover-content'>
                            Only the container(s) added to this list will be
                            activated for alerting and will show up in the
                            dashboards.
                        </p>
                    </div>
                }
                <div className="list-col-2-add">
                    {curGTMContainers?.map((elem, idx) =>
                        <div key={idx} className="inputs-row-3-col-single d-flex flex-row">
                            <input type="text" placeholder="GTM-XXXXX" value={elem?.container || ""} onChange={(event) => handleGTMContainerInputValueChange(idx, event, 'gtmID')} />
                            <Space size={5}>
                                <DashCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={() => handleElemDeleteGTM(idx)}
                                    style={{ cursor: "pointer" }}
                                >

                                </DashCircle>
                                <Popover content={content} title="Container Limit Reached!" trigger={trigger}>
                                {idx === curGTMContainers.length -1 ? (
                                    <PlusCircle
                                        className='color-blue'
                                        size={18}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleElemAddGTM(idx)}>
                                    </PlusCircle>
                                ) : null }
                                </Popover>
                            </Space>
                        </div>
                    )
                    }
                </div>
            </div>

            <hr style={{ border: '1px solid', color: colors.ccBlue, width: '100%' }} />

            <div>
                <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center'}}>
                <Link target="_blank" to={clientSideTemplateLink}>
                    <Download style={{ color: '#000000', marginRight: '8px' }} size={20} /> {"  "}
                </Link>
                    Installation
                </h3>
                <div style={{ padding: '20px'}} >
                <ol style={{ paddingLeft: '0', margin: '0', textAlign: 'left' }} >
                    <li>Download the Code Cube template via the button above.</li>
                    <li>Import the template in your GTM container and import a new monitoring tag.</li>
                    <li>Copy the "Dataset name" in the Code Cube monitoring tag.</li>
                </ol>
                </div>
                <div>
                    <input
                        className='disabled-input'
                        type="text"
                        placeholder="Database name"
                        value={databaseName || ""}
                        disabled
                        ref={inputRefDataset}
                    />
                    <Copy
                        className='copy-icon'
                        size={21}
                        onClick={() => copyToClipboard(inputRefDataset)}
                    />
                </div>
            </div>

            <hr style={{ border: '1px solid', color: colors.ccBlue, width: '100%' }} />

            <div>
            <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center' }}>
            <Gear style={{ color: '#000000', marginRight: '8px' }} size={20} />
                Configuration
            </h3>
                <br/>
                <p className="modal-category fw-bold" style={{marginBottom: 6}}>Configure tag names</p>
                <p>
                    Tag Monitor shows Tag ID'S by default. Add friendly names to your tags. Click "Start bulk editing" to begin. 
                </p>
                <div
                    className="btn btn-sm cc-btn-outline-blue mb-3"
                    onClick={(e) => { e.preventDefault(); navigate("/authorize_user") }}
                >
                    start bulk editing
                </div>

            </div>
            <div style={{ position: 'relative'}}>
                <p className='modal-category fw-bold' size={14} style={{marginBottom: 6}}>
                    Tags to exclude from monitoring
                </p>
                <p> 
                    Select tags to exclude from monitoring. Add exclusions directly in GTM or use the table below.
                </p>
                <div className='list-col-2-add'>
                    {Array.isArray(tagsMonitoringExcludeClient) && tagsMonitoringExcludeClient.map((elem, idx) => 
                        <div key={idx} className="inputs-row-3-col-single d-flex flex-row"  style={{padding: 0}}>
                            <div >
                                <Select
                                    style={{ minWidth: "9rem" }}
                                    showSearch
                                    value={elem?.type || "tag name"}
                                    onChange={(value) => handleExcludedTagInputValue(idx, value, "type")}>
                                    {excludetagsby.map(option => (
                                        <Select.Option key={option} value={option}>
                                        {option}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    value={elem?.match || "exactly matches"}
                                    style={{ minWidth: "9rem" }}
                                    showSearch
                                    onChange={(value) => handleExcludedTagInputValue(idx, value, "match")}>
                                    {matchOptions.map(option => (
                                        <Select.Option key={`${option}-${idx}`} value={option}>
                                            {option}
                                        </Select.Option>
                                        
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Input
                                    placeholder={elem?.type === "tag id" ? "tag id" : "tag name"}
                                    value={elem?.value || ""}
                                    onChange={(event) => handleExcludedTagInputValue(idx, event, "value")}
                                    style={{ minWidth: "14rem" }}
                                   
                                />
                            </div>
                            <div>
                                <DashCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={() => handleElemDeleteExcludedClientTag(idx)}
                                    >
                                </DashCircle> {" "}
                                {idx === tagsMonitoringExcludeClient.length -1 ? (
                                    <PlusCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={handleElemAddExcludedClientTag}
                                    >
                                    </PlusCircle>
                                ) : null }
                            </div>
                        </div>
                    )}
                 </div>
            </div>

            <div style={{ position: 'relative' }}>
            <p className="modal-category fw-bold" size={14} style={{marginBottom: 6}}>
                    Alert threshold
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </p>
                {
                showGuideDiv &&
                <div className='hoverable-div-parent' style={{ left: '10em' }}>
                    <p className='hover-content'>
                        Notifications via email, Slack, or Teams are triggered only
                        when the error threshold is met
                    </p>
                </div>
                }
                <p>
                    Choose the error percentage threshold to determine when you should receive notifications.
                </p>

                <div>
                    <Select
                        style={{ width: '8em' }}
                        value={`${curThreshold}%`}
                        onChange={(value) => handleThresholdChange(parseInt(value))}
                        >
                        {percentages.map((elem) => (
                            <Option key={elem} value={`${elem}`}>{`${elem}%`}</Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    )
}

export const SSTSettingsModal = ({ context }) => {
    const {
        databaseName,
        SSImplmenetationLink,
        serverSideTemplateLink,
        serverErrorsThreshold,
        setConfigParams,
        setModalSaveStatus,
        gtmContainers,
        serverSideAvailable,
        customNmbrCid,
        subscriptionModel,
        tags_monitoring_exclude_server,
    } = useContext(context);

    const navigate = useNavigate();
    const percentages = [1, 5, 10, 25, 50, 100];

    const [curThreshold, setCurThreshold] = useState(serverErrorsThreshold);
    const [configParamsToSave, setConfigParamsToSave] = useState({});
    const [showGuideDiv, setShowGuideDiv] = useState(false);
    const [maxNmbrCids, setMaxNmbrCids] = useState(false);
    const [trigger, setTrigger] = useState("");
    const [showGuideDivGTM, setShowGuideDivGTM] = useState(false);

    const [curGTMContainers, setCurGTMContainers] = useState(gtmContainers || [{}]);

    const [tagsMonitoringExcludeServer, setTagsMonitoringExcludeServer] = useState(
        tags_monitoring_exclude_server || [{}]
    );

    const inputRefDataset = useRef(null);

    const content = (
        <div>
            <p>
                You have reached the maximum number of containers  <br></br>
                allowed by your subscription.  <br></br>
                To add more containers or upgrade your plan, <br></br>
                please contact us
                <a target='blank' href='https://code-cube.io/tag-monitor/upgrade-license/'> here</a>.
            </p>
        </div>
    );

    const excludetagsby = ["tag name", "tag id"];
    const matchOptions = ["exactly matches", "matches regex", "contains"];

    useEffect(() => {
        setTagsMonitoringExcludeServer(tags_monitoring_exclude_server || [{}]);
    }, [tags_monitoring_exclude_server]);

    useEffect(() => {
        // Set the maximum number of containers per subscription model
        switch (customNmbrCid > 0) {
            case true:
                setMaxNmbrCids(customNmbrCid);
                break;
            default:
                switch (subscriptionModel) {
                    case "essentials":
                        setMaxNmbrCids(1);
                        break;
                    case "advanced":
                        setMaxNmbrCids(3);
                        break;
                    case "premium":
                    case "enterprise":
                        setMaxNmbrCids(10);
                        break;
                    default:
                        setMaxNmbrCids(1);
                }
        }
    }, [subscriptionModel]);


    useEffect(() => {
        setTrigger(curGTMContainers?.length >= maxNmbrCids ? "hover" : "");
    }, [maxNmbrCids, curGTMContainers]);


    const handleElemDeleteGTM = (idx) => {
        const newGtmContainerList = curGTMContainers.filter((elem, index) => index !== idx);
        setCurGTMContainers(newGtmContainerList.length ? newGtmContainerList : [{}]);
    }
    const handleElemAddGTM = () => {
        if (curGTMContainers?.length < maxNmbrCids) {
            setCurGTMContainers([...curGTMContainers, {type: "server-side"}]);
        }
    }
    const handleGTMContainerInputValueChange = (idx, event, valueType) => {
        const newGTMContainerList = [...curGTMContainers];
        const elem = { ...newGTMContainerList[idx] };

        switch (valueType) {
            case "cidType":
                elem['type'] = event.target.innerText;
                break;
            case "gtmID":
                elem['container'] = event.target.value;
                elem['type'] = 'server-side';
                break;
            default:
        }

        newGTMContainerList[idx] = elem;
        setCurGTMContainers(newGTMContainerList);
    }

    const handleElemAddExcludedServerTag = () => {
        setTagsMonitoringExcludeServer([...tagsMonitoringExcludeServer, {}]);
    }
    const handleElemDeleteExcludedServerTag = (idx) => {
        const newExcludedElemList = tagsMonitoringExcludeServer.filter((elem, index) => index !== idx);
        setTagsMonitoringExcludeServer(newExcludedElemList.length ? newExcludedElemList : [{}]);
    }
    const handleExcludedTagInputValue = (idx, event, valueType) => {
        const newExcludedElemList = [...tagsMonitoringExcludeServer];
        const elem = { ...newExcludedElemList[idx] };

        switch (valueType) {
            case "type":
                elem.type = event;
                elem.type = elem.type === "tag name" ? "tag name" : "tag id";
                break;
            case "match":
                elem.match = event;
                break;
            case "value":
                elem.value = event.target.value;
                break;
            default:
                break;
        }

        newExcludedElemList[idx] = elem;
        setTagsMonitoringExcludeServer(newExcludedElemList);
    }

    const handleThresholdChange = (elem) => setCurThreshold(elem);
    
    useEffect(() => setCurThreshold(serverErrorsThreshold), [serverErrorsThreshold]);


    useEffect(() => {
        setConfigParamsToSave(prevParams => {
            const newObject = {
                ...prevParams,
                gtm_containers_server: curGTMContainers,
                tags_monitoring_exclude_server: tagsMonitoringExcludeServer,
                server_errors_threshold: curThreshold,
            };

            return newObject;
        });

        setCurGTMContainers(curGTMContainers.length ? curGTMContainers : [{}]);

        setTagsMonitoringExcludeServer(tagsMonitoringExcludeServer.length ? tagsMonitoringExcludeServer: [{}])

        setModalSaveStatus((curGTMContainers !== gtmContainers && 
                            !checkEmptyArray(curGTMContainers)) ||
                            (tagsMonitoringExcludeServer !== tags_monitoring_exclude_server));
    }, [curGTMContainers, tagsMonitoringExcludeServer, curThreshold]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    useEffect(() => setModalSaveStatus(Number(curThreshold) !== Number(serverErrorsThreshold)), [curThreshold]);


    return (
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>configuration</h5>
                <h2>Server-side error monitoring</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div style={{marginRight: '10px', flex: '1'}}>
                    <Link className="panel-button-container grey-bg" target="_blank" to={SSImplmenetationLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
                <div style={{marginRight: '10px', flex: '1'}}>
                    <Link className="panel-button-container grey-bg" target="_blank" to={serverSideTemplateLink}>
                        <div className="file-icon">
                            <Download style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Download template</div>
                            <div className="panel-button-desc">For GTM server-side container</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <p>
                    Set up Tag Monitor in your Google Tag Manager container in 3 simple steps. Need help? check our {" "}
                    <Link target="_blank" to={SSImplmenetationLink}>
                        detailed guide here.
                    </Link>
                </p>

            </div>
            <div style={{ position: 'relative', marginBottom: 0 }} className="elems-list">
            <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ color: '#000000', marginRight: '8px' }} size={20} />
                    Container set-up
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDivGTM(true)}
                        onMouseLeave={() => setShowGuideDivGTM(false)}
                    />
                </h3>
                <p>
                    Add your Google Tag Manager container ID'(s) to the list below.
                </p>
                {
                    showGuideDivGTM &&
                    <div className='hoverable-div-parent' style={{ left: '15em' }}>
                        <p className='hover-content'>
                            Only the container(s) added to this list will be
                            activated for alerting and will show up in the
                            dashboards.
                        </p>
                    </div>
                }
                <div className="list-col-2-add">
                    {curGTMContainers?.map((elem, idx) =>
                        <div key={idx} className="inputs-row-3-col-single d-flex flex-row">
                            <input type="text" placeholder="GTM-XXXXX" value={elem?.container || ""} onChange={(event) => handleGTMContainerInputValueChange(idx, event, 'gtmID')} />
                            <div>
                                <DashCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={() => handleElemDeleteGTM(idx)}>

                                </DashCircle> {" "}
                                <Popover content={content} title="Container Limit Reached!" trigger={trigger}>
                                {idx === curGTMContainers.length -1 ? (
                                    <PlusCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={() => handleElemAddGTM(idx)}>
                                    </PlusCircle>
                                ) : null }
                                </Popover>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>

            <hr style={{ border: '1px solid', color: colors.ccBlue, width: '100%' }} />

            <div>
                <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center'}}>
                <Link target="_blank" to={serverSideTemplateLink}>
                    <Download style={{ color: '#000000', marginRight: '8px' }} size={20} /> {"  "}
                </Link>
                    Installation
                </h3>
                <div style={{ padding: '20px'}} >
                <ol style={{ paddingLeft: '0', margin: '0', textAlign: 'left' }} >
                    <li>Download the Code Cube template via the button above.</li>
                    <li>Import the template in your GTM container and create a new monitoring tag.</li>
                    <li>Copy the "Dataset name" the Code Cube monitoring tag.</li>
                    <li>Add <strong>"raw_data_server"</strong> as value in the field "Table ID".</li>
                </ol>
                </div>
                <div className='d-flex flex-column' style={{ gap: '.5em' }}>
                <div>
                    <div>
                        <input
                            className='disabled-input'
                            type="text"
                            placeholder="Database name"
                            value={databaseName || ""}
                            disabled
                            ref={inputRefDataset}
                        />
                        <Copy
                            className='copy-icon'
                            size={21}
                            onClick={() => copyToClipboard(inputRefDataset)}
                        />
                    </div>
                </div>
            </div>
            </div>

            <hr style={{ border: '1px solid', color: colors.ccBlue, width: '100%' }} />

            <div>
            <h3 className="modal-category" style={{ display: 'flex', alignItems: 'center' }}>
            <Gear style={{ color: '#000000', marginRight: '8px' }} size={20} />
                Configuration
            </h3>
                <br/>
                <p className="modal-category fw-bold" style={{marginBottom: 6}}>Configure tag names</p>
                <p>
                    Tag Monitor shows Tag ID'S by default. Add friendly names to your tags. Click
                    "Start bulk editing" to begin. 
                </p>
                <div
                    className="btn btn-sm cc-btn-outline-blue mb-3"
                    onClick={(e) => { e.preventDefault(); navigate("/authorize_user") }}
                >
                    start bulk editing
                </div>

            </div>

            <div style={{ position: 'relative'}}>
                <p className='modal-category fw-bold' size={14} style={{marginBottom: 6}}>
                    Tags to exclude from monitoring
                </p>
                <p> 
                    Select tags to exclude from monitoring. Add exclusions directly in GTM or use the table below.
                </p>
                <div className='list-col-2-add'>
                    {Array.isArray(tagsMonitoringExcludeServer) && tagsMonitoringExcludeServer.map((elem, idx) => 
                        <div key={idx} className="inputs-row-3-col-single d-flex flex-row"  style={{padding: 0}}>
                                <div className='dropdown'>
                                    <Select
                                        style={{ minWidth: "9rem" }}
                                        showSearch
                                        value={elem?.type || "tag name"}
                                        onChange={(value) => handleExcludedTagInputValue(idx, value, "type")}>
                                        {excludetagsby.map(option => (
                                            <Select.Option key={option} value={option}>
                                            {option}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                    <Select
                                            value={elem?.match || "exactly matches"}
                                            style={{ minWidth: "9rem" }}
                                            showSearch
                                            onChange={(value) => handleExcludedTagInputValue(idx, value, "match")}>
                                            {matchOptions.map(option => (
                                                <Select.Option key={`${option}-${idx}`} value={option}>
                                                    {option}
                                                </Select.Option>
                                                
                                            ))}
                                        </Select>
                                </div>
                            <div className="tags-container" >
                                <Input
                                    placeholder={elem?.type === "tag id" ? "tag id" : "tag name"}
                                    value={elem?.value || ""}
                                    onChange={(event) => handleExcludedTagInputValue(idx, event, "value")}
                                    style={{ minWidth: "14rem" }}
                                />
                            </div>
                            <div>
                            <DashCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={() => handleElemDeleteExcludedServerTag(idx)}
                                    >
                                </DashCircle>{" "}
                            {idx === tagsMonitoringExcludeServer.length -1 ? (
                                <PlusCircle
                                    className='color-blue'
                                    size={18}
                                    onClick={handleElemAddExcludedServerTag}
                                 >
                                </PlusCircle>
                            ) : null}
                            </div>
                        </div>
                    )}
                 </div>
            </div>

            <div style={{ position: 'relative' }}>
                <p className="modal-category fw-bold" size={14} style={{marginBottom: 6}}>
                    Alert threshold
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </p>
                {
                    showGuideDiv &&
                    <div className='hoverable-div-parent' style={{ left: '10em' }}>
                        <p className='hover-content'>
                            Notifications via email, Slack, or Teams are triggered only
                            when the error threshold is met
                        </p>
                    </div>
                }
                <p>
                    Choose the error percentage threshold to determine when you should receive notifications.
                </p>
                <div className="dropdown">
                    <Select
                        style={{ width: '8em' }}
                        value={`${curThreshold}%`}
                        onChange={(value) => handleThresholdChange(parseInt(value))}
                        >
                        {percentages.map((elem) => (
                            <Option key={elem} value={`${elem}`}>{`${elem}%`}</Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    )
}

export const TagInactivitySettingsModal = ({ context }) => {
    const {
        TIImplmenetationLink,
        tagsClientSide,
        tagsServerSide,
        companyDomains,
        checkFrequency,
        setConfigParams,
        setModalSaveStatus,
    } = useContext(context);

    const [curClientSideTags, setCurClientSideTags] = useState([]);
    const [curServerSideTags, setCurServerSideTags] = useState([]);
    const [clientTagsInputValue, setClientTagsInputValue] = useState("");
    const [serverTagsInputValue, setServerTagsInputValue] = useState("");
    const [curCompanyDomains, setCurCompanyDomains] = useState([]);
    const [curCheckFreq, setCurCheckFreq] = useState("");

    const [configParamsToSave, setConfigParamsToSave] = useState({});


    useEffect(() => {
        setCurClientSideTags([...tagsClientSide]);
        setCurServerSideTags([...tagsServerSide]);
        setCurCompanyDomains([...companyDomains]);
        setCurCheckFreq(checkFrequency);
    }, [tagsClientSide, tagsServerSide, companyDomains, checkFrequency]);

    const handleCheckboxChange = (idx, event) => {
        const updatedCompanyDomains = curCompanyDomains.map((domain, index) => {
            if (index === idx) {
                return {
                    ...domain,
                    tag_inactivity: event.target.checked,
                };
            }
            return domain;
        });

        setCurCompanyDomains(updatedCompanyDomains);
    }

    const handleCheckFreqChange = (newCheckFreq) => setCurCheckFreq(newCheckFreq);

    useEffect(() => setCurCheckFreq(checkFrequency), [checkFrequency]);


    // Tags input handlers
    const handleInputChange = ({ event, inputValue, setInputValue, tags, setTags }) => {
        const enterKeys = [";", ",", "Enter", ""];
        const val = event.target.value;

        if (enterKeys.indexOf(event?.key) > -1 && inputValue !== "") {
            const newList = [...tags, inputValue.trim().replace(";", "")];
            setTags(newList);
            setInputValue("");
        }
        else if (enterKeys.indexOf(val) < 0) setInputValue(val);
    }

    const handleRemoveTag = ({ elem, tags, setTags }) => {
        const newList = tags.filter(email => email !== elem);
        setTags(newList);
    }

    const updateModalState = () => {
        setModalSaveStatus(
            !_.isEqual(curClientSideTags, tagsClientSide) ||
            !_.isEqual(curServerSideTags, tagsServerSide) ||
            curCheckFreq !== checkFrequency ||
            !_.isEqual(curCompanyDomains, companyDomains)
        );
    }


    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.tags_client_side = curClientSideTags.join(",");
        newObject.tags_server_side = curServerSideTags.join(",");
        newObject.check_frequency = curCheckFreq;
        newObject.company_domains = curCompanyDomains;
        setConfigParamsToSave(newObject);
        
        updateModalState();
    }, [curClientSideTags, curServerSideTags, curCheckFreq, curCompanyDomains]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return (
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>configuration</h5>
                <h2>Tag Volume monitoring</h2>
            </div>
            <div className="container-guide modal-panel-button-row d-flex flex-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={TIImplmenetationLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up in Tag Manager</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <h3 className="modal-category">List of tags (client-side)</h3>
                <p>
                    Add the list of tags which should be enabled for Tag Inactivity monitoring.
                    Separate the tags by entering comma or pressing Enter button.
                </p>
                <div className="tags-container">
                    <input
                        type="text"
                        placeholder="Tag name"
                        value={clientTagsInputValue}
                        onChange={(event) => handleInputChange({
                            event,
                            inputValue: clientTagsInputValue,
                            setInputValue: setClientTagsInputValue,
                            tags: curClientSideTags,
                            setTags: setCurClientSideTags
                        })}
                        onKeyDown={(event) => handleInputChange({
                            event,
                            inputValue: clientTagsInputValue,
                            setInputValue: setClientTagsInputValue,
                            tags: curClientSideTags,
                            setTags: setCurClientSideTags
                        })}
                    />
                    <div className="tags-list">
                        {curClientSideTags.map((elem, idx) =>
                            <span className="tag" key={idx}>
                                {elem}
                                <XCircleFill
                                    className="remove"
                                    onClick={() => handleRemoveTag({
                                        elem,
                                        tags: curClientSideTags,
                                        setTags: setCurClientSideTags,
                                    })}
                                />
                            </span>)
                        }
                    </div>
                </div>
            </div>
            <div>
                <h3 className="modal-category">List of tags (server-side)</h3>
                <p>
                    Add the list of tags which should be enabled for Tag Inactivity monitoring.
                    Separate the tags by entering comma or pressing Enter button.
                </p>
                <div className="tags-container">
                    <input
                        type="text"
                        placeholder="Tag name"
                        value={serverTagsInputValue}
                        onChange={(event) => handleInputChange({
                            event,
                            inputValue: serverTagsInputValue,
                            setInputValue: setServerTagsInputValue,
                            tags: curServerSideTags,
                            setTags: setCurServerSideTags
                        })}
                        onKeyDown={(event) => handleInputChange({
                            event,
                            inputValue: serverTagsInputValue,
                            setInputValue: setServerTagsInputValue,
                            tags: curServerSideTags,
                            setTags: setCurServerSideTags
                        })}
                    />
                    <div className="tags-list">
                        {curServerSideTags.map((elem, idx) =>
                            <span className="tag" key={idx}>
                                {elem}
                                <XCircleFill
                                    className="remove"
                                    onClick={() => handleRemoveTag({
                                        elem,
                                        tags: curServerSideTags,
                                        setTags: setCurServerSideTags,
                                    })}
                                />
                            </span>)
                        }
                    </div>
                </div>
            </div>
            <div>
                <h3 className="modal-category">Domains for monitoring</h3>
                <p>
                    Add the list of domains which should be enabled for Tag Inactivity monitoring.
                </p>
                {curCompanyDomains &&
                    <div className="list-col-2-add">
                        {curCompanyDomains?.map((elem, idx) =>
                            <div key={idx} className="inputs-row-2-col-checkbox">
                                <div className="form-check">
                                    <input
                                        className="form-check-input list-checkbox"
                                        type="checkbox"
                                        checked={elem?.tag_inactivity}
                                        onChange={(event) => handleCheckboxChange(idx, event)}
                                    />
                                </div>
                                <input className='disabled-input' type="text" value={elem?.domain} disabled />
                            </div>
                        )}
                    </div>
                }
            </div>
            <div>
                <h3 className="modal-category">Select frequency</h3>
                <p>
                    Select the frequency of getting tag inactivity
                    notifications from the list below.

                </p>
                <Select
                    style={{ width: 200 }}
                    placeholder="Select frequency"
                    optionFilterProp="label"
                    value={curCheckFreq}
                    onSelect={(e) => { handleCheckFreqChange(e) }}
                    options={[
                        {
                            value: 'hourly',
                            label: 'Every hour',
                        },
                        {
                            value: 'every4hours',
                            label: 'Every 4 hours',
                        },
                        {
                            value: 'daily',
                            label: 'Once per day',
                        }
                    ]}
                />
            </div>
        </div>
    );
}

export const CloudMonitoringSettingsModal = ({ context }) => {
    const {
        cloudRunData,
        companyInfo,
        companyId,
        setModalSaveStatus,
    } = useContext(context);

    const tagMonitorSubscription = companyInfo["tag_monitor_subscription_model"];
    const googleCloudImplementationDocsLink = "https://docs.code-cube.io/tag-monitor/cloud-run-monitoring/";

    const [googleCloudDataForm, setGoogleCloudDataForm] = useState({});
    const [response, setResponse] = useState({});
    const [loadingActive, setloadingActive] = useState(false);
    const [alertData, setAlertData] = useState({});
    const [formData, setFormData] = useState(cloudRunData);


    useEffect(() => {
        const saveData = async () => {
            setloadingActive(true);
            const resp = await ConfigService.saveGoogleCloudData({ googleCloudDataForm, companyId });
            const respData = await resp?.json() || resp;
            setResponse(respData);
            setloadingActive(false);
            return respData;
        }

        if (Object.keys(googleCloudDataForm).length > 0) saveData();
    }, [googleCloudDataForm]);

    useEffect(() => {
        setGoogleCloudDataForm({});
        setResponse({});
        setloadingActive(false);
        setAlertData({});
        setFormData(cloudRunData);
    }, [companyId, cloudRunData]);

    useEffect(() => {
        const error = response.error;
        const details = response.details == "None" ? "" : response.details;
        const success = response.success;
        const type = success ? "success" : "error";
        const message = success ? "Successfull implementation!" : "Error occured!";

        setAlertData(
            {
                'error': error,
                'details': details,
                'type': type,
                'message': message
            }
        );
    }, [response]);
    

    return (
        <div className="modal-elems-rows">
            <div id='cloud-run-div'>
                <div>
                    <h5 className='small-heading'>configuration</h5>
                    <h2>Cloud Run monitoring</h2>
                </div>

                <div className="container-guide modal-panel-button-row d-flex flex-row mt-4 mb-5">
                    <Link className="panel-button-container grey-bg" target="_blank" to={googleCloudImplementationDocsLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up the configuration</div>
                        </div>
                    </Link>
                </div>

                <div className='mb-5'>
                    <p>
                        Enable advanced monitoring for your Google Tag Manager server hosted on Cloud Run via the three steps below. The following will be monitored:
                    </p>
                    <ul style={{ marginLeft: 0 }}>
                        <li>
                            Real-time monitoring of the health of your Cloud Run instances
                        </li>
                        <li>
                            Detailed error tracking and alerts for server-side tagging errors
                        </li>
                    </ul>

                   
                </div>

                <GoogleCloudConfigForm
                    cloudRunData={formData}
                    saveFunction={setGoogleCloudDataForm}
                    tagMonitorSubscription={tagMonitorSubscription}
                    setModalSaveStatus={setModalSaveStatus}
                />

                {loadingActive && (
                    <Flex align="center" gap="middle">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48, marginBottom: "1rem" }} spin />} />
                    </Flex>
                )}

                {
                    Object.keys(response).length > 0 && !loadingActive &&
                    <Alert
                        message={alertData.message}
                        description={alertData.details}
                        type={alertData.type}
                    />
                }
            </div>
        </div>
    );
}