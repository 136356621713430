import { React, useContext } from 'react';
import { AuthContext } from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { CompanyLogo } from '../../components/companyLogo';
import localStorageService from '../../services/localStorage.service';

export const LogoutPage = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        await logout();
        localStorageService.clearLocalStorage();
        navigate("/login");
    };

    return (
        <div className='full-screen-grey-bg pt-5'>
            <div className="col-lg-4 mx-auto text-center pt-4 mt-4 white-bg-frame px-5">
                <CompanyLogo />
                <h1 className='registration-headings'>Sign out</h1>
                <h6 className="mt-4 mb-5">Are you sure you want to sign out?</h6>
                <form method="post" action="/logout/" onSubmit={handleSubmit}>
                    <button className="col-12 mt-4 btn btn-block blue-btn" type="submit">Sign Out</button>
                </form>
            </div>
        </div>
    );
}
